import Sentry from 'config/sentry'

class Logger {
    static captureException(error, extra = null, tags = null, username = null) {
        // Setting tags, extra and username
        Sentry.configureScope(scope => {
            if (tags) {
                Object.keys(tags).forEach(key => {
                    scope.setTag(key, tags[key])
                })
            }

            if (extra) {
                Object.keys(extra).forEach(key => {
                    if (key === 'errorInfo') {
                        // Special for errorInfo it has key values, mapping them to extra info
                        Object.keys(extra[key]).forEach(infoKey => {
                            scope.setExtra(infoKey, extra[key][infoKey])
                        })
                    } else {
                        scope.setExtra(key, extra[key])
                    }
                })
            }

            if (username) {
                scope.setUser({ username })
            }
        })

        // Capturing exception with Browser Sentry which will report straight
        // to Sentry if the code is being executed in client.
        Sentry.captureException(error)
    }

    static addBreadcrumb(message) {
        Sentry.addBreadcrumb({
            message,
        })
    }

    static captureMessage(message, extra = null, tags = null, username = null) {
        Sentry.configureScope(scope => {
            if (tags) {
                Object.keys(tags).forEach(key => {
                    scope.setTag(key, tags[key])
                })
            }

            if (extra) {
                Object.keys(extra).forEach(key => {
                    if (key === 'errorInfo') {
                        // Special for errorInfo it has key values, mapping them to extra info
                        Object.keys(extra[key]).forEach(infoKey => {
                            scope.setExtra(infoKey, extra[key][infoKey])
                        })
                    } else {
                        scope.setExtra(key, extra[key])
                    }
                })
            }

            if (username) {
                scope.setUser({ username })
            }
        })
        Sentry.captureMessage(message)
    }
}

export default Logger

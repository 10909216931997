import React from 'react'
import { PropTypes } from 'prop-types'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import config from 'config/web/config'
import { isServer } from 'config/web/configHelper'
import Logger from 'utils/logging'
import { Loading } from '../layouts'
// import RoadmapLoading from '../components/roadmap/components/roadmap-shared/components/loading'

const ARTICLES_BY_CATEGORY_QUERY = gql`
    query ArticleCollection($locale: String, $limit: Int=10, $category: String, $preview: Boolean) {
        articleCollection(
            limit: $limit
            locale: $locale
            preview: $preview
            where: { category_in: [$category] }
        ) {
            items {
                sys {
                    publishedAt
                    firstPublishedAt
                }
                title
                slug
                category
                author
                publishingDate
                video
                metaDescription
                metaImageUrl {
                    url
                }
            }
        }
    }
`

function ArticlesByCategory({ locale, limit, category, children }) {
    const preview = config.contentful.preview
    let fetchPolicy = isServer ? 'network-only' : 'cache-first'
    if (preview) fetchPolicy = 'no-cache' // Disable cache in preview mode.
    return (
        <Query
          query={ARTICLES_BY_CATEGORY_QUERY}
          fetchPolicy={fetchPolicy}
          variables={{ locale, limit, category, preview }}
        >
            {({ loading, error, data }) => {
                if (loading) return <Loading />
                if (error) {
                    Logger.captureException(error)
                    return ''
                }
                return children(data.articleCollection.items)
            }}
        </Query>
    )
}

ArticlesByCategory.propTypes = {
    // slug: PropTypes.string,
    limit: PropTypes.number,
    category: PropTypes.string,
    locale: PropTypes.oneOf(['en', 'de', 'fr', 'ru', 'ja']),
    children: PropTypes.any //eslint-disable-line
}

export default ArticlesByCategory

const getMetadata = /**
 * @param {{ title: string; description: string; image: string; robots: tring }} page
 */ page => {
    if (page) {
        const {
            metaTitle = '',
            metaDescription = '',
            metaImage = '',
            robots = '',
        } = page
        const image = metaImage && metaImage.url ? metaImage.url : metaImage
        const metadata = {
            title: metaTitle,
            description: metaDescription,
            image,
            robots,
        }
        return metadata
    }
    return {}
}

export default getMetadata

import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import SectionType from 'models/types/ts/sectionType'
import { Container, Button } from 'layouts'
import AnimatedText from 'layouts/animated-text'
import BackgroundImage from 'features/background-image'
import { FeatureVideo, YouTube, FullscreenVideo } from 'features'
import LinkArrow from 'pages/www/components/shared/link-arrow'
import { HeadingMedium, HeadingSmall } from 'layouts/typography'
import style from './Hero.module.scss'

interface Props {
    section: SectionType
}

const Hero = ({section}: Props ) => {
    const { headline, teaser, buttonText, buttonUrl, videoId, imageFile } = section
    const [isPlaying, toggleVideo] = useState(false)

    // const youtubeUrl = `https://www.youtube.com/watch?v=${videoId}`

    return (
        <BackgroundImage
          url={imageFile ? imageFile.url : ''}
          repeat="no-repeat"
          size="cover"
          //   height={700}
          position="center center"
          className={style.hero}
        >
            <Container className={style.content}>
                <div className={style.text}>
                    <HeadingMedium>
                        <AnimatedText>{headline}</AnimatedText>
                    </HeadingMedium>
                    <HeadingSmall fontSize={[24, 36]}>
                        <AnimatedText>{teaser}</AnimatedText>
                    </HeadingSmall>

                    <div className={style.buttonContainer}>
                        <FeatureVideo
                          videoId='YMXelBrkyTQ'
                          subTitle=''
                          isButton
                          title='View trailer'
                        />
                        <Button
                          theme={'primary'}
                          size="small"
                          path="https://secure.eveonline.com/signup/"
                          data-id="eveNav_playFree"
                        >
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </Container>
        </BackgroundImage>
    )
}

export default Hero

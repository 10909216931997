import React from 'react'
import ReactMarkdown from 'react-markdown'
import _map from 'lodash/map'
import { SEO } from 'features'
import { propTypes, defaultProps } from './props'
import style from './PlayerResources.scss'
import { Section, Container, Row } from '../../../layouts/common'
import ResourceItem from './resource-item'

const PlayerResources = props => {
    const resource = _map(props.content, (content, key) => (
        <ResourceItem key={`ri-${key}`} content={content} />
    ))

    const resourceItems = []
    for (let i = 0; i < resource.length; i++) {
        const key = i
        resourceItems.push(resource[i])
        if (i % 3 === 2) {
            resourceItems.push(
                <div key={`ri-border-${key}`} className={style.border} />,
            )
        }
    }

    return (
        <div className={style.pilot_resources}>
            <SEO
              title="Player Resources"
            />
            <Section hasContent={props.hasContent}>
                <Container>
                    <div className={style.content}>
                        <div className={style.text}>
                            <ReactMarkdown source={props.headline} />
                            <ReactMarkdown source={props.body} />
                        </div>
                    </div>
                    <Row className={style.resources}>
                        {props.hasContent && resourceItems}
                    </Row>
                </Container>
            </Section>
        </div>
    )
}

PlayerResources.propTypes = propTypes
PlayerResources.defaultProps = defaultProps

export default PlayerResources

import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { FeatureVideo } from 'features'
import { getImage } from '../../helpers'
import style from './YTVideo.scss'

const YTVideo = ({ section }) => {
    return (
        <div className={style.wrapper} id={section.identifier}>
            {section.headline && <ReactMarkdown source={section.headline} />}
            <FeatureVideo videoId={section.videoId} image={getImage(section.imageFile)} />
            {section.body && <p>{section.body}</p>}
        </div>
    )
}

YTVideo.propTypes = {
    section: PropTypes.shape({
        identifier: PropTypes.string,
        headline: PropTypes.string,
        body: PropTypes.string,
        videoId: PropTypes.string,
        imageFile: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
}

export default YTVideo

import React, { Fragment } from 'react'

export default () => (
    <Fragment>
        <h2>サービス規約</h2>
        <p>
            当社のウェブサイトではCookieを使用しています。Cookieが有効になっていないと、登録手続きやウェブサイトが正常に機能しません。登録手続きを継続し、メールアドレスを認証することで、Cookieの使用に同意することになります。Cookieの使用に同意しない場合は、メールアドレスの認証をせず登録手続きを停止してください。当社のCookieポリシーの詳細については、{' '}<a href="https://community.eveonline.com/support/policies/eve-eula/" rel="noopener noreferrer" target="_blank">EVE Onlineエンドユーザーライセンス契約</a>{' '}および{' '}<a href="https://community.eveonline.com/support/policies/eve-privacy-policy/" rel="noopener noreferrer" target="_blank">プライバシーポリシー</a>をご確認ください。
        </p>
        <p>
            続けることで、{' '}<a href="https://community.eveonline.com/support/policies/eve-eula/" rel="noopener noreferrer" target="_blank">EVE Onlineエンドユーザーライセンス契約</a>、{' '}<a href="https://community.eveonline.com/support/policies/eve-tos/" rel="noopener noreferrer" target="_blank">サービス規約</a>{' '}、{' '}<a href="https://community.eveonline.com/support/policies/eve-privacy-policy/" rel="noopener noreferrer" target="_blank">プライバシーポリシー</a>を確認し、同意したものとみなされます。CCPがメールアドレスを使用して、購入品の確認、カスタマーサポートの提供、EVE Onlineの技術アップデートや機能アップデートの配布、その他のアカウント関連のコミュニケーションを行うことに同意します。
        </p>
    </Fragment>
)

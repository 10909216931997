import React from 'react'
import { PropTypes } from 'prop-types'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import moment from 'moment'
import config from 'config/web/config'
import { isServer } from 'config/web/configHelper'
import Logger from 'utils/logging'
import { Loading, Section } from '../layouts'
// import RoadmapLoading from '../components/roadmap/components/roadmap-shared/components/loading'

const ARTICLES_ARCHIVE_QUERY = gql`
    query ArticleCollectionSearch($locale: String, $limit: Int=20, $start: DateTime, $end: DateTime $preview: Boolean) {
        articleCollection(
            limit: $limit
            locale: $locale
            preview: $preview
            where: { AND: [
                { publishingDate_lt: $end }
                { publishingDate_gt: $start }
            ]}
            order: publishingDate_DESC
        ) {
            total
            items {
                sys {
                    publishedAt
                    firstPublishedAt
                }
                title
                slug
                category
                author
                publishingDate
                video
                metaDescription
                metaImageUrl {
                    url
                }
            }
        }
    }
`

function ArticlesArchiveQuery({ locale, limit, query, children }) {
    const preview = config.contentful.preview
    let fetchPolicy = isServer ? 'network-only' : 'cache-first'
    if (preview) fetchPolicy = 'no-cache' // Disable cache in preview mode.
    const start = moment(new Date(query)).startOf('year')
    const end = moment(new Date(query)).endOf('year')
    return (
        <Query
          query={ARTICLES_ARCHIVE_QUERY}
          fetchPolicy={fetchPolicy}
          variables={{ locale, limit, start, end, preview }}
        >
            {({ loading, error, data }) => {
                if (loading) 
                    return <Section hasContent={false} spinnerSize={20} />
                if (error) {
                    Logger.captureException(error)
                    return ''
                }
                return children(data.articleCollection.items)
            }}
        </Query>
    )
}

ArticlesArchiveQuery.propTypes = {
    // slug: PropTypes.string,
    limit: PropTypes.number,
    query: PropTypes.string,
    locale: PropTypes.oneOf(['en', 'de', 'fr', 'ru', 'ja']),
    children: PropTypes.any //eslint-disable-line
}

export default ArticlesArchiveQuery

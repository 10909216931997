import { connect } from 'react-redux'
import Evergreen from './components/live-event/Evergreen'

function mapStateToProps({ pages: { liveEventPages } }) {
    let pageEverGreen = {}
    let pageEverGreenBackground = {}
    let pageEverGreenSectionsIntro = {}
    let pageEverGreenSectionsPastEvents = {}
    let pageEverGreenSectionsParticipate = {}

    let hasContent = false

    if (liveEventPages) {
        if (liveEventPages.pageEverGreen) {
            pageEverGreen = liveEventPages.pageEverGreen
            pageEverGreenBackground =
                liveEventPages.pageEverGreen.pageBackground
            pageEverGreenSectionsIntro =
                liveEventPages.pageEverGreen.sections[0]
            pageEverGreenSectionsPastEvents =
                liveEventPages.pageEverGreen.sections[1]
            pageEverGreenSectionsParticipate =
                liveEventPages.pageEverGreen.sections[2]

            hasContent = true

            // console.log('evergreeen', pageEverGreen)
        }
    }

    return {
        pageEverGreen,
        pageEverGreenBackground,
        pageEverGreenSectionsIntro,
        pageEverGreenSectionsPastEvents,
        pageEverGreenSectionsParticipate,
        hasContent,
    }
}

export default connect(
    mapStateToProps,
    null
)(Evergreen)

import React from 'react'
import { useSelector } from 'react-redux'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import { findSectionByIdentifier } from 'lib/pages/api'
import PageWithSeo from 'features/page-with-seo'
import BackgroundImage from 'features/background-image'
import LoadingBelowFold from 'layouts/loading/LoadingBelowFold'
import { isClient } from 'config/web/configHelper'
import Hero from './components/hero'
import Banner from './components/banner'
import style from './WWW.scss'

let LoadableComponent

if (isClient) {
    LoadableComponent = React.lazy(() => import(/* webpackChunkName: "page-www" */ './WWWContainer'))
}

export default function LoadableWWW() {
    const slug = 'www-home'
    const language = useSelector((state) => state.language)

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page) => {
                if (page) {
                    const sections = page.sectionsCollection.items

                    // Get hero & banner sections above fold
                    const hero = findSectionByIdentifier(sections, 'www-hero')
                    const headline = hero && hero.headline
                    const teaser = hero && hero.teaser
                    const align = hero && hero.template
                    const content = hero && hero.contentCollection.items

                    const banner = findSectionByIdentifier(
                        sections,
                        'www-banner-section'
                    )

                    return (
                        <PageWithSeo page={page} hideSitename>
                            <Hero
                              headline={headline}
                              teaser={teaser}
                              align={align}
                              content={content}
                            />
                            <BackgroundImage
                              id="content"
                              className={style.content}
                              url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg"
                            >
                                {banner && <Banner section={banner} />}
                                {isClient ? (
                                    <React.Suspense
                                      fallback={<LoadingBelowFold />}
                                    >
                                        <LoadableComponent
                                          sections={sections}
                                          language={language}
                                        />
                                    </React.Suspense>
                                ) : (
                                    <LoadingBelowFold />
                                )}
                            </BackgroundImage>
                        </PageWithSeo>
                    )
                }
            }}
        </PageBySlugQuery>
    )
}

import React from 'react'
import { Link } from 'features'

interface Props {
    children: Node,
    slug: string
}

const NewsLink = ({ children, slug }) => (
    <Link path={{ page: 'news', subpage: 'view', id: slug }}>{children}</Link>
)

export default NewsLink

import React, { Fragment } from 'react'

export default () => (
    <Fragment>
        <h2>условиями предоставления услуг</h2>
        <p>
            Внимание! В целях обеспечения корректной работы сайта (и механизма
            регистрации пользователей) мы используем куки (файлы, хранящие
            данные о взаимодействии пользователя и сайта). Чтобы подтвердить
            своё согласие на получение кук с нашего сайта, достаточно пройти
            процедуру регистрации и процедуру проверки адреса электронной почты;
            в противном случае следует немедленно прервать процедуру регистрации
            (проверки адреса). Правила применения нами кук описаны в{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-eula/"
              rel="noopener noreferrer"
              target="_blank">
                лицензионном соглашении с конечным пользователем
            </a>{' '}
            и{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-privacy-policy/"
              rel="noopener noreferrer"
              target="_blank">
                правилах хранения личной информации
            </a>
            .
        </p>
        <p>
            Продолжением регистрации (проверки адреса) я подтверждаю, что
            ознакомился и согласен (ознакомилась и согласна) с{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-eula/"
              rel="noopener noreferrer"
              target="_blank">
                лицензионным соглашением с конечным пользователем
            </a>
            ,{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-tos/"
              rel="noopener noreferrer"
              target="_blank">
                условиями предоставления услуг
            </a>{' '}
            и{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-privacy-policy/"
              rel="noopener noreferrer"
              target="_blank">
                правилами хранения личной информации
            </a>
            ; кроме того, я разрешаю компании CCP использовать предоставленный
            мной адрес электронной почты для проверки совершаемых мною покупок,
            оказания помощи силами службы поддержки игроков, пересылки
            технической и новостной информации, имеющей отношение к EVE Online,
            а также для пересылки любой другой корреспонденции, имеющей
            отношение к создаваемой мною учётной записи.
        </p>
    </Fragment>
)

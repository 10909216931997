import React from 'react'
import { PropTypes } from 'prop-types'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import config from 'config/web/config'
import { isServer } from 'config/web/configHelper'
import Logger from 'utils/logging'
import { Loading } from '../layouts'
// import RoadmapLoading from '../components/roadmap/components/roadmap-shared/components/loading'

const ARTICLES_QUERY_OLD = gql`
    query ArticleCollection($locale: String, $limit: Int=10, $preview: Boolean) {
        articleCollection(
            limit: $limit
            locale: $locale
            preview: $preview
        ) {
            items {
                sys {
                    publishedAt
                    firstPublishedAt
                }
                title
                slug
                category
                author
                publishingDate
                video
                metaDescription
                metaImageUrl {
                    url
                }
            }
        }
    }
`
const ARTICLES_QUERY = gql`
    query ArticleCollection($locale: String, $limit: Int=10, $categoryToExclude: String="patch-notes", $preview: Boolean) {
        articleCollection(
            limit: $limit
            locale: $locale
            preview: $preview
            where: { category_not_in: [$categoryToExclude] }
            order: [publishingDate_DESC]
        ) {
            items {
                sys {
                    publishedAt
                    firstPublishedAt
                }
                title
                slug
                category
                author
                publishingDate
                video
                metaDescription
                metaImageUrl {
                    url
                }
            }
        }
    }
`

const ARTICLES_QUERY_WITH_TAGS = gql`
    query ArticleCollection($locale: String, $limit: Int=10, $categoryToExclude: String="patch-notes", $preview: Boolean) {
        articleCollection(
            limit: $limit
            locale: $locale
            preview: $preview
            where: { category_not_in: [$categoryToExclude] }
            order: [publishingDate_DESC]
        ) {
            items {
                sys {
                    publishedAt
                    firstPublishedAt
                }
                title
                slug
                tags
                category
                author
                publishingDate
                video
                metaDescription
                metaImageUrl {
                    url
                }
            }
        }
    }
`

function ArticlesQuery({ locale, limit, categoryToExclude, children }) {
    const preview = config.contentful.preview
    let fetchPolicy = isServer ? 'network-only' : 'cache-first'
    if (preview) fetchPolicy = 'no-cache' // Disable cache in preview mode.
    // Check if the have the tags field in Contentful, otherwise the query fails
    // TODO: remove when we have added the "tags" field in the Master Contentful environment
    const isContentfulEnvironmentWithTags = config.contentful.environment === 'tags'    
    return (
        <Query
          query={isContentfulEnvironmentWithTags ? ARTICLES_QUERY_WITH_TAGS : ARTICLES_QUERY}
          fetchPolicy={fetchPolicy}
          variables={{ locale, limit, categoryToExclude, preview }}
        >
            {({ loading, error, data }) => {
                if (loading) return <Loading />
                if (error) {
                    Logger.captureException(error)
                    return ''
                }
                return children(data.articleCollection.items)
            }}
        </Query>
    )
}

ArticlesQuery.propTypes = {
    // slug: PropTypes.string,
    limit: PropTypes.number,
    categoryToExclude: PropTypes.string,
    locale: PropTypes.oneOf(['en', 'de', 'fr', 'ru', 'ja']),
    children: PropTypes.any //eslint-disable-line
}

export default ArticlesQuery

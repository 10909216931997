import { isDevelopment } from 'config/web/configHelper'
import { trimUtmQueryParameters } from './QueryParameterProcessor'
import { getValues } from './ProtoValuesStorage'

export default class ProtoManager {

    // TODOS
    // Instance of service connection to grpc-gateway, likely using Axios.
    // Get the data needed for sending in to events, could be utm tags stored in session storage.
    // Get the starting page you landed on.

    static publishSignupEvent(username: string, hashedUserId: string) {
        if(isDevelopment) {
            // TODO
            // Need to only send event if certain things are in place, need to decide
            // Starting with gclid set in session storage.
            const utm = trimUtmQueryParameters(getValues())
            if(utm && utm.gclid) {
                console.log('PublishSignupEvent called. utm data: ', utm)
                console.log('username: ', username)
                console.log('hashed user id: ', hashedUserId)
            }
            else {
                console.log('PublishSignupEvent called but no utm values so should not send event.', utm)
            }
        }
    }

    static publishHelloWorldEvent() {
        console.log('PublishHelloWorldEvent called.')
    }
    static publishEvent() {
        console.log('publish event called.')
    }
}
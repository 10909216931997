import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import classNames from 'classnames'
// import Platform from 'platform'
import ReactMarkdown from 'react-markdown'
import localize from 'shared/localize'
import { Column, Row } from '../../../../layouts/common'
import { Transitions, Icon } from '../../../../layouts'
import style from './Download.scss'

class SysReq extends Component {
    state = {
        showWin: true,
        index: 0,
        items: ['hello', 'world', 'click', 'me'],
        // handleAdd: this.handleAdd.bind(this),
    }

    getIcon = tabName => (tabName === 'Windows' ? 'windows' : 'apple')

    platformSelect = (platform, index) => {
        this.setState({ showWin: platform === 'Windows' })
        this.setState({ index })
    }

    renderTabs = sections => {
        const tabs = _map(sections, (item, index) => {
            const currClass = this.state.index === index ? style.current : ''
            return (
                <Fragment key={index}>
                    {item && (
                        <li key={index} role="presentation">
                            <div
                              className={currClass}
                              role="presentation"
                              onClick={() => this.platformSelect(item.name, index)
                                }
                            >
                                <Icon brand name={this.getIcon(item.name)} />
                                {item.name}
                            </div>
                        </li>
                    )}
                </Fragment>
            )
        })

        return tabs
    }

    renderTable = sections => {
        const selectedTable = _filter(sections, {
            name: this.state.showWin ? 'Windows' : 'Mac',
        })
        const iconClass = classNames(style.table_bg, {
            [style.apple]: !this.state.showWin,
        })
        return (
            <div className={style.tableShow} key={selectedTable[0].name}>
                <ReactMarkdown source={selectedTable[0].body} />
                <Icon
                  brand
                  className={iconClass}
                  name={this.getIcon(selectedTable[0].name)}
                />
            </div>
        )
    }

    render() {
        return (
            <div className={style.sysReq}>
                <Row>
                    <Column xs={12} md={12}>
                        <h4>
                            {localize[this.props.language].systemRequirements}
                        </h4>
                        <ul className={style.tabs}>
                            {this.renderTabs(this.props.sections)}
                        </ul>
                        {/* <Transitions transition="FadeInRight"> */}
                        {this.renderTable(this.props.sections)}
                        {/* </Transitions> */}
                    </Column>
                </Row>
            </div>
        )
    }
}

SysReq.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            body: PropTypes.string,
            name: PropTypes.string,
        })
    ),
    language: PropTypes.string,
}

export default SysReq

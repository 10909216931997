import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import _map from 'lodash/map'
import ClassNames from 'classnames'
import { Section, Container, Row, Column, Border } from '../../../../layouts'
import { SEO } from '../../../../features'
import SectionIntro from './components/intro'
import style from './LiveEvent.scss'

class Evergreen extends Component {
    renderPastList = evergreenPastChallenges => {
        if (!evergreenPastChallenges) {
            return ''
        }

        const list = _map(evergreenPastChallenges.content, (item, key) => (
            <Column
              key={key}
              md={4}
              xs={12}
              className={style.past_challenge_item}>
                <div className={style.inner}>
                    <img alt={'title'} src={item.imageFile} />
                    <h4>{item.name}</h4>
                    <p>{item.body}</p>
                </div>
            </Column>
        ))

        return (
            <Fragment>
                <Row className={style.awards}>
                    <Column xs={12} md={12}>
                        <h3 className={style.heading}>
                            {evergreenPastChallenges.name}
                        </h3>
                        <Border transparent className={style.border} />
                    </Column>
                </Row>
                <Row className={style.past_challenges}>{list}</Row>
            </Fragment>
        )
    }

    renderParticipate = participate => {
        if (!participate) {
            return ''
        }

        return (
            <Row className={style.participate}>
                <Column md={7} xs={12}>
                    <h3 className={style.heading}>{participate.name}</h3>
                    <Border transparent className={style.border} />
                    <ReactMarkdown source={participate.body} />
                </Column>
                <Column md={5} xs={12}>
                    <img alt="" src={participate.imageFile} />
                </Column>
            </Row>
        )
    }

    renderIntro = evergreenIntro => (
        // evergreenIntro = everGreenPage.sections[0]
        <SectionIntro
          style={style}
          title={evergreenIntro.name}
          subTitle={''}
          content={evergreenIntro.body}
          eventImage={evergreenIntro.imageFile}
          buttonText={evergreenIntro.buttonText}
          buttonUrl={evergreenIntro.buttonUrl}
        />
    )

    render() {
        const {
            pageEverGreen,
            pageEverGreenSectionsIntro,
            pageEverGreenSectionsPastEvents,
            pageEverGreenSectionsParticipate,
            hasContent,
        } = this.props

        // console.log('page Evergreen', pageEverGreen)

        const bgImgStyle = {
            backgroundImage: `url(${pageEverGreen.pageBackground})`,
        }

        return (
            <div
              className={ClassNames(style.live_event, style.evergreen)}
              style={bgImgStyle}>
                <Section hasContent={hasContent}>
                    <SEO
                      title={pageEverGreen.metaTitle}
                      description={pageEverGreen.metaDescription}
                      image={pageEverGreen.metaImage}
                    />
                    <Container>
                        <SectionIntro
                          style={style}
                          title={pageEverGreenSectionsIntro.name}
                          subTitle={''}
                          content={pageEverGreenSectionsIntro.body}
                          eventImage={pageEverGreenSectionsIntro.imageFile}
                          buttonText={pageEverGreenSectionsIntro.buttonText}
                          buttonUrl={pageEverGreenSectionsIntro.buttonUrl}
                        />

                        {this.renderPastList(pageEverGreenSectionsPastEvents)}

                        {this.renderParticipate(
                            pageEverGreenSectionsParticipate
                        )}
                    </Container>
                </Section>
            </div>
        )
    }
}

Evergreen.propTypes = {
    pageEverGreen: PropTypes.any, // eslint-disable-line
    pageEverGreenSectionsIntro: PropTypes.any, // eslint-disable-line
    pageEverGreenSectionsPastEvents: PropTypes.any, // eslint-disable-line
    pageEverGreenSectionsParticipate: PropTypes.any, // eslint-disable-line
    hasContent: PropTypes.bool // eslint-disable-line
}

export default Evergreen

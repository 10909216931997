import React, { SyntheticEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Icon } from 'layouts'
import { redirect } from 'redux-first-router'
import { Link } from 'features'
import style from './Tags.module.scss'

const Tags = () => {
    const dispatch = useDispatch()
    const [inputString, setInputString] = useState('')

    const handleInputChange = (event: SyntheticEvent) => {
        event.preventDefault()
        setInputString(event.target.value)
    }

    const performSearch = (event) => {
        const payload = {
            page: 'news',
            subpage: 'q',
            id: inputString
        }

        dispatch(redirect({type: 'PAGE', payload}))
        event.preventDefault()
    }

    return (

        <div className={style.tags}>
            <ul>
                <li><Link path={{ page: 'news', subpage: 't', id: 'nullsec' }}>nullsec</Link></li>
                <li><Link path={{ page: 'news', subpage: 't', id: 'wormholes' }}>wormholes</Link></li>
                <li><Link path={{ page: 'news', subpage: 't', id: 'pvp' }}>pvp</Link></li>
                <li><Link path={{ page: 'news', subpage: 't', id: 'community' }}>community</Link></li>
            </ul>
            <div className={style.search}>
                <form onSubmit={performSearch}>
                    <input type="text" value={inputString} onChange={event => handleInputChange(event)} />
                    <button type="button" onClick={performSearch}>
                        <Icon solid name="search" />
                    </button>
                </form>
            </div>
        </div>
    )
}
export default Tags

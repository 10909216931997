import React from 'react'
import { PropTypes } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
// import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import config from 'config/web/config'
import Error from 'pages/error'
import { isServer } from 'config/web/configHelper'
import Logger from 'utils/logging'
import { Loading } from '../layouts'

const SECTTION_BY_ID_NEWS_CONTENT_QUERY = gql`
    query SectionById($identifier: String, $locale: String, $preview: Boolean ) {
        sectionCollection(
        limit: 20
        locale: $locale
        preview: $preview
        where: { identifier: $identifier }
        ) {
        items {
            name
            headline
            teaser
            body
            buttonText
            buttonUrl
            template
            videoId
            backgroundImage
            imageFile {
                url
            }
            theme
            identifier
            contentCollection(limit: 20) {
                total
                items {
                    ... on Article {
                        title
                        slug
                        category
                        author
                        publishingDate
                        metaImageUrl {
                            url
                        }
                        metaDescription
                        content
                        video
                    }
                }
            }
            }
        }
    }
`

function SectionByIdQuery({ identifier, locale, children }) {
    // // New way, uses hooks but ssr meta stuff does not work.
    const preview = config.contentful.preview
    // console.log('preview', preview)

    const fetchPolicy = isServer ? 'cache-and-network' : 'cache-first'
    // const fetchPolicy = 'network-only'
    // if (preview) fetchPolicy = 'no-cache' // Disable cache in preview mode.
    // console.log('fetch policy:', fetchPolicy)

    const { loading, error, data, networkStatus } = useQuery(
        SECTTION_BY_ID_NEWS_CONTENT_QUERY,
        {
            variables: { identifier, locale, preview },
            fetchPolicy,
            errorPolicy: 'ignore'
        }
    )

    if (loading) return (<></>)
    if (error) {
        Logger.captureException(error)
        return <Error />
    }

    return <div>{children(data.sectionCollection.items[0])}</div>
}

SectionByIdQuery.propTypes = {
    identifier: PropTypes.string,
    locale: PropTypes.oneOf(['en', 'de', 'fr', 'ru', 'ja']),
    children: PropTypes.any //eslint-disable-line
}

export default SectionByIdQuery

import axios from 'axios'
import _reduce from 'lodash/reduce'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import paths from 'config/paths'
import config from 'config/web/config'
import decode from 'jwt-decode'
import { ArticleViewModel, SectionViewModel } from 'models'
import { flags } from 'config'

// console.log('paths', paths)
// import { mapping } from '../../config/sections'

const SECTIONS_URL = paths.sectionsApiUrl
const ARTICLES_URL = paths.articlesApiUrl
const PAGES_URL = paths.pagesApiUrl

export function findSectionByIdentifier(sections, identifier) {
    return _find(
        sections,
        (section) => section && section.identifier === identifier
    )
}

// TODO: Temporary hack to display EN content when passing JA into the articles API
function returnEnDefaultlang(language) {
    return language === 'ja' ? 'en' : language
}

export async function fetchSection(id, language) {
    const sections = await axios.get(`${SECTIONS_URL}/${id}?lang=${returnEnDefaultlang(language)}`)

    const section = new SectionViewModel(sections.data[id])

    const content = {
        section
    }

    // TODO return the section only and use that in containers.
    return content
}

export async function fetchSections(sectionIds, language) {
    const sections = await axios.get(
        `${SECTIONS_URL}/${sectionIds}?lang=${returnEnDefaultlang(language)}`
    )
    return sections.data
}

export async function fetchArticles(category, language, size = 10) {
    const articles = await axios.get(
        `${ARTICLES_URL}?cat=${category}&lang=${returnEnDefaultlang(language)}&pagesize=${size}`
    )

    // console.log('ARTICLES', articles)

    // const articleResponse = articles.data.results[0]
    // const article = new ArticleViewModel(articleResponse)
    // console.log('ONE ARTICLE.TITLE', article)

    return articles
}

// TODO add get shared footer later. (likely)
export async function fetchSharedContent(language) {
    const [footer] = await Promise.all([
        // fetchSection('4k6uAyhMPmcqikAOmy4KQA', language),
        fetchSection('6CYsNLR3hb071Z2uei68rC', language) // TODO WIP [FOOTER IMPROVEMENTS]
    ])

    return {
        // navigation: navigation.section,
        footer: footer.section // TODO WIP [FOOTER IMPROVEMENTS]
    }
}

export async function fetchPageArticles(language) {
    const [news, devblogs, patchNotes, scope, upgrade] = await Promise.all([
        fetchArticles('news', returnEnDefaultlang(language), 5),
        fetchArticles('dev-blogs', returnEnDefaultlang(language), 3),
        fetchArticles('patch-notes', returnEnDefaultlang(language), 2),
        fetchArticles('scope', returnEnDefaultlang(language), 2),
        fetchSection('4jWSWHLzRSwkISQegieaaQ', returnEnDefaultlang(language))
    ])

    return {
        news: news.data.results,
        devblogs: devblogs.data.results,
        patchNotes: patchNotes.data.results,
        scope: scope.data.results,
        upgrade: upgrade.section,
        hasContent: true
    }
}

export async function fetchPageWithoutDispatch(id, language) {
    let page
    try {
        page = await axios.get(`${PAGES_URL}/${id}?lang=${returnEnDefaultlang(language)}`)
        if (page === '') {
            page = {}
        }
    } catch (ex) {
        page = {}
    }

    return page
}

export async function fetchArticle(subpage, language) {
    const { data } = await axios.get(
        `${ARTICLES_URL}/${subpage}?lang=${returnEnDefaultlang(language)}`
    )
    if (!data) {
        return null
    }

    return data
}

export async function fetchHome(language) {
    const [pageResponse, newsResponse] = await Promise.all([
        fetchPageWithoutDispatch('home', returnEnDefaultlang(language)),
        fetchArticles('news', returnEnDefaultlang(language), 3)
    ])

    if (!pageResponse) {
        return null
    }

    // Extract page content
    const page = pageResponse.data
    const { sections } = page
    const jumbotron = findSectionByIdentifier(sections, 'jumbotron')
    const cta = findSectionByIdentifier(sections, 'cta')
    const explore = findSectionByIdentifier(sections, 'explore')
    const pilot = findSectionByIdentifier(sections, 'pilot')
    const upgrade = findSectionByIdentifier(sections, 'upgrade')
    const banner = findSectionByIdentifier(sections, 'www-banner')

    // Extract news
    const newsData = newsResponse && newsResponse.data
    const news = newsData && newsData.results

    let metaData = {}
    if (!_isEmpty(page)) {
        metaData = {
            metaTitle: page.metaTitle,
            metaDescription: page.metaDescription,
            metaImage: page.metaImage
        }
    }

    return {
        metaData,
        jumbotron,
        cta,
        explore,
        pilot,
        upgrade,
        banner,
        news,
        hasContent: true
    }
}

export async function fetchLiveEvents(language) {
    const liveEventsPromise = fetchPageWithoutDispatch('live-event', language)
    const liveEventsEvergreenPromise = fetchPageWithoutDispatch(
        'evergreen',
        language
    )

    const [liveEvents, liveEventsEvergreen] = await Promise.all([
        liveEventsPromise,
        liveEventsEvergreenPromise
    ])

    if (_isEmpty(liveEvents) && _isEmpty(liveEventsEvergreen)) {
        return null
    }

    return {
        pageLiveEvent: liveEvents.data,
        pageEverGreen: liveEventsEvergreen.data,
        hasContent: true
    }
}

export async function fetchEvergreen(language) {
    const liveEventsEvergreen = await fetchPageWithoutDispatch(
        'evergreen',
        language
    )

    if (!liveEventsEvergreen) {
        return null
    }

    return {
        pageEverGreen: liveEventsEvergreen.data,
        hasContent: true
    }
}

export async function fetchAccountInfo(token) {
    const info = await axios({
        url: `${config.cis}v2/users/me`,
        headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
        },
        method: 'get'
    })
        .then((result) => (result.status === 200 ? { ...result.data } : null))
        .catch((e) => {
            console.log(e)
            return null
        })

    return info
}

export async function fetchAccounts(token) {
    const accounts = await axios({
        url: `${config.cis}v2/users/me/users_with_same_email_address`,
        headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
        },
        method: 'get'
    })
        .then((result) => (result.status === 200 ? result.data || [] : null))
        .catch((e) => {
            console.log(e)
            return null
        })

    return accounts
}

export async function fetchOmegaStatus(token) {
    const omegaStatus = await axios({
        url: `${config.cis}users/me/entitlements/eve_clonestate_omega`,
        headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
        },
        method: 'get'
    })
        .then((result) => (result.status === 200
                ? result.data || []
                : { isValid: false, expiryDate: null }))
        .catch((e) => {
            console.log(e)
            if (e.response && e.response.status === 404) {
                return { isValid: false, expiryDate: null }
            }
            return { isValid: null, expiryDate: null }
        })

    return omegaStatus
}

const getSanitizedClientId = (id) => {
    switch (id) {
        case 'account_management':
        case 'eveClientTQ':
        case 'eveLauncherTQ':
        case 'wwwEveOnline':
        case 'wwwdevelopers':
            return id

        default:
            return 'wwwEveOnline' // Return other or something like that instead?
    }
}

export async function fetchActivities(token) {
    const activities = await axios({
        url: `${config.loginHistory}v1/accountactivities/me/loginevents?count=20`,
        headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
        },
        method: 'get'
    })
        .then((result) => {
            return result.status === 200 ? result.data || [] : null
        })
        .catch((e) => {
            console.log(e)
            return null
        })

    if (!activities) {
        return { fetchFailed: true }
    }

    // clientIdentifier: "wwwEveOnline"
    // description: "webdev successfully authenticated."
    // eventDate: "2019-09-25T09:33:26.5133333"
    // eventID: 1519
    // eventTypeID: 2
    // intellectualProperty: 0
    // userID: 30148013

    const history = activities.map((activity) => {
        return {
            client: getSanitizedClientId(activity.clientIdentifier),
            date: activity.eventDate
        }
    })

    return { history }
}

export async function fetchAgreements(language) {
    const agreements = await axios({
        url: `${config.cis}EVE/agreements`,
        method: 'get',
        headers: {
            'Accept-Language': language
        }
    })
        .then((result) => (result.status === 200 ? result.data || [] : null))
        .catch((e) => {
            console.log(e)
            return null
        })

    return _reduce(
        agreements,
        (result, item) => {
            result[item.agreementName.toLowerCase()] = {
                ...item,
                agreementName: undefined
            }
            return result
        },
        {}
    )
}

export async function fetchAgreementStatus(token, language) {
    const { scp } = decode(token)

    if (scp.indexOf('cisservice.customerRead.v1') === -1) {
        return {}
    }

    const status = await axios({
        url: `${config.cis}v2/users/me/agreements`,
        method: 'get',
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept-Language': language
        }
    })
        .then((result) => (result.status === 200 ? result.data || [] : null))
        .catch((err) => {
            if (flags.isDevelopment) {
                console.log('Could not fetch agreement status', err)
            }
            return null
        })

    return _reduce(
        status,
        (result, item) => {
            result[item.agreementName.toLowerCase()] = { ...item }
            return result
        },
        {}
    )
}

export async function fetchAccountData(token, language) {
    const [
        accountInfo,
        accounts,
        omegaStatus,
        activities,
        agreements,
        agreementStatus
    ] = await Promise.all([
        fetchAccountInfo(token),
        fetchAccounts(token),
        fetchOmegaStatus(token),
        fetchActivities(token),
        fetchAgreements(language),
        fetchAgreementStatus(token, language)
    ])

    // console.log(info, accounts, agreements)

    const { characters, id: userId, userName, fullName, emailAddress } =
        accountInfo || {}

    return {
        characters,
        info: { id: userId, userName, fullName, emailAddress },
        accounts,
        omegaStatus,
        activities,
        agreements,
        agreementStatus,
        hasContent: true
    }
}

import { connect } from 'react-redux'
import LiveEvent from './components/live-event'

function mapStateToProps({ pages: { liveEventPages } }) {
    let pageEverGreen = {}
    let pageEverGreenSectionsIntro = {}
    let pageEverGreenSectionsPastEvents = {}
    let pageEverGreenSectionsParticipate = {}

    let pageLiveEvent = {}
    let pageLiveEventSectionsIntro = {}
    let pageLiveEventSectionsStory = {}
    let pageLiveEventParticipate = {}
    let showLiveEvent = false
    let hasContent = false

    if (liveEventPages) {
        if (liveEventPages.pageEverGreen) {
            pageEverGreen = liveEventPages.pageEverGreen
            pageEverGreenSectionsIntro = liveEventPages.pageEverGreen.sections[0]
            pageEverGreenSectionsPastEvents = liveEventPages.pageEverGreen.sections[1]
            pageEverGreenSectionsParticipate = liveEventPages.pageEverGreen.sections[2]
            hasContent = true
        }

        if (liveEventPages.pageLiveEvent) {
            pageLiveEvent = liveEventPages.pageLiveEvent
            pageLiveEventSectionsIntro = liveEventPages.pageLiveEvent.sections[0]
            pageLiveEventSectionsStory = liveEventPages.pageLiveEvent.sections[1]
            pageLiveEventParticipate = liveEventPages.pageLiveEvent.sections[2]
            showLiveEvent = true
            hasContent = true
        }
    }

    return {
        // pageLiveEvent,
        pageEverGreen,
        pageEverGreenSectionsIntro,
        pageEverGreenSectionsPastEvents,
        pageEverGreenSectionsParticipate,
        pageLiveEvent,
        pageLiveEventSectionsIntro,
        pageLiveEventSectionsStory,
        pageLiveEventParticipate,
        showLiveEvent,
        hasContent,
    }
}

export default connect(mapStateToProps, null)(LiveEvent)

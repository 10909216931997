import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import Overview from './components/overview'
import Single from './components/single'

export default function PoliciesContainer() {
    // const slug = 'scope-test-2'

    // We are on policies so always get that. It could contain all the policies in sections.
    const pageSlug = 'policies'

    const location = useSelector(state => state.location)

    const policySlug = location && location.payload && location.payload.subpage

    if (policySlug) {
        // We are on a subpage so need to display single policy.
        console.log('SINGLE POLICY')
    } else {
        console.log('POLICY OVERVIEW')
    }

    const language = useSelector(state => state.language)

    return (
        <PageBySlugQuery slug={pageSlug} locale={language}>
            {item => {
                if (item) {
                    if (policySlug) {
                        const policy = item.sectionsCollection.items.find(
                            section => section.identifier === policySlug
                        )
                        return <Single policy={policy} />
                    } else {
                        return (
                            <Overview
                              policies={item.sectionsCollection.items}
                            />
                        )
                    }
                } else {
                    return (
                        <div>
                            <h1>Not found</h1>
                        </div>
                    )
                }
            }}
        </PageBySlugQuery>
    )
}

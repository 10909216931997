import _isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { getLanguage } from 'selectors'
import DownloadButton from './components/DownloadButton'

function mapStateToProps(props) {
    const { downloadVersions } = props

    let hasContent = false
    // 2018-06-26 BaldvinTh - Launcher version default set to 1225328
    let versions = { win: 1602194, mac: 1602194 }
    if (!_isEmpty(downloadVersions)) {
        versions = downloadVersions.versions || versions
        hasContent = downloadVersions.hasContent
    }
    return {
        versions,
        hasContent,
        language: getLanguage(props),
    }
}

const mapDispatchToProps = dispatch => ({
    dispatch,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DownloadButton)

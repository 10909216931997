import React from 'react'
import Hero from '../hero'
import Featured from '../featured'
import Mural from '../mural'
import ImageCards from '../image-cards'
import CardList from '../card-list'
import YTVideo from '../ytvideo'
import CTATemplate from '../cta-template'

const ComponentFactory = ({ data }) => {
    const components = {}
    components.Hero = <Hero />
    components.HeroCenter = <Hero align="center" />
    components.HeroRight = <Hero align="right" />
    components.Featured = <Featured />
    components.ImageList = <ImageCards />
    components.Mural = <Mural />
    components.CardList = <CardList />
    components.YTVideo = <YTVideo />
    components.CTA = <CTATemplate />

    const componentTemplate = components[data.template]
    if (data.template == null || componentTemplate == null) {
        return null
    }

    return React.cloneElement(componentTemplate, { section: data })
}

export default ComponentFactory

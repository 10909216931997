import React, { memo, Fragment } from 'react'
import PropTypes from 'prop-types'

const getViewBox = (name) => {
    switch (name) {
        case 'eve-logo-with-text':
            return '0 0 130 51'
        case 'eve-logo':
            return '0 0 122 36'
        case 'alpha':
            return '0 0 32 33'
        case 'omega':
            return '0 0 34 36'
        case 'omega-hexagon':
            return '0 0 165 134'
        case 'omega-hexagon-small':
            return '0 0 28.3 24'
        case 'alpha-hexagon':
            return '0 0 80 65'
        case 'alpha-hexagon-small':
            return '0 0 27.6 24'
        case 'www-user':
            return '0 0 20 20'
        case 'isk':
            return '0 0 45 24'
        case 'nes':
            return '0 0 50 47'
        case 'rifter':
            return '222 260 215 224'
        case 'vegas-logo':
            return '0 0 350 162'
        case 'globe':
            return '0 0 20 20'
        case 'play':
            return '0 0 24 24'
        default:
            return '0 0 30 30'
    }
}

const SvgIcon = memo(
    ({
        name = '',
        style = {},
        fill = '#fff',
        width = '100%',
        height = '100%',
        className = '',
    }) => {
        const getPath = (pathName, props) => {
            switch (pathName) {
                case 'eve-logo-with-text':
                    return (
                        <Fragment>
                            <g clipPath="url(#clip0)">
                                <path
                                  d="M0 18.1038H37.2437V11.7624H0V18.1038ZM0 8.53815H6.73212C6.73212 8.53815 6.73212 6.95208 6.73212 6.36165C7.68145 6.36165 37.2437 6.36165 37.2437 6.36165V0.0144653H0V8.53815ZM6.73212 21.2991H0V29.8459H37.2437V23.5016C37.2437 23.5016 7.68145 23.5016 6.73212 23.5016C6.73212 22.9083 6.73212 21.2991 6.73212 21.2991ZM91.257 21.2991H84.5278V29.8459H121.769V23.5016C121.769 23.5016 92.2035 23.5016 91.2541 23.5016C91.257 22.9083 91.257 21.2991 91.257 21.2991ZM60.893 22.8215C60.0942 21.4409 47.7211 0.0144653 47.7211 0.0144653H40.3985L60.893 35.6779L81.373 0.0144653H74.0447C74.0447 0.0144653 61.6889 21.4409 60.893 22.8215ZM84.5307 0.0144653V8.53815H91.2599C91.2599 8.53815 91.2599 6.95208 91.2599 6.36165C92.2092 6.36165 121.774 6.36165 121.774 6.36165V0.0144653H84.5307ZM84.5307 18.1038H121.772V11.7624H84.5307V18.1038Z"
                                  fill={fill}
                                />
                                <path
                                  d="M24.5233 45.8484C24.5233 45.3535 24.6159 45.0149 24.8041 44.8296C24.9893 44.6473 25.3482 44.5547 25.8807 44.5547H29.9009C30.4364 44.5547 30.7981 44.6444 30.9863 44.8296C31.1715 45.012 31.267 45.3506 31.267 45.8484V48.7427C31.267 49.2463 31.1715 49.5879 30.9863 49.7673C30.7981 49.9496 30.4364 50.0423 29.9009 50.0423H25.8836C25.3482 50.0423 24.9922 49.9525 24.807 49.7673C24.6188 49.5879 24.5262 49.2463 24.5262 48.7427V45.8484H24.5233ZM25.7997 49.009H29.9935V45.533H25.7997V49.009Z"
                                  fill={fill}
                                />
                                <path
                                  d="M39.5099 50.0394V44.5547H40.3811L44.245 47.7876C44.3753 47.8976 44.4939 47.996 44.5923 48.0886C44.6936 48.187 44.7862 48.2825 44.8702 48.3809C44.8499 48.077 44.8354 47.8571 44.8297 47.7297C44.8239 47.5995 44.8181 47.5011 44.8181 47.4316V44.5547H45.9613V50.0394H45.0901L41.0613 46.6502C40.9629 46.5633 40.879 46.4852 40.8066 46.4157C40.7342 46.3463 40.6677 46.2768 40.604 46.2015C40.6214 46.3723 40.6329 46.5315 40.6445 46.682C40.6503 46.8325 40.6532 46.9627 40.6532 47.0727V50.0365H39.5099V50.0394Z"
                                  fill={fill}
                                />
                                <path
                                  d="M54.3229 50.0394V44.5547H55.5993V49.0061H59.7121V50.0365H54.3229V50.0394Z"
                                  fill={fill}
                                />
                                <path
                                  d="M67.1447 50.0394V44.5547H68.421V50.0394H67.1447Z"
                                  fill={fill}
                                />
                                <path
                                  d="M76.7769 50.0394V44.5547H77.6509L81.5148 47.7876C81.648 47.8976 81.7637 47.996 81.865 48.0886C81.9663 48.187 82.0589 48.2825 82.14 48.3809C82.1197 48.077 82.1052 47.8571 82.0995 47.7297C82.0908 47.5995 82.0879 47.5011 82.0879 47.4316V44.5547H83.2282V50.0394H82.357L78.3282 46.6502C78.2298 46.5633 78.1459 46.4852 78.0706 46.4157C78.0011 46.3463 77.9317 46.2768 77.8709 46.2015C77.8883 46.3723 77.9027 46.5315 77.9085 46.682C77.9201 46.8325 77.923 46.9627 77.923 47.0727V50.0365H76.7769V50.0394Z"
                                  fill={fill}
                                />
                                <path
                                  d="M91.5869 50.0394V44.5547H97.1961V45.4895H92.8662V46.7109H95.3871V47.6458H92.8662V49.0032H97.2481V50.0336H91.5869V50.0394Z"
                                  fill={fill}
                                />
                                <path
                                  d="M130 2.28649C130 3.57156 129.004 4.57298 127.713 4.57298C126.423 4.57298 125.427 3.57156 125.427 2.28649C125.427 1.00142 126.423 0 127.713 0C129.004 0 130 1.00142 130 2.28649ZM125.783 2.28649C125.783 3.38053 126.605 4.24882 127.713 4.24882C128.819 4.24882 129.644 3.38343 129.644 2.28649C129.644 1.19245 128.819 0.327055 127.713 0.327055C126.608 0.324161 125.783 1.19245 125.783 2.28649Z"
                                  fill={fill}
                                />
                                <path
                                  d="M128.055 2.39645C128.402 2.33567 128.7 2.13307 128.7 1.67867C128.7 1.21848 128.443 0.966675 127.864 0.966675H126.877V3.5368H127.195V2.39645H127.728L128.452 3.5397H128.822L128.055 2.39645ZM127.612 2.12729H127.195V1.23584H127.774C128.087 1.23584 128.385 1.31978 128.385 1.67867C128.385 2.11571 128 2.12729 127.612 2.12729Z"
                                  fill={fill}
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect
                                      width="130"
                                      height="50.0394"
                                      fill="white"
                                    />
                                </clipPath>
                            </defs>
                        </Fragment>
                    )
                case 'eve-logo':
                    return (
                        <path
                          {...props}
                          d="M0 18.1038H37.2437V11.7624H0V18.1038ZM0 8.53815H6.73212C6.73212 8.53815 6.73212 6.95208 6.73212 6.36165C7.68145 6.36165 37.2437 6.36165 37.2437 6.36165V0.0144653H0V8.53815ZM6.73212 21.2991H0V29.8459H37.2437V23.5016C37.2437 23.5016 7.68145 23.5016 6.73212 23.5016C6.73212 22.9083 6.73212 21.2991 6.73212 21.2991ZM91.257 21.2991H84.5278V29.8459H121.769V23.5016C121.769 23.5016 92.2035 23.5016 91.2541 23.5016C91.257 22.9083 91.257 21.2991 91.257 21.2991ZM60.893 22.8215C60.0942 21.4409 47.7211 0.0144653 47.7211 0.0144653H40.3985L60.893 35.6779L81.373 0.0144653H74.0447C74.0447 0.0144653 61.6889 21.4409 60.893 22.8215ZM84.5307 0.0144653V8.53815H91.2599C91.2599 8.53815 91.2599 6.95208 91.2599 6.36165C92.2092 6.36165 121.774 6.36165 121.774 6.36165V0.0144653H84.5307ZM84.5307 18.1038H121.772V11.7624H84.5307V18.1038Z"
                        />
                    )
                case 'alpha':
                    return (
                        <path
                          {...props}
                          d="M29.4592 1.21413H24.3185C23.4593 2.62717 22.9704 5.51685 22.837 6.13152C21.5704 4.32989 20.2 3.08641 18.7259 2.20326C17.2518 1.32011 15.3555 0.931519 13.0222 0.931519C9.25925 0.931519 6.23703 2.21739 3.95555 4.74674C1.67407 7.26902 0.533325 10.8581 0.533325 15.4717C0.533325 19.9652 1.66666 23.5261 3.94073 26.1473C6.21481 28.7685 9.21481 30.0826 12.9407 30.0826C17.8593 30.0826 21.0889 27.4826 22.637 23.6745C23.0444 25.3207 23.6518 26.656 24.4518 28.069H29.4518C28.3555 26.656 27.1481 21.3924 25.8444 14.6451C26.9555 9.19782 28.1555 4.04022 29.4592 1.21413ZM19.3704 23.3777C17.8222 25.2571 15.8963 26.1967 13.5852 26.1967C11.2222 26.1967 9.37777 25.2429 8.05184 23.3424C6.72592 21.4348 6.06666 18.8136 6.06666 15.4788C6.06666 12.0522 6.76295 9.43098 8.16295 7.61522C9.56295 5.79945 11.4593 4.88804 13.8667 4.88804C16.1926 4.88804 18.0815 5.76413 19.5259 7.50924C20.9704 9.25434 21.6889 11.8473 21.6889 15.2668C21.6963 18.7924 20.9185 21.4984 19.3704 23.3777Z"
                        />
                    )
                case 'omega':
                    return (
                        <path
                          {...props}
                          d="M33.6444 35.466H19.4593V31.6879C25.3704 29.1032 28.3185 24.4282 28.3185 17.6629C28.3185 13.8495 27.2741 10.714 25.1926 8.24936C23.1111 5.78474 20.4 4.54891 17.0667 4.54891C13.637 4.54891 10.8889 5.79181 8.82963 8.27054C6.77037 10.7493 5.74815 13.8071 5.74815 17.4299C5.74815 24.287 8.68148 29.0397 14.5556 31.6879V35.466H0.355555V31.4266H8.31852C3.27407 27.479 0.755554 22.6557 0.755554 16.9638C0.755554 13.8706 1.42222 11.0953 2.74815 8.63776C4.07407 6.18021 5.94814 4.23818 8.37037 2.81874C10.7926 1.39223 13.7037 0.686035 17.1037 0.686035C20.4667 0.686035 23.2741 1.34279 25.5259 2.64925C27.7704 3.95571 29.6222 5.82712 31.0741 8.27054C32.5259 10.714 33.2518 13.6164 33.2518 16.9708C33.2518 22.6627 30.7333 27.486 25.6889 31.4336H33.6519V35.466H33.6444Z"
                        />
                    )
                case 'omega-hexagon':
                    return (
                        <Fragment>
                            <path
                              d="M123.75 0H41.25L0 67L41.25 134H123.75L165 67L123.75 0Z"
                              fill="black"
                            />
                            <path
                              d="M122.879 1.45652L163.228 67L122.879 132.543H42.1208L1.77222 67L42.1208 1.45652H122.879ZM123.75 0H41.25L0 67L41.25 134H123.75L165 67L123.75 0Z"
                              fill="#FBB03B"
                            />
                            <path
                              d="M116.829 99.9611H87.5722V92.1687C99.7639 86.8378 105.844 77.1957 105.844 63.2422C105.844 55.377 103.69 48.91 99.3972 43.8268C95.1042 38.7435 89.5125 36.1946 82.6375 36.1946C75.5639 36.1946 69.8958 38.7581 65.6486 43.8705C61.4014 48.9829 59.2931 55.2896 59.2931 62.7615C59.2931 76.9044 65.3431 86.7068 77.4583 92.1687V99.9611H48.1708V91.6298H64.5944C54.1903 83.4878 48.9958 73.5398 48.9958 61.8002C48.9958 55.4207 50.3708 49.6965 53.1056 44.6279C55.8403 39.5592 59.7056 35.5537 64.7014 32.6261C69.6972 29.6839 75.7014 28.2274 82.7139 28.2274C89.65 28.2274 95.4403 29.582 100.085 32.2765C104.714 34.9711 108.533 38.8309 111.528 43.8705C114.522 48.91 116.019 54.8963 116.019 61.8148C116.019 73.5544 110.825 83.5024 100.421 91.6444H116.844V99.9611H116.829Z"
                              fill="#FFCC00"
                            />
                        </Fragment>
                    )
                case 'alpha-hexagon':
                    return (
                        <Fragment>
                            <path
                              d="M60 0H20L0 32.5L20 65H60L80 32.5L60 0Z"
                              fill="black"
                            />
                            <path
                              d="M59.5778 0.706522L79.1407 32.5L59.5778 64.2935H20.4222L0.859259 32.5L20.4222 0.706522H59.5778ZM60 0H20L0 32.5L20 65H60L80 32.5L60 0Z"
                              fill="#CCCCCC"
                            />
                            <path
                              d="M54.4592 18.2141H49.3185C48.4593 19.6272 47.9704 22.5168 47.837 23.1315C46.5704 21.3299 45.2 20.0864 43.7259 19.2033C42.2518 18.3201 40.3555 17.9315 38.0222 17.9315C34.2593 17.9315 31.237 19.2174 28.9555 21.7467C26.6741 24.269 25.5333 27.8581 25.5333 32.4717C25.5333 36.9652 26.6667 40.5261 28.9407 43.1473C31.2148 45.7685 34.2148 47.0826 37.9407 47.0826C42.8593 47.0826 46.0889 44.4826 47.637 40.6745C48.0444 42.3207 48.6518 43.656 49.4518 45.069H54.4518C53.3555 43.656 52.1481 38.3924 50.8444 31.6451C51.9555 26.1978 53.1555 21.0402 54.4592 18.2141ZM44.3704 40.3777C42.8222 42.2571 40.8963 43.1967 38.5852 43.1967C36.2222 43.1967 34.3778 42.2429 33.0518 40.3424C31.7259 38.4348 31.0667 35.8136 31.0667 32.4788C31.0667 29.0522 31.763 26.431 33.163 24.6152C34.563 22.7995 36.4593 21.888 38.8667 21.888C41.1926 21.888 43.0815 22.7641 44.5259 24.5092C45.9704 26.2543 46.6889 28.8473 46.6889 32.2668C46.6963 35.7924 45.9185 38.4984 44.3704 40.3777Z"
                              fill="#CCCCCC"
                            />
                        </Fragment>
                    )
                case 'omega-hexagon-small':
                    return (
                        <Fragment>
                            <path
                              d="M.58 12L7.33.5h13.514l6.75 11.5-6.75 11.5H7.33L.58 12z"
                              fill="#000"
                              stroke="#FBB03B"
                            />
                            <path
                              d="M19.949 17.904h-4.996v-1.396c2.082-.955 3.12-2.682 3.12-5.181 0-1.409-.368-2.567-1.1-3.477-.734-.91-1.688-1.367-2.862-1.367-1.208 0-2.176.459-2.901 1.374-.726.916-1.086 2.046-1.086 3.384 0 2.533 1.034 4.289 3.102 5.267v1.396h-5V16.41h2.804c-1.777-1.458-2.664-3.24-2.664-5.342 0-1.143.235-2.168.702-3.076a5.338 5.338 0 0 1 1.98-2.15c.853-.526 1.878-.787 3.076-.787 1.184 0 2.173.242 2.966.725.79.482 1.442 1.174 1.954 2.076.51.903.767 1.975.767 3.214 0 2.103-.887 3.885-2.664 5.343h2.804v1.49h-.002z"
                              fill="#FC0"
                            />
                        </Fragment>
                    )
                case 'alpha-hexagon-small':
                    return (
                        <Fragment>
                            <path
                              fill="#000"
                              stroke="#CCC"
                              d="M.576 12L7.147.5h13.134L26.853 12 20.28 23.5H7.147L.576 12z"
                            />
                            <path
                              fill="#CCC"
                              d="M18.671 6.725H16.91c-.295.522-.462 1.589-.508 1.816-.434-.665-.904-1.125-1.41-1.45-.505-.327-1.155-.47-1.955-.47-1.29 0-2.326.475-3.109 1.409-.782.93-1.173 2.256-1.173 3.96 0 1.659.388 2.973 1.168 3.941.78.968 1.808 1.453 3.086 1.453 1.686 0 2.794-.96 3.324-2.366.14.608.348 1.101.622 1.623h1.715c-.376-.522-.79-2.465-1.237-4.957.381-2.011.792-3.915 1.24-4.959zm-3.459 8.184c-.53.694-1.19 1.04-1.983 1.04-.81 0-1.443-.352-1.897-1.053-.455-.705-.681-1.673-.681-2.904 0-1.265.239-2.233.719-2.903.48-.67 1.13-1.007 1.955-1.007.798 0 1.445.323 1.94.968.496.644.742 1.601.742 2.864.003 1.302-.264 2.3-.795 2.995z"
                            />
                        </Fragment>
                    )
                case 'www-user':
                    return (
                        <>
                            <path
                              d="M5.99654 13.0403L2.89685 14.0422L3.29716 15.6894H9.05466V3.36938C5.52185 3.36938 5.99216 7.34189 5.99216 7.34189C5.99873 7.54095 5.85216 7.44032 5.81935 7.43595C5.49123 7.41189 5.62466 8.27376 5.70341 8.64345C5.77998 9.01314 5.8106 9.13126 5.93748 9.46157C6.06435 9.79189 6.32685 9.74157 6.32685 9.74157C6.32685 9.74157 6.41435 10.3103 6.4931 10.8091C6.57185 11.3078 6.88248 11.5966 6.88248 11.5966C6.88248 11.5966 6.88248 11.6469 6.81466 12.2944C6.74466 12.9419 5.99654 13.0403 5.99654 13.0403Z"
                            />
                            <path
                              d="M9.05687 3.36941C12.5897 3.36941 12.1194 7.34191 12.1194 7.34191C12.1128 7.54098 12.2594 7.44035 12.2922 7.43598C12.6203 7.41191 12.4869 8.27379 12.4081 8.64348C12.3316 9.01316 12.3009 9.13129 12.1741 9.4616C12.0472 9.79191 11.7847 9.7416 11.7847 9.7416C11.7847 9.7416 11.6972 10.3104 11.6206 10.8091C11.5419 11.3079 11.2312 11.5966 11.2312 11.5966C11.2312 11.5966 11.2312 11.6469 11.2991 12.2944C11.3669 12.9419 12.115 13.0425 12.115 13.0425L15.2147 14.0444L14.8144 15.6916H9.05687V3.36941ZM12.6312 1.05723H5.48249L1.17531 11.7322L2.41343 16.816H15.6959L16.9341 11.7322L12.6312 1.05723ZM13.3159 0.0400391L18.0016 11.6557L16.4966 17.831H1.61718L0.112183 11.6557L4.79781 0.0400391H13.3159Z"
                            />
                        </>
                    )
                case 'isk':
                    return (
                        <path
                          d="M5.351 23V0.695999H0.455V23H5.351ZM9.0375 8.632C9.0375 10.84 9.9975 12.184 11.8855 12.664L19.1815 14.552C19.6935 14.68 19.9495 14.968 19.9495 15.448V18.232C19.9495 18.776 19.6935 19.032 19.1495 19.032H14.4775C13.9335 19.032 13.6775 18.776 13.6775 18.232V16.408H8.8455V18.424C8.8455 21.464 10.4775 23.128 13.4215 23.128H20.1095C23.1495 23.128 24.8135 21.464 24.8135 18.424V14.328C24.8135 12.344 23.7575 11.064 21.6775 10.52L14.5735 8.696C14.0295 8.536 13.8695 8.376 13.8695 7.768V5.464C13.8695 4.92 14.1255 4.664 14.6695 4.664H18.9255C19.4695 4.664 19.7255 4.92 19.7255 5.464V7.48H24.5575V5.272C24.5575 2.232 22.9255 0.567999 19.9815 0.567999H13.7415C10.7015 0.567999 9.0375 2.232 9.0375 5.272V8.632ZM38.986 18.136C39.338 18.552 39.498 19.032 39.498 19.608V23H44.394V18.68C44.394 17.784 44.138 17.048 43.626 16.472L39.274 11.512L43.402 7.48C44.01 6.904 44.33 6.104 44.33 5.112V0.695999H39.69V4.312C39.69 4.952 39.53 5.432 39.178 5.752L35.402 9.464H33.226V0.695999H28.33V23H33.226V13.592H34.986L38.986 18.136Z"
                          fill="#A5A5A6"
                        />
                    )
                case 'nes':
                    return (
                        <path
                          d="M20.662 21.585v1.7h10.009v-1.7H20.662zM22.929 27.09h6.027V25.39h-8.294V31.253h10.01V29.43h-7.743v-2.34zM8.121 31.253v-9.668h1.547l6.85 5.695c.234.19.44.366.617.537.176.167.34.338.486.517-.034-.544-.061-.924-.073-1.153-.011-.224-.019-.4-.019-.52v-5.076h2.033v9.668h-1.547l-7.14-5.974c-.173-.152-.322-.29-.453-.41a4.603 4.603 0 0 1-.356-.374c.03.3.054.582.07.845.014.266.022.494.022.689v5.224H8.121zM31.535 29.434l8.37-.004v-2.203h-8.37v-3.356c0-.879.161-1.48.49-1.8.326-.323.958-.483 1.888-.483h7.57v1.69h-7.995v2.077h8.39V28.966c0 .879-.165 1.48-.495 1.803-.329.324-.964.487-1.906.487h-7.942v-1.822zM47.281 19.869L50 17.985H31.1L24.812 0l-6.287 17.985H0l2.65 1.884h17.223l4.94-14.131 4.939 14.13h17.53zM34.045 32.942l2.703 9.208-11.935-8.535-11.935 8.535 2.714-9.208h-1.98l-4.1 13.933 15.3-10.943 15.302 10.943-4.101-13.933h-1.968z"
                          fill="#A5A5A6"
                        />
                    )
                case 'rifter':
                    return (
                        <polygon
                            // fill={color}
                          points="429.56,411.61 429.56,428.79 421.89,428.79 421.89,394.5 421.89,386.92 414.31,394.5 414.22,394.5 414.22,428.79 404.64,438.39 404.64,445.72 379.88,437.35 379.88,428.12 360.79,416.89 360.79,381.55 365.21,365.03 365.21,329.88 370.49,310.14 370.49,264.86 352.33,261.62 334.17,264.86 334.17,310.14 343.87,390.71 343.87,436.19 329.51,423.81 315.12,436.18 315.12,390.71 324.83,310.14 324.83,264.86 306.67,261.62 288.51,264.86 288.51,310.14 293.79,329.88 293.79,365.03 298.23,381.57 298.23,416.89 279.11,428.12 279.11,437.35 254.34,445.72 254.34,438.39 244.76,428.79 244.76,394.5 244.68,394.5 237.1,386.92 237.1,394.5 237.1,428.79 229.43,428.79 229.43,411.61 222.96,405.13 222.96,411.61 222.96,477.71 229.43,484.19 229.43,471.6 235.56,471.6 239.3,471.6 302.55,481.34 302.55,471.6 310.89,471.6 312.75,471.6 312.75,480.23 329.51,490.29 346.24,480.23 346.24,471.6 348.1,471.6 356.46,471.6 356.46,481.34 419.7,471.6 423.43,471.6 429.56,471.6 429.56,484.19 436.03,477.71 436.03,411.61 436.03,405.13 "
                        />
                    )
                case 'vegas-logo':
                    return (
                        <Fragment>
                            <path
                              d="M118.984 16.6686H153.244V10.8254H118.984V16.6686ZM118.984 7.85804H125.185C125.185 7.85804 125.185 6.39267 125.185 5.86147C126.063 5.86147 153.262 5.86147 153.262 5.86147V0H118.984V7.85804ZM125.185 19.6176H118.984V27.4757H153.244V21.6325C153.244 21.6325 126.045 21.6325 125.167 21.6325C125.185 21.1013 125.185 19.6176 125.185 19.6176ZM202.941 19.6176H196.758V27.4757H231.017V21.6325C231.017 21.6325 203.818 21.6325 202.941 21.6325C202.941 21.1013 202.941 19.6176 202.941 19.6176ZM175.01 21.0097C174.278 19.7458 162.883 0 162.883 0H156.152L175.01 32.8609L193.85 0H187.1C187.1 0 175.742 19.7458 175.01 21.0097ZM196.758 0V7.85804H202.941C202.941 7.85804 202.941 6.39267 202.941 5.86147C203.818 5.86147 231.017 5.86147 231.017 5.86147V0H196.758ZM196.758 16.6686H231.017V10.8254H196.758V16.6686Z"
                              fill={fill}
                            />
                            <path
                              d="M88.9309 90.5981H79.4744L62.9941 45.6113H72.6153L84.1752 78.3074L95.8084 45.6113H105.429L88.9309 90.5981Z"
                              fill={fill}
                            />
                            <path
                              d="M113.734 45.6113H146.384V53.0481H123.429V64.1849H142.964V71.6216H123.429V83.1797H146.384V90.6165H113.734V45.6113Z"
                              fill={fill}
                            />
                            <path
                              d="M176.745 66.3653H193.006V83.1621C193.006 87.2835 189.055 90.5989 184.116 90.5989H165.935C161.014 90.5989 157.137 87.2835 157.137 83.1621V53.0305C157.137 48.9091 161.014 45.5938 165.935 45.5938H184.116C189.036 45.5938 193.006 48.9091 193.006 53.0305V57.9761H183.311V54.7889C183.311 53.7815 182.342 53.0305 181.135 53.0305H169.008C167.8 53.0305 166.831 53.7815 166.831 54.7889V81.4037C166.831 82.4111 167.8 83.1621 169.008 83.1621H181.135C182.342 83.1621 183.311 82.4111 183.311 81.4037V73.5273H176.745V66.3653Z"
                              fill={fill}
                            />
                            <path
                              d="M244.899 90.5981H235.131L231.254 80.7985H213.072L209.359 90.5981H199.592L217.773 45.6113H226.736L244.899 90.5981ZM215.981 73.5815H228.419L222.2 56.4184L215.981 73.5815Z"
                              fill={fill}
                            />
                            <path
                              d="M260.666 69.5884C255.892 68.3795 252.014 66.4196 252.014 62.2982V53.0481C252.014 48.9267 255.892 45.6113 260.812 45.6113H277.86C282.78 45.6113 286.749 48.9267 286.749 53.0481V57.9204H277.055V54.7882C277.055 53.7808 276.085 53.0298 274.878 53.0298H263.885C262.678 53.0298 261.709 53.7808 261.709 54.7882V60.3932C261.709 61.4007 262.678 62.0784 263.885 62.3532C268.659 63.5071 272.683 64.3864 278.591 65.7968C283.201 66.8775 286.987 68.9656 286.987 73.087V83.1614C286.987 87.2827 283.109 90.5981 278.189 90.5981H260.739C255.819 90.5981 251.85 87.2827 251.85 83.1614V78.4173H261.544V81.403C261.544 82.4104 262.513 83.1614 263.721 83.1614H275.116C276.323 83.1614 277.293 82.4104 277.293 81.403V74.9187C277.293 73.9113 276.396 73.3068 275.116 73.032C268.988 71.6216 266.647 71.0721 260.666 69.5884Z"
                              fill={fill}
                            />
                            <path
                              d="M349.999 74.9531C349.304 75.5393 183.642 159.542 183.642 159.542C180.917 160.879 177.972 161.538 175.045 161.538C172.119 161.538 169.174 160.879 166.448 159.542C166.448 159.542 0.694349 75.5026 0.0175781 74.9531L168.643 150.823C172.722 152.801 177.405 152.801 181.484 150.823L349.999 74.9531Z"
                              fill={fill}
                            />
                            <path
                              d="M349.999 59.5675C349.322 59.0179 256.824 11.943 256.824 11.943C255.507 11.2836 254.062 10.9355 252.599 10.9355H242.1V16.7787H250.715C252.068 16.7787 253.422 17.0535 254.666 17.603L349.999 59.5675Z"
                              fill={fill}
                            />
                            <path
                              d="M0 59.5675C0.676771 59.0179 93.1748 11.943 93.1748 11.943C94.4918 11.2836 95.9368 10.9355 97.4001 10.9355H107.917V16.7787H99.3024C97.9488 16.7787 96.5953 17.0535 95.3515 17.603L0 59.5675Z"
                              fill={fill}
                            />
                            <path
                              d="M164.418 122.708C164.418 122.708 164.345 122.708 163.687 122.708C164.327 121.169 164.675 118.678 164.675 118.44C164.675 118.22 164.492 118.275 164.492 118.275C163.431 122.561 158.693 120.803 158.693 117.89C158.693 114.85 163.321 109.849 164.967 106.955C166.632 109.849 171.241 114.831 171.241 117.89C171.241 120.803 166.485 122.561 165.443 118.275C165.443 118.275 165.26 118.202 165.26 118.44C165.26 118.66 165.607 121.169 166.248 122.708C165.571 122.708 164.839 122.708 164.839 122.708H164.418Z"
                              fill={fill}
                            />
                            <path
                              d="M145.103 122.596C144.755 121.735 142.853 119.281 140.109 115.159C136.853 110.269 140.164 108.4 141.463 108.4C142.359 108.4 144.718 108.492 145.066 110.763V110.855C145.414 108.584 147.773 108.4 148.669 108.4C149.968 108.4 153.279 110.269 150.023 115.159C147.279 119.281 145.743 121.754 145.395 122.614C145.304 122.724 145.176 122.761 145.103 122.596Z"
                              fill="#CC171E"
                            />
                            <path
                              d="M178.504 114.135C179.748 113.457 184.851 107.962 184.851 105.562H184.888C184.888 107.98 189.991 113.475 191.235 114.135C189.991 114.813 184.888 120.308 184.888 122.726H184.851C184.851 120.308 179.748 114.794 178.504 114.135Z"
                              fill="#CC171E"
                            />
                            <path
                              d="M202.794 120.379C203.617 119.958 204.166 119.225 204.422 118.401C204.532 119.647 203.873 122.193 203.635 122.706C203.91 122.706 204.166 122.706 204.166 122.706H204.605C204.605 122.706 204.879 122.706 205.757 122.706C205.519 122.174 204.843 119.5 204.989 118.31C205.062 118.401 205.117 118.493 205.154 118.603C205.464 119.482 206.123 120.233 207.074 120.581C208.757 121.204 210.586 120.324 211.135 118.603C211.683 116.881 210.751 114.976 209.068 114.335C208.08 113.968 207.092 114.133 206.342 114.683C206.416 114.481 206.507 114.316 206.635 114.188C207.312 113.584 207.733 112.668 207.733 111.587C207.733 109.646 206.379 108.07 204.696 108.07C203.013 108.07 201.66 109.646 201.66 111.587C201.66 112.759 202.154 113.749 202.922 114.335C202.959 114.371 202.995 114.408 203.032 114.445C202.959 114.426 202.885 114.408 202.812 114.39C202.026 113.987 201.075 113.987 200.16 114.445C198.55 115.287 197.837 117.302 198.569 118.951C199.282 120.563 201.184 121.222 202.794 120.379Z"
                              fill={fill}
                            />
                        </Fragment>
                    )
                case 'globe':
                    return (
                        <path
                          d="M10.5859 19.1171V15.4726H13.5789C13.4031 15.9343 13.2063 16.3726 12.9836 16.7781C12.2969 18.0367 11.4461 18.857 10.5859 19.1171ZM0.8125 10.5859C0.896875 11.9218 1.26719 13.1781 1.8625 14.3007H4.83438C4.54844 13.157 4.37266 11.9054 4.33281 10.5859H0.8125ZM2.60078 15.4726C3.67188 16.9164 5.15313 18.0367 6.87344 18.6601C6.19141 17.8328 5.6125 16.7453 5.17891 15.4726H2.60078ZM7.01406 16.7781C7.70312 18.0367 8.55156 18.857 9.41406 19.1195V15.4726H6.42109C6.59688 15.9343 6.79375 16.3726 7.01406 16.7781ZM1.82969 5.76246C1.25313 6.86871 0.896875 8.10387 0.8125 9.41403H4.33281C4.37266 8.11793 4.54141 6.88746 4.81797 5.76246H1.82969ZM2.55625 4.59059H5.15547C5.59141 3.28981 6.17734 2.18121 6.87109 1.33981C5.12734 1.97262 3.62969 3.11637 2.55625 4.59059ZM12.9859 3.22184C12.2969 1.96325 11.4484 1.14293 10.5859 0.880432V4.58824H13.6047C13.4219 4.10309 13.2156 3.64606 12.9859 3.22184ZM17.4438 4.59059C16.3703 3.11637 14.8703 1.97262 13.1266 1.33981C13.8203 2.17887 14.4086 3.28981 14.8422 4.59059H17.4438ZM17.3992 15.4726H14.8211C14.3875 16.7453 13.8086 17.8328 13.1266 18.6601C14.8469 18.0367 16.3305 16.9164 17.3992 15.4726ZM19.1875 9.41403C19.1055 8.10387 18.7469 6.86871 18.1703 5.76246H15.182C15.4586 6.88746 15.6273 8.11793 15.6672 9.41403H19.1875ZM14.493 10.5859H10.5859V14.3007H13.9586C14.268 13.157 14.4508 11.8984 14.493 10.5859ZM13.975 5.76246H10.5859V9.41403H14.493C14.4531 8.12496 14.275 6.88981 13.975 5.76246ZM6.04141 14.3007H9.41406V10.5859H5.50703C5.54922 11.8984 5.73203 13.157 6.04141 14.3007ZM15.1656 14.3007H18.1375C18.7328 13.1781 19.1031 11.9218 19.1875 10.5859H15.6672C15.6273 11.9054 15.4516 13.157 15.1656 14.3007ZM5.50703 9.41403H9.41406V5.76246H6.025C5.725 6.88981 5.54688 8.12496 5.50703 9.41403ZM7.01406 3.22184C6.78203 3.64606 6.57578 4.10309 6.39531 4.59059H9.41406V0.882776C8.55391 1.14293 7.70312 1.96325 7.01406 3.22184Z"
                        />
                    )
                case 'play':
                    return (
                        <>
                            <path d="M15.958 11.531l-4.983-3.623a.6.6 0 00-.954.485v7.243a.599.599 0 00.954.484l4.983-3.62a.596.596 0 000-.97z" fill="#30B2E6"/><path d="M12 .001c-6.628 0-12 5.372-12 12 0 6.627 5.372 11.997 12 11.997S24 18.627 24 12c0-6.628-5.372-12-12-12zm0 21.995c-5.52 0-9.995-4.474-9.995-9.995 0-5.518 4.475-9.997 9.995-9.997s9.995 4.478 9.995 9.997c0 5.521-4.475 9.995-9.995 9.995z" fill="#30B2E6"/>
                        </>
                    )
                default:
                    return (
                        <path
                          {...props}
                          d="M 2.56635 12.9241C 0.708307 9.55549 0 6.83983 0 5.00558C 0 3.17134 0.450658 2.64526 0.907953 2.22025C 1.36525 1.79524 3.42732 0.523908 3.77867 0.286808C 4.13002 0.0497085 5.47099 -0.41107 6.31193 0.798636C 7.15287 2.00834 8.73646 4.43718 9.82825 6.05069C 11.5415 8.33611 10.1766 9.33937 9.73572 9.94069C 8.92447 11.0472 8.45734 11.3201 8.45734 12.6788C 8.45734 14.0375 12.2545 17.8976 13.1625 18.8487C 14.0635 19.7926 17.8471 23.1094 19.0195 23.2868C 20.2002 23.4654 21.7807 22.2154 22.1168 21.8985C 23.8263 20.586 24.7912 21.581 25.5787 22.0136C 26.3661 22.4461 29.9239 24.663 31.0264 25.4103C 32.0641 26.1576 31.9992 27.3125 31.9992 27.3125C 31.9992 27.3125 29.859 30.7092 29.5996 31.1168C 29.2753 31.5924 28.4971 32 26.746 32C 24.995 32 23.1241 31.6802 18.6777 29.2349C 15.0396 27.234 11.5714 24.1009 9.75551 22.2666C 7.87475 20.4324 4.68876 16.772 2.56635 12.9241Z"
                        />
                    )
            }
        }

        return (
            <svg
              width={width}
              style={style}
              fill={fill}
              height={height}
              viewBox={getViewBox(name)}
              preserveAspectRatio="xMidYMid meet"
              xmlns="http://www.w3.org/2000/svg"
              className={className}
              alt={name}
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                {getPath(name, { fill })}
            </svg>
        )
    }
)

export default SvgIcon

SvgIcon.propTypes = {
    name: PropTypes.string,
    style: PropTypes.func,
    fill: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
}

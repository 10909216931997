import React from 'react'
import { useSelector } from 'react-redux'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import Loading from 'layouts/loading/PageLoading'
import { isClient } from 'config/web/configHelper'

let LoadableComponent

if (isClient) {
    LoadableComponent = React.lazy(() => import(/* webpackChunkName: "page-recruit" */ './RecruitContainer'))
}

export default function LoadableWWW() {
    const slug = 'recruit'
    const language = useSelector(state => state.language)

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {page => {
                if (page) {
                    const sections = page.sectionsCollection.items

                    return (
                        <PageWithSeo page={page} showLoading={!isClient}>
                            {isClient && (
                                <React.Suspense fallback={<Loading />}>
                                    <LoadableComponent sections={sections} language={language} />
                                </React.Suspense>
                                )}
                        </PageWithSeo>
                    )
                }
            }}
        </PageBySlugQuery>
    )
}

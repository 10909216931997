import React from 'react'
import { useSelector } from 'react-redux'
import config from 'config/web/config'
import Article from './Article'
import ArticlePreview from './ArticlePreview'
import ArticleRedirect from './ArticleRedirect'

export default function index() {
    const location = useSelector((state) => state.location)
    const subpage = location && location.payload && location.payload.subpage
    if (subpage === 't') {
        // This only executes if url is /article/t/{slug}
        // Trying out article redirect on server, see how it behaves.
        return <ArticleRedirect />
    }

    return config.contentful.preview ? <ArticlePreview /> : <Article />
}

import React, { Component } from 'react'
import { NOT_FOUND } from 'redux-first-router'
import { withLocalize } from 'react-localize-redux'
import { isClient } from 'config/web/configHelper'
import moment from 'moment'
import fr from 'moment/locale/fr'
import de from 'moment/locale/de'
import ru from 'moment/locale/ru'
import ja from 'moment/locale/ja'
import PropTypes from 'prop-types'
import _isEmpty from 'lodash/isEmpty'
import _includes from 'lodash/includes'
import { sitemap } from 'config'
import NotFound from 'pages/not-found'
import Error from 'pages/error'
import { AppLayout } from 'layouts'
import DefaultPageLayout from 'layouts/page-layouts'

class App extends Component {
    componentDidMount() {
        // Set active language for translations
        this.props.setActiveLanguage(this.props.lang)
        // Loading extra locales and setting current language in moment
        if (this.props.lang === 'fr') moment.updateLocale('fr', fr)

        if (this.props.lang === 'de') moment.updateLocale('de', de)

        if (this.props.lang === 'ru') moment.updateLocale('ru', ru)

        if (this.props.lang === 'ja') moment.updateLocale('ja', ja)

        moment.locale(this.props.lang)

        // ! Important. This has to be here for first page load to work when using Optimize custom event to trigger.
        if (isClient) {
            // console.log('APP calling optimize.activate')
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({ event: 'optimize.activate' })
        }
    }

    componentDidUpdate(prevProps) {
        // Update active language when switching
        if (prevProps.lang !== this.props.lang) {
            this.props.setActiveLanguage(this.props.lang)

            // Set active locale in moment if changed.
            moment.locale(this.props.lang)
        }
    }

    page() {
        if (this.props.type === 'ERROR') {
            return <Error />
        }

        if (this.props.type === NOT_FOUND) {
            return <NotFound />
        }

        // TODO work in progress. Use sitemap in a clever way to populate the app.
        const page = sitemap[this.props.page]

        if (page && page.render()) {
            if (this.props.subpage) {
                // If subpage is set then get that from sitemap.
                if (page.children) {
                    // If one template set for all children then use, e.g. articles/category
                    if (page.children.render()) {
                        if (
                            _includes(page.children.values, this.props.subpage)
                        ) {
                            return page.children.render()
                        }
                        // return <NotFound />
                    } else {
                        // Else get the correct subpage from sitemap and use that pages template.
                        const subpage = page.children[this.props.subpage]
                        if (subpage && subpage.render()) {
                            if (this.props.id) {
                                // Third level page can be set then check if that exists in sitemap.
                                const thirdpage =
                                    subpage.children[this.props.id]
                                if (thirdpage) {
                                    return thirdpage.render()
                                }
                                // return <NotFound />
                            }
                            return subpage.render()
                        }
                    }
                } else {
                    return page.render()
                }
                // return <NotFound />
            }
            return page.render()
        }

        return <NotFound />
    }

    isPageLightTheme() {
        const page = sitemap[this.props.page]

        // Error pages have dark theme now
        if (this.props.type === NOT_FOUND || this.props.type === 'ERROR') {
            return false
        } else if (page && page.showDarkHeader) {
            return true
        }
        return false
    }

    PageLayout() {
        const sitemapPage = sitemap[this.props.page]

        return (
            // <ErrorBoundary>
            <DefaultPageLayout
              page={this.page()}
              lang={this.props.lang}
              hasContent={!_isEmpty(this.page())}
              darkNavigation={this.isPageLightTheme()}
              hideMenuBorder={sitemapPage && sitemapPage.hideMenuBorder}
              showLogin={sitemapPage && sitemapPage.showLogin}
              path={this.props.page}
            />
            // </ErrorBoundary>
        )
    }

    render() {
        return <AppLayout lang={this.props.lang}>{this.PageLayout()}</AppLayout>
    }
}

App.propTypes = {
    type: PropTypes.string,
    lang: PropTypes.string,
    page: PropTypes.string,
    subpage: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // Localization
    setActiveLanguage: PropTypes.func
}

export default withLocalize(App)

import React from 'react'
import PropTypes from 'prop-types'
import { isClient } from 'config/web/configHelper'
import style from './BackgroundVideo.scss'

/* eslint jsx-a11y/media-has-caption: 0 */

const BackgroundVideo = ({ mp4, webm, poster, loop, fill, disableMobile }) => {
    /**
     * @props
     * * poster: if poster is passed - an img is rendered in mobile
     * * fill: Use when video needs to stretch/cover 100% height (like on frontpage)
     *
     * @mobile
     * * Making sure we don't load video on mobile
     * * If poster is provided we render image on mobile
     * *
     */
    // if (isServer) return null
    if (isClient && disableMobile && window.innerWidth < 768) {
        return poster ? (
            <div className={style.poster}>
                <img src={poster} alt="" />
            </div>
        ) : null
    }

    return (
        <div className={style(style.bgVideo, { [style.fill]: fill })}>
            {isClient && (
                <video
                  playsInline
                  autoPlay
                  muted
                  preload="auto"
                  loop={loop}
                  poster={poster}>
                    {mp4 && <source src={mp4} type="video/mp4" />}
                    {webm && <source src={webm} type="video/webm" />}
                </video>
            )}
        </div>
    )
}

BackgroundVideo.propTypes = {
    mp4: PropTypes.string,
    webm: PropTypes.string,
    poster: PropTypes.string,
    loop: PropTypes.bool,
    fill: PropTypes.bool,
    disableMobile: PropTypes.bool,
}

BackgroundVideo.defaultProps = {
    poster: '',
    loop: false,
    fill: false,
    disableMobile: true,
}

export default BackgroundVideo
import React from 'react'
import PropTypes from 'prop-types'
import { Container, Icon } from 'layouts'
import style from './alert-message.scss'

const AlertMessage = ({ children, container, error, warning, success, ...rest }) => {
    const className = style('alert', rest.className, {
        error: error || (!warning && !success),
        warning,
        success,
    })

    let iconType = 'times'
    if (warning) iconType = 'exclamation-triangle'
    if (success) iconType = 'envelope'

    const alertBox = (
        <div className={className}>
            <div className={style.icon}>
                <Icon fixedWidth name={iconType} />
            </div>
            <div>{children}</div>
        </div>
    )

    if (container) return <Container>{alertBox}</Container>

    return alertBox
}

AlertMessage.propTypes = {
    children: PropTypes.node,
    container: PropTypes.bool,
    error: PropTypes.bool,
    warning: PropTypes.bool,
    success: PropTypes.bool,
}

export default AlertMessage

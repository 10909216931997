import axios from 'axios'
import config from 'config/web/config'
import Analytics from 'utils/analytics'

let prevValue = ''
let isValid = true

// TODO replace with new datalayer event helper.
const validationEvent = {
    event: 'Interaction',
    eventCategory: 'form interactions',
    eventAction: 'form: signup',
    eventLabel: 'error - username unavailable'
}

export default async function validateUsername(value: string, message: string) {
    if (value !== prevValue) {
        prevValue = value
        return axios
            .get(`${config.signupServiceUrl}/IsUsernameAvailable/${value}`)
            .then(result => {
                // console.log('TCL: validateUsername -> result', result)
                if (result.status === 200) {
                    isValid = result.data
                    if (!isValid) Analytics.PushEventObject(validationEvent)
                }
                return isValid ? '' : message
            })
    }

    return isValid ? '' : message
}

import config from 'config/web/config'
// import { isProduction } from './web/configHelper'

export function getPaths() {
    // const config = getConfiguration(isProduction ? 'production' : 'development')

    const paths = {
        webBaseUrl: config.webBaseUrl,
        apiBaseUrl: config.apiBaseUrl,
        sectionsApiUrl: `${config.apiBaseUrl}/sections`,
        articlesApiUrl: `${config.apiBaseUrl}/articles`,
        pagesApiUrl: `${config.apiBaseUrl}/pages`,
        monumentSearchApiUrl: config.monumentSearchApiUrl,
        adGlareUrl: {
            small: config.adGlare.small,
            medium: config.adGlare.medium,
            large: config.adGlare.large,
        },
        launcherVersionsUrl: config.launcherVersionsUrl,
        launcherVersionDownloadUrl: config.launcherVersionDownloadUrl,
        sentryDsn: config.sentryDsn,
        shareFacebook: config.shareFacebook,
        shareTwitter: config.shareTwitter,
        shareGoogle: config.shareGoogle,
        shareReddit: config.shareReddit,
        shareVk: config.shareVk,
    }

    return paths
}

const paths = getPaths()

export default paths

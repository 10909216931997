import { pageActions } from './lib/pages/actions'

const routesMap = {
    ERROR: {
        path: '/error',
    },
    PAGE: {
        path: '/:lang(|en|de|fr|ru|ja)?/:page?/:subpage?/:id?',
        thunk: pageActions,
    },
}

export default routesMap

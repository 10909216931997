import React from 'react'
import _map from 'lodash/map'
import { Helmet } from 'react-helmet-async'
import ReactMarkdown from 'react-markdown'
import SectionType from 'models/types/ts/sectionType'
import ContentType from 'models/types/ts/contentType'
import { Section, Container, Heading } from 'layouts'
import { HeadingRegular } from 'layouts/typography'

import style from './Faq.module.scss'

interface Props {
    section: SectionType,
    headingStyle: string
}

export default function Faq({ section, headingStyle }: Props) {

    const items = section && section.contentCollection && section.contentCollection.items

    function renderHelmet() {
        const getFaqStructured = _map(items, (item: ContentType) => (
            `{
                "@type": "Question",
                "name": "${item.headline}",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "${item.body}"
                }
              }`
        ))

        const scriptInnerHTML = `
        {
            "@context": "http://schema.org",
            "@type": "FAQPage",
            "mainEntity": [${getFaqStructured}]
        }
        `

        const script = [
            { type: 'application/ld+json', innerHTML: scriptInnerHTML }
        ]

        return (
            <Helmet script={script} />
        )
    }

    const renderItems = _map(items, (item: ContentType, key: string) => (
        <div className={style.items} key={key}>
            <p className={style.question}>{item.headline}</p>
            <div className={style.answer}>
                <ReactMarkdown source={item.body} escapeHtml={false} />
            </div>
        </div>
    ))

    if(section && items) {
        return (
            <Section className={style.faq}>
                <Container>
                    <HeadingRegular className={headingStyle}>{section.headline}</HeadingRegular>
                    {renderItems}
                </Container>
                {renderHelmet()}
            </Section>
        )
    }

    return <></>
    
}
import { setValues } from './ProtoValuesStorage'
import utmType from './models/utmType'

export function trimUtmQueryParameters(query: utmType) {
    // console.log('query: ', query)
    let trimmedUtm: utmType
    if(query) {        
        trimmedUtm = {
            gclid: query.gclid,
            utm_source: query.utm_source,
            utm_medium: query.utm_medium,
            utm_campaign: query.utm_campaign,
            utm_term: query.utm_term
        }
    }
    // console.log('trimmed: ', trimmedUtm)
    return trimmedUtm
}

export default function processQueryParameters(query: utmType) {

    // Trimming down to only what is allowed in query parameters for utm event tracking
    // TODO MOVE TO TRIM FUNCTION THAT CAN BE CALLED ELSEWHERE ALSO
    const utm = trimUtmQueryParameters(query)


    // TODO here only setvalues if certain things are set, likely the gclid in query paramater.
    // Have to make sure we won't override the values with other query parameters.
    if(utm && utm.gclid) {
        console.log('Processing query parameters: ', utm)
        setValues(utm)
    }
    else {
        console.log('NOT Processing query parameters: ', utm)
    }

    // console.log('Inside processQueryParameters and query is ', query)
    // console.log('Inside processQueryParameters and query stripped is ', utm)
}


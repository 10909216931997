import { connect } from 'react-redux'
import SectionType from 'models/types/ts/sectionType'
import { findSectionByIdentifier } from 'lib/pages/api'
import getMetadata from 'selectors/metadata'
// import mockData from './yearMock.json'
import YearReview from './components'

function mapStateToProps({ pages: { year }, location }) {
    // console.log('location: ', location)

    // const id = 'DqTAaUxhIFE'

    let pageBackground = ''
    let baseUrl = 'https://e.idomoo.com/' // this is overwritten in the page config json baseUrl property if it is set.

    let hero : SectionType = null
    let cta : SectionType = null
    let extra : SectionType = null
    let bottom : SectionType = null
    let metadata = null

    let hasContent = false

    const yearPage = year

    // console.log('subpage= ', location)

    const id = location && location.query && location.query.id
    // console.log('ID: ', id)
    const idSet = id !== undefined

    // console.log('YEAR PAGE: ', yearPage)

    if(yearPage) {
        baseUrl = yearPage.config && yearPage.config.baseUrl
        pageBackground = yearPage.pageBackground
        hero = findSectionByIdentifier(yearPage.sections, 'year-hero')
        cta = findSectionByIdentifier(yearPage.sections, 'year-cta')
        extra = findSectionByIdentifier(yearPage.sections, 'year-extra')
        bottom = findSectionByIdentifier(yearPage.sections, 'year-bottom')
        metadata = getMetadata(yearPage)
        hasContent = true
    }

    return {
        id,
        idSet,
        baseUrl,
        pageBackground,
        hero,
        cta,
        extra,
        bottom,
        metadata,
        hasContent
    }
    // console.log('idSet: ', idSet)

    // TODO dispatch redirect here if parameter is not set or not found

    // return {
    //     id,
    //     idSet
    // }
}

export default connect(
    mapStateToProps,
    null
)(YearReview)

import axios from 'axios'
import config from 'config/web/config'
import Analytics from 'utils/analytics'

let prevValue = ''
let isValid = true

// TODO replace with new datalayer event helper.
const validationEvent = {
    event: 'Interaction',
    eventCategory: 'form interactions',
    eventAction: 'form: signup',
    eventLabel: 'error - invalid email'
}

export default async function validateEmail(value: string, message: string) {
    // console.log('TCL: validateEmail -> value', value)
    const signupApiUrl = config.signupServiceUrl
    // const signupApiUrl = 'https://localhost:44337/'

    if (value !== prevValue) {
        prevValue = value
        return axios
            .post(`${signupApiUrl}/isEmailValid`, {
                emailAddress: value
            })
            .then(result => {
                // console.log('TCL: validateEmail -> result', result)
                if (result.status === 200) {
                    isValid = result.data
                    if (!isValid) Analytics.PushEventObject(validationEvent)
                }
                return isValid ? '' : message
            })
    }

    return isValid ? '' : message
}

import { connectRoutes } from 'redux-first-router'
import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { localizeReducer } from 'react-localize-redux'
import createLoggingMiddleware from './middlewares/logging/middleware'
import analyticsReporter from './middlewares/analytics/middleware'
import localizationMiddleware from './middlewares/localization/middleware'
import pages from './lib/pages/reducers'
import recruit from './lib/recruit/reducers'
import language from './lib/language/reducers'
import country from './lib/country/reducers'
import navigation from './lib/menu/reducers'
import ads from './features/ad-glare/reducer'
import downloadVersions from './features/DownloadButton/downloadButton'
import monuments from './pages/monument/reducers'
import routesMap from './routesMap'
import options from './utils/options'
import auth from './packages/authentication/lib/reducers'
import authMiddleware from './packages/authentication/lib/middleware'
import { isProduction, isClient, isLocal } from './config/web/configHelper'

const devEnabled = !isProduction && !isLocal

function configureStore(preLoadedState, initialEntries) {
    const {
        reducer,
        middleware,
        enhancer,
        thunk: rfrThunk
    } = connectRoutes(routesMap, { ...options, initialEntries })

    let reducers = {
        localize: localizeReducer,
        pages,
        language,
        country,
        navigation,
        ads,
        recruit,
        downloadVersions,
        monuments,
        location: reducer
    }

    // Adding auth reducer
    reducers = { auth, ...reducers }

    // and you already know how the story ends:
    const rootReducer = combineReducers(reducers)

    let composeEnhancers = compose
    const middlewares = [
        middleware,
        thunk,
        createLoggingMiddleware(),
        analyticsReporter(),
        localizationMiddleware()
    ]

    middlewares.push(authMiddleware)

    // Enable logger and redux dev tools in dev builds
    if (devEnabled) {
        middlewares.push(logger)
        // There is no window on the server
        if (isClient) {
            /* eslint-disable no-underscore-dangle */
            composeEnhancers =
                window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
            /* eslint-enable */
        }
    }

    const allMiddlewares = applyMiddleware(...middlewares)
    const enhancers = composeEnhancers(enhancer, allMiddlewares)
    // note the order: enhancer, then middlewares
    const store = createStore(rootReducer, preLoadedState, enhancers)

    return { store, thunk: rfrThunk }
}

export default configureStore

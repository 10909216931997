import { UAParser } from 'ua-parser-js'

const OS_WINDOWS = 'Windows'
const OS_MAC = 'Mac OS'
// const OS_CHROMIUM = 'Chromium OS'

export default class PlatformHelper {
    ua: UAParser
    constructor() {
        this.ua = new UAParser() // eslint-disable-line
    }

    setUA(uastring: string) {
        this.ua.setUA(uastring)
    }

    getUA() {
        return this.ua.getUA()
    }

    getOSName() {
        return this.ua.getOS().name
    }

    isWindowsOrMac() {
        const os = this.ua.getOS().name
        return os === OS_WINDOWS || os === OS_MAC
    }

    isWindows() {
        return this.ua.getOS().name === OS_WINDOWS
    }

    isMac() {
        return this.ua.getOS().name === OS_MAC
    }
}

import { redirect as rfrRedirect, NOT_FOUND } from 'redux-first-router'
import { isSingle } from 'lib/pages/selectors'
import { sitemap } from 'config'
import { languageChange } from '../language/actions'
import { hideNavigation } from '../menu/actions'
//import { fetchSharedContent } from './api'
import { getLanguage, getLanguages } from '../language/selectors'

export const FETCH_PAGE = 'FETCH_PAGE'
export const FETCH_PAGE_START = 'FETCH_PAGE_START'

export const FETCH_PAGE_HOME = 'FETCH_PAGE_HOME'
export const FETCH_PAGE_HOME_START = 'FETCH_PAGE_HOME_START'

export const FETCH_PAGE_PLAYER_RESOURCES = 'FETCH_PAGE_PLAYER_RESOURCES'
export const FETCH_PAGE_PLAYER_RESOURCES_START =
    'FETCH_PAGE_PLAYER_RESOURCES_START'
export const FETCH_PAGE_LIVE_EVENT = 'FETCH_PAGE_LIVE_EVENT'
export const FETCH_PAGE_LIVE_EVENT_START = 'FETCH_PAGE_LIVE_EVENT_START'
export const FETCH_PAGE_WORLD_TOUR = 'FETCH_PAGE_WORLD_TOUR'
export const FETCH_PAGE_WORLD_TOUR_START = 'FETCH_PAGE_WORLD_TOUR_START'
export const FETCH_PAGE_ARTICLES = 'FETCH_PAGE_ARTICLES'
export const FETCH_PAGE_ARTICLES_START = 'FETCH_PAGE_ARTICLES_START'
export const FETCH_PAGE_ARTICLES_SUCCESSFUL = 'FETCH_PAGE_ARTICLES_SUCCESSFUL'
export const FETCH_PAGE_ARTICLES_CATEGORY = 'FETCH_PAGE_ARTICLES_CATEGORY'
export const FETCH_PAGE_ARTICLES_CATEGORY_START =
    'FETCH_PAGE_ARTICLES_CATEGORY_START'
export const FETCH_PAGE_ARTICLE = 'FETCH_PAGE_ARTICLE'
export const FETCH_PAGE_ARTICLE_START = 'FETCH_PAGE_ARTICLE_START'
export const FETCH_DYNAMIC_PAGE = 'FETCH_DYNAMIC_PAGE'
export const FETCH_DYNAMIC_PAGE_START = 'FETCH_DYNAMIC_PAGE_START'
export const FETCH_SHARED_CONTENT = 'FETCH_SHARED_CONTENT'
export const FETCH_SHARED_CONTENT_START = 'FETCH_SHARED_CONTENT_START'
export const FETCH_PAGE_COMMUNITY = 'FETCH_PAGE_COMMUNITY'
export const FETCH_PAGE_OPTIMIZE_EVENT = 'FETCH_PAGE_OPTIMIZE_EVENT'
export const FETCH_PAGE_TRANSITION_EVENT = 'FETCH_PAGE_TRANSITION_EVENT'

export const FETCH_ACCOUNT_DATA = 'FETCH_ACCOUNT_DATA'
export const FETCH_ACCOUNT_DATA_START = 'FETCH_ACCOUNT_DATA_START'
export const FETCH_ACCOUNT_DATA_SUCCESSFUL = 'FETCH_ACCOUNT_DATA_SUCCESSFUL'
export const UPDATE_ACCOUNT_DATA_AGREEMENT = 'SET_ACCOUNT_DATA_AGREEMENT_STATUS'

export const TOGGLE_ACCOUNT_MENU = 'TOGGLE_ACCOUNT_MENU'

// const SECTIONS_URL = paths.sectionsApiUrl
// const ARTICLES_URL = paths.articlesApiUrl

export const setPage = (id, page) => ({
    type: FETCH_PAGE,
    id,
    payload: { page },
})
export const setPageStarted = id => ({
    type: FETCH_PAGE_START,
    id,
})

export const setHome = home => ({ type: FETCH_PAGE_HOME, payload: { home } })
export const setHomeStarted = () => ({ type: FETCH_PAGE_HOME_START })

export const setPlayerResources = section => ({
    type: FETCH_PAGE_PLAYER_RESOURCES,
    payload: { section },
})
export const setPlayerResourcesStarted = () => ({
    type: FETCH_PAGE_PLAYER_RESOURCES_START,
})
export const setLiveEvent = liveEventPages => ({
    type: FETCH_PAGE_LIVE_EVENT,
    payload: { liveEventPages },
})
export const setLiveEventStarted = () => ({ type: FETCH_PAGE_LIVE_EVENT_START })

export const setWorldTour = page => ({
    type: FETCH_PAGE_WORLD_TOUR,
    payload: { page },
})
export const setWorldTourStarted = () => ({ type: FETCH_PAGE_WORLD_TOUR_START })
export const setArticles = articles => ({
    type: FETCH_PAGE_ARTICLES,
    payload: { articles },
})
export const setArticlesStarted = () => ({ type: FETCH_PAGE_ARTICLES_START })
export const setArticlesCategory = articlesCategory => ({
    type: FETCH_PAGE_ARTICLES_CATEGORY,
    payload: { articlesCategory },
})
export const setArticlesCategoryStarted = () => ({
    type: FETCH_PAGE_ARTICLES_CATEGORY_START,
})
export const setArticle = article => ({
    type: FETCH_PAGE_ARTICLE,
    payload: { article },
})
export const setArticleStarted = () => ({ type: FETCH_PAGE_ARTICLE_START })

export function setArticlesSuccessful(payload) {
    return {
        type: FETCH_PAGE_ARTICLES_SUCCESSFUL,
        payload,
    }
}

export function setGoogleOptimizeEvent() {
    return {
        type: FETCH_PAGE_OPTIMIZE_EVENT,
        // payload,
    }
}

export function setPageTransitionEvent() {
    // console.log('%cPAGE TRANSITION', 'color: #ad0011; font-size: 18px')
    return {
        type: FETCH_PAGE_TRANSITION_EVENT,
    }
}

export const setNow = page => ({ type: FETCH_DYNAMIC_PAGE, payload: { page } })
export const setNowStarted = () => ({ type: FETCH_DYNAMIC_PAGE_START })
/* export const setShared = shared => ({
    type: FETCH_SHARED_CONTENT,
    payload: { shared },
})
export const setSharedStarted = () => ({ type: FETCH_SHARED_CONTENT_START }) */

export const setError = () => ({ type: 'ERROR' })
export const setNotFound = () => ({ type: NOT_FOUND })
export const redirect = (page, subpage, id, lang) => ({
    type: 'PAGE',
    payload: { lang, page, subpage, id },
})

function getActivePage(page, subpage) {
    // If page is undefined in here then we are looking for home.
    const pageName = !page ? 'home' : page
    let activePage = sitemap[pageName]

    // Not logging tags anymore, lot of noise and the pages are obsolete or crawlers.
    if (!activePage) {
        // setTags({
        //     pageName,
        //     subpage,
        // })
        return undefined
    }

    // If subpage is set and main page has children then use that as activePage.
    // try {
    if (subpage && activePage.children) {
        activePage = activePage.children
    }
    // } catch (ex) {
    //     activePage = undefined
    //     ex.page = pageName
    //     ex.subpage = subpage
    //     throw ex
    // }

    return activePage
}

// Can be removed when these pages are in Contentful and configured like others.
const singleLegacyPages = ['discovery', 'monument']
function isSingleLegacyPage(page) {
    return singleLegacyPages.includes(page)
}

// Page actions is like action we had in controllers in the MVC pattern,
// except this one is shared for everything.
export async function pageActions(dispatch, getState) {
    const {
        payload: { lang, page, subpage, id },
    } = getState().location

    // Language
    let language = getLanguage(getState())
    let actionLanguage = lang
    const languages = getLanguages()
    if (lang == null || languages.indexOf(lang) === -1) {
        // Fallback to english
        actionLanguage = 'en'
    }
    if (actionLanguage !== language) {
        dispatch(languageChange(actionLanguage))
        language = actionLanguage
    }

    // There is /en/ in the url, remove it
    if (lang === 'en') {
        return dispatch(rfrRedirect(redirect(page, subpage, id)))
    }

    // Shared content
/*     if (!getState().pages.shared) {
        dispatch(setSharedStarted())
        const shared = await fetchSharedContent(language)
        dispatch(setShared(shared))
    } */

    const activePage = getActivePage(page, subpage)

    // Return NOT FOUND if sitemap does not contain page.
    if (!activePage) {
        console.log('dispatch not found in actions.js')
        return dispatch(setNotFound())
    }

    // Disabled pages
    if (activePage.disabled) {
        return dispatch(setNotFound())
    }

    // if the page is a single page with no subpages allowed, then remove all other parts of url, example /download
    // TODO can be removed when legacy pages are in Contentful, doing redirect below based on page.config.pageType value
    if (isSingleLegacyPage(page) && subpage) {
        return dispatch(rfrRedirect(redirect(page, null, null, lang)))
    }

    // There is /home/ in the url, remove it
    if (page === 'home') {
        if (actionLanguage === 'en') {
            return dispatch(rfrRedirect(redirect()))
        }
        return dispatch(
            rfrRedirect(
                redirect(undefined, undefined, undefined, actionLanguage),
            ),
        )
    }

    // Redirects
    if (activePage.redirect) {
        const action = activePage.redirect
        action.payload.lang = language
        return dispatch(rfrRedirect(action))
    }

    // Show/Hide the navigation
    dispatch(hideNavigation(activePage.hideMenu))

    // Shared content
/*     if (!getState().pages.shared) {
        dispatch(setSharedStarted())
        const shared = await fetchSharedContent(language)
        dispatch(setShared(shared))
    } */

    if (activePage.populate) {
        // Has populate function so calling it.
        const result = await activePage.populate(dispatch, getState, language)

        // Added to make sure that pages that are single should not have sub-subpages so redirecting to the base subpage.
        if (result && result.payload && result.payload.page) {
            if (isSingle(result.payload.page) && subpage) {
                return dispatch(rfrRedirect(redirect(page, null, null, lang)))
            }
        }

        dispatch(setGoogleOptimizeEvent())
        // return result
    } else {
        dispatch(setGoogleOptimizeEvent())
    }

    switch (activePage.id) {
        case 'store':
            // console.log(
            //     '%cActions.js - store',
            //     'color: green; font-size: 16px',
            //     activePage
            // )
            // No Dispatch, doing Page Transition in store code
            break
        case 'callback':
            // console.log(
            //     '%cActions.js - callback',
            //     'color: green; font-size: 16px',
            //     activePage
            // )
            // No Dispatch, doing Page Transition in store code
            break
        case 'login':
            // console.log(
            //     '%cActions.js - login',
            //     'color: green; font-size: 16px',
            //     activePage
            // )
            // No Dispatch, the login page is being hit when there isn´t a user initiated login taking place.
            // To prevent faulty data I´m opting to remove the login hit.
            break
        default:
            // console.log(
            //     '%cActions.js - default',
            //     'color: green; font-size: 16px'
            // )
            // console.log('activePage', activePage)
            dispatch(setPageTransitionEvent())
            break
    }
}

export const setAccountData = account => ({
    type: FETCH_ACCOUNT_DATA,
    payload: { account },
})

export const setAccountDataStarted = () => ({ type: FETCH_ACCOUNT_DATA_START })

export function setAccountDataSuccessful(payload) {
    return {
        type: FETCH_ACCOUNT_DATA_SUCCESSFUL,
        payload,
    }
}

export const setAgreementStatus = payload => ({
    type: UPDATE_ACCOUNT_DATA_AGREEMENT,
    payload,
})

export const openAccountMenu = () => ({
    type: TOGGLE_ACCOUNT_MENU,
    payload: { open: true },
})

export const closeAccountMenu = () => ({
    type: TOGGLE_ACCOUNT_MENU,
    payload: { open: false },
})

export const toggleAccountMenu = open => ({
    type: TOGGLE_ACCOUNT_MENU,
    payload: { open },
})

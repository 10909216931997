import _isEqual from 'lodash/isEqual'
import _map from 'lodash/map'
import _orderBy from 'lodash/orderBy'
import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import Table from '../table'

class SortTable extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sortIndex: null,
            descending: null,
            columns: null,
            rows: null,
        }
    }

    componentDidMount = () => {
        console.log('mounted', this.props)
        this.sortFromProps()
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.sortColumn !== prevProps.sortColumn) {
            // Sorted column has changed
            console.log(
                `Sorting column changed from '${prevProps.sortColumn}' => '${
                    this.props.sortColumn
                }' and sort direction is '${
                    this.props.descending ? 'descending' : 'ascending'
                }'`
            )
            this.sortFromProps()
            // this.setState({
            //     sortColumn: this.props.sortColumn,
            //     descending: this.props.descending,
            // })
        } else if (this.props.descending !== prevProps.descending) {
            // Sorted direction has changed
            console.log(
                `Sorting direction changed from '${
                    prevProps.descending ? 'descending' : 'ascending'
                }' => '${this.props.descending ? 'descending' : 'ascending'}'`
            )
            this.sortFromProps()
            // this.setState({ descending: this.props.descending })
        } else if (!_isEqual(prevProps.columns, this.props.columns)) {
            this.sortFromProps()
        } else if (!_isEqual(prevProps.rows, this.props.rows)) {
            this.sortFromProps()
        }
    }

    sortAndSave = (sortIndex, descending) => {
        // Create the columns for the state
        const columns = this.props.columns.map((col, i) => {
            const result = { ...col }
            if (i === sortIndex) {
                result.sorted = true
                result.descending = descending
            }
            return result
        })

        // Sort the rows
        const sortedRows = _orderBy(
            this.props.rows,
            [
                row => {
                    if (row[sortIndex].sortValue != null) {
                        return row[sortIndex].sortValue
                    }
                    return row[sortIndex].text
                },
            ],
            [descending ? 'desc' : 'asc']
        )

        // Create the rows for the state
        const rows = sortedRows.map(row => row.map(col => ({ text: col.text, className: col.className })))

        this.setState({
            sortIndex,
            descending,
            rows,
            columns,
        })
    }

    sortFromProps = () => {
        console.log('start sorting')

        const { sortIndex } = this.props

        // Sortindex is missing
        if (sortIndex == null) {
            this.setState({
                sortIndex: -1,
                descending: null,
                columns: this.props.columns,
                rows: this.props.rows,
            })
            return
        }

        // Rows or columns missing
        if (
            this.props.columns == null ||
            this.props.columns.length < 1 ||
            this.props.rows == null ||
            this.props.rows.length < 1
        ) {
            this.setState({
                sortIndex: -1,
                descending: null,
                columns: null,
                rows: null,
            })
            return
        }

        // SortIndex is invalid
        if (sortIndex < 0 || sortIndex > this.props.columns.len) {
            this.setState({
                sortIndex: -1,
                descending: null,
                columns: this.props.columns,
                rows: this.props.rows,
            })
            return
        }

        this.sortAndSave(sortIndex, this.props.descending || false)
    }

    resort = (sortIndex, alreadySorted, descending) => {
        console.log('sorting hello', sortIndex, alreadySorted, descending)
        let desc = this.props.columns[sortIndex].sortDirection === 'desc'
        if (alreadySorted) {
            desc = !descending
        }

        this.sortAndSave(sortIndex, desc)
    }

    render() {
        if (this.state.columns == null || this.state.rows == null) {
            return null
        }

        const tableProps = {
            className: this.props.className,
            columns: [...this.state.columns],
            rows: [...this.state.rows],
        }

        if (this.state.sortIndex != null && this.state.sortIndex > -1) {
            return (
                <Table
                  {...tableProps}
                  sortable
                  sort={(name, sorted, descending) => this.resort(name, sorted, descending)
                    }
                />
            )
        }

        return <Table {...tableProps} />
    }
}

SortTable.propTypes = {
    className: PropTypes.string,
    sortIndex: PropTypes.number,
    descending: PropTypes.bool,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            className: PropTypes.string,
            sortDirection: PropTypes.string,
        })
    ),
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string,
                sortValue: PropTypes.string,
                className: PropTypes.string,
            })
        )
    ),
}

// `You have chosen to sort ${sorted ? '(re-order)' : ''}${
//     direction === 'desc' ? 'ascending' : 'descending'
// } by column: ${name}`

export default SortTable

import React from 'react'
import { useSelector } from 'react-redux'
import { getQuery, getLocation } from 'lib/location/selectors'
import { isClient } from 'config/web/configHelper'
import classNames from 'classnames'
import { Frame, Icon } from 'layouts'
import { TaglineSmall } from 'layouts/typography'
import style from './emailSignup.module.scss'

interface Props {
    config: {
        emailSignup?: boolean,
        lid?: string,
        mid?: number,
    }
}


const EmailSignup = ({ config }: Props) => {
    const query = useSelector((state) => getQuery(state))
    const location = useSelector((state) => getLocation(state))

    function renderErrorMsg(code) {        
        switch(code) {
            case '4':
                return '無効なメールアドレス.'
            case '8':
                return 'このEメールは既に登録されています。'
            default:
                return '予期せぬエラーが発生しました。後ほど再度お試しください。'
        }        
    }

    function renderEmailSignup() {
        if (isClient) {

            const theFormHtml = `<form action="https://cl.exct.net/subscribe.aspx?lid=${config.lid}" name="subscribeForm" method="post" onSubmit="return checkForm();" > ` +
            ` <input type=hidden name="thx" value="${window.location.origin}${location.pathname}?success=true"> ` +
            ` <input type=hidden name="err" value="${window.location.origin}${location.pathname}?success=false"> ` + 
            ` <input type=hidden name="MID" value="${config.mid}"> ` +
            ` <input type="text" placeholder="メール" name="Email Address"> ` +
            ` <input type="submit" value="今すぐ事前登録"> ` + 
            ` </form>`

            const goBack = '<input action="action" onclick="window.history.go(-1); return false;" type="submit" value="再度入力する" />'


            if(query?.success === "true"){
                return (
                    <div className={classNames(style.message, style.success)}>
                        <Icon regular name="check-circle" />ご登録ありがとうございます
                    </div>
                )
            }
            else if(query?.success === "false")
            {
                return (
                    <div className={classNames(style.message, style.warning)}>
                        <Icon regular name='exclamation-circle' /> {renderErrorMsg(query?.errorcode)}
                        <div 
                          className={style.backButton} 
                          //    eslint-disable-next-line
                          dangerouslySetInnerHTML={{ __html: goBack }}
                        />
                    </div>
                )
            }
            return (
                <>
                    <TaglineSmall>事前登録をして『EVE Online日本語版』の最新情報をゲットしよう！</TaglineSmall>
                    <div
                      //    eslint-disable-next-line
                      dangerouslySetInnerHTML={{ __html: theFormHtml }} 
                    />
                    <p className={style.disclaimer}>CCP Gamesからプロモーションメールを受信することに同意します</p>
                </>
            )
        }
    }    

    return (
        <Frame className={style.frameContainer}>
            <div className={style.emailSignup}>
                {isClient && renderEmailSignup()}
            </div>
        </Frame>
    )
}

export default EmailSignup

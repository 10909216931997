import React, { Fragment } from 'react'

export default () => (
    <Fragment>
        <h2>Nutzungsbedingungen</h2>
        <p>
            Unsere Webseite verwendet Cookies. Ohne die Verwendung von Cookies
            funktionieren die Registrierung und die Webseite nicht richtig.
            Indem Sie die Registrierung fortsetzen und Ihre E-Mail-Adresse
            bestätigen, akzeptieren Sie die Verwendung von Cookies. Falls Sie
            der Verwendung von Cookies nicht zustimmen, bestätigen Sie bitte
            nicht Ihre E-Mail-Adresse und schließen Sie nicht die Registrierung
            ab. Für weitere Informationen zu unseren Richtlinien in Bezug auf
            Cookies, lesen Sie bitte unsere{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-eula/"
              rel="noopener noreferrer"
              target="_blank">
                EVE Online Endbenutzer-Lizenzvereinbarung
            </a>{' '}
            und{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-privacy-policy/"
              rel="noopener noreferrer"
              target="_blank">
                Datenschutzerklärung
            </a>
            .
        </p>
        <p>
            Indem Sie fortfahren, bestätigen Sie, dass Sie die{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-eula/"
              rel="noopener noreferrer"
              target="_blank">
                EVE Online Endbenutzer-Lizenzvereinbarung
            </a>
            ,{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-tos/"
              rel="noopener noreferrer"
              target="_blank">
                Nutzungsbedingungen
            </a>{' '}
            und{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-privacy-policy/"
              rel="noopener noreferrer"
              target="_blank">
                Datenschutzerklärung
            </a>
            . gelesen haben und diesen zustimmen. Sie bestätigen außerdem, dass
            CCP Ihre E-Mail-Adresse nutzen darf, um Ihre Einkäufe zu
            verifizieren, Kundendienst anzubieten, technische und inhaltliche
            Aktualisierungen zu EVE Online zu verschicken, sowie für andere
            accountbezogene Mitteilungen.
        </p>
    </Fragment>
)

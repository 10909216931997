import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import Visibility from 'react-visibility-sensor'
import ReactMarkdown from 'react-markdown'
import { pushClickEventToDataLayer } from 'utils/analytics/datalayer/dataLayerFunctions'
import LazyLoad from 'react-lazyload'
import { Button } from 'layouts'
// import { getPathname } from 'lib/location/selectors'
import Link from '../../shared/link'
import { ThemeContext, LocationContext } from '../../../context'
import { lightOrDark, getImage, slugify } from '../../../helpers'
import style from './Card.scss'

class Card extends Component {
    state = {
        active: false,
    }

    onChange = isVisible => {
        if (isVisible) {
            this.setState({ active: true })
        }
    }


    renderMedia = file => {
        const fileType = file.split('.').pop()

        /* eslint jsx-a11y/media-has-caption: 0 */
        if (fileType === 'mp4' || fileType === 'webm') {
            return (
                <video playsInline autoPlay muted loop>
                    <source src={file} type={`video/${fileType}`} />
                </video>
            )
        }

        return (
            <LazyLoad
              height={478}
              offset={400}
              once
            >
                <img
                  src={`${file}?w=850`}
                  alt=""
                  size="mediumSmall"
                  height={478}
                  offset={400}
            />
            </LazyLoad>
        )
    }

    render() {
        const { id, card } = this.props
        const {
            name,
            headline,
            body,
            imageFile,
            buttonText,
            buttonTheme,
            buttonUrl,
        } = card

        const { active } = this.state
        const alignmentStyle = id % 2 === 0 ? style.left : style.right

        return (
            <ThemeContext.Consumer>
                {colorTheme => (
                    <LocationContext.Consumer>
                        {location => (
                            <div
                              id={slugify(name)}
                              className={style(style.card, alignmentStyle, {
                                    [style.active]: active,
                            })}
                              key={id}
                            >
                                <div className={style.inner}>
                                    {imageFile && this.renderMedia(getImage(imageFile))}
                                    <Visibility
                                      onChange={this.onChange}
                                      partialVisibility
                                      active={!active}
                                    >
                                        <div
                                          className={style(style.text, { [style.active]: active })}
                                          style={{ backgroundColor: colorTheme.cardTemplate }}
                                        >
                                            <ReactMarkdown
                                              renderers={{
                                                heading: ({ children, level }) => createElement(`h${level}`, {style: { color: colorTheme.headline }}, children),
                                                paragraph: ({ children }) => <p style={{ color: lightOrDark(colorTheme.cardTemplate) }}>{children}</p>,
                                                link: ({ children, href }) => <Link href={href} onClick={ () => pushClickEventToDataLayer('Button click', `page: ${location}, url: ${href}`)} className={style.link} color={colorTheme.link}>{children}</Link>,
                                            }}
                                              source={body}
                                              escapeHtml={false}
                                            />
                                            {buttonText != null && (
                                                <Button
                                                  className={style.btn}
                                                  //   data-id={`dynamic-card-button-${buttonText}`}
                                                  data-id="5435434"
                                                  target={'_blank'}
                                                  path={buttonUrl}
                                                  onClick={() => pushClickEventToDataLayer('Button click', `page: ${location}, url: ${buttonUrl}`) }
                                                  size="small"
                                                  custom={{
                                                        color: colorTheme.button.color,
                                                        background: colorTheme.button.background,
                                                }}
                                                >
                                                    {buttonText}
                                                </Button>
                                            )}
                                        </div>
                                    </Visibility>
                                </div>
                            </div>
                        )}
                    </LocationContext.Consumer>
                )}
            </ThemeContext.Consumer>
        )
    }
}

Card.propTypes = {
    card: PropTypes.shape({
        name: PropTypes.string,
        body: PropTypes.string,
        imageFile: PropTypes.shape({
            url: PropTypes.string,
        }),
        buttonText: PropTypes.string,
        buttonTheme: PropTypes.string,
        buttonUrl: PropTypes.string,
    }),
    id: PropTypes.number,
}

export default Card

import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { pushClickEventToDataLayer } from 'utils/analytics/datalayer/dataLayerFunctions'
import Picture from 'features/picture'
import Link from '../shared/link'
import { getImage } from '../../helpers'
import { ThemeContext, LocationContext } from '../../context'
import style from './Featured.scss'

const Featured = ({ section }) => (
    <div className={style.featured}>
        <div className={style.wrapper}>
            {section.buttonUrl ? (
                <a
                  href={section.buttonUrl}
                  className={style.link}
                  data-id="dynamic-featured-cta-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                    <Picture src={getImage(section.imageFile)} alt="" />
                </a>
            ) : (
                <Picture src={getImage(section.imageFile)} alt="" />
            )}
            {section.body && (
                <div className={style.content}>
                    <ThemeContext.Consumer>
                        {colorTheme => (
                            <LocationContext.Consumer>
                                {location => (
                                    <ReactMarkdown
                                      source={section.body}
                                      escapeHtml={false}
                                      renderers={{
                                            heading: ({ children, level }) => createElement(
                                                    `h${level}`,
                                                    {
                                                        style: {
                                                            color:
                                                                colorTheme
                                                                    .featuredTemplate
                                                                    .headline,
                                                        },
                                                    },
                                                    children
                                                ),
                                            link: ({ children, href }) => (
                                                <Link
                                                  href={href}
                                                  color={colorTheme.featuredTemplate.link}
                                                  onClick={ () => pushClickEventToDataLayer('Button click', `page: ${location}, url: ${href}`)}
                                                >
                                                    {children}
                                                </Link>
                                            ),
                                        }}
                                    />
                                )}
                            </LocationContext.Consumer>
                        )}
                    </ThemeContext.Consumer>
                </div>
            )}
        </div>
    </div>
)

Featured.propTypes = {
    section: PropTypes.shape({
        body: PropTypes.string,
        imageFile: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
}

export default Featured

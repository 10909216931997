import React from 'react'
import { flags } from 'config'
import PropTypes from 'prop-types'
import Link from 'features/link'
import { pushNavigationClickEventToDataLayer } from 'utils/analytics/datalayer/dataLayerFunctions'
import Icon from 'layouts/font-awesome'
import style from './NavItem.scss'

const getIcon = (external) => {
    const props = {
        name: external ? 'external-link-square' : 'chevron-right',
        className: style('icon', { external, mobileView: !external })
    }
    return <Icon {...props} />
}

const getChildren = (external, translate, translationId) => {
    const bar = <div key="bar" className={style.bar} />
    const text = (
        <div key="text" className={style.text}>
            <div className={style.title}>{translate(translationId)}</div>
            <div className={style.logo}>{getIcon(external)}</div>
        </div>
    )
    return [bar, text]
}

const NavItem = ({
    selected,
    external,
    page,
    sub,
    href,
    dataId,
    className: externalClass,
    translate,
    translationId
}) => {
    let className = style(style.item, {
        [style.selected]: selected,
        [style.colorTheme]: flags.features.colorThemeEnabled
    })

    if (externalClass !== undefined) {
        className = `${externalClass} ${className}`
    }

    let Tag = Link

    const props = {
        className,
        'data-id': dataId
    }

    if (page) {
        props.path = { page, subpage: sub }
    } else {
        Tag = 'a'
        props.href = href

        if (external) {
            props.target = '_blank'
            props.rel = 'noopener noreferrer'
        }
    }

    return (
        <Tag
          onClick={() => pushNavigationClickEventToDataLayer(dataId)}
          {...props}
        >
            {getChildren(external, translate, translationId)}
        </Tag>
    )
}

NavItem.propTypes = {
    className: PropTypes.string,
    selected: PropTypes.bool,
    external: PropTypes.bool,
    page: PropTypes.string,
    sub: PropTypes.string,
    href: PropTypes.string,
    dataId: PropTypes.string,
    translate: PropTypes.func,
    translationId: PropTypes.string
}

export default NavItem

import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import localize from 'features/navigation/components/localize'
import { isClient } from 'config/web/configHelper'
import Link from 'features/link'
import { SelectLanguage } from 'features'
import { flags } from 'config'
// import AccountNav from 'features/account-navigation'
import AccountMenu from 'features/account-management-menu'
import { Icon, SvgIcon } from 'layouts'
import NavItems from './components/navitems'
import MobileMenu from './components/mobile-menu'
import CTA from './components/cta'
// import Notification from './components/notification'
import style from './Navigation.scss'

class Navigation extends Component {
    constructor(props) {
        super(props)

        const timeoutId = setTimeout(() => {
            this.setState({ render: true })
        }, 300)

        this.state = {
            timeoutId,
            render: false,
            scenario: 'TopShow',
        }

        this.start = new Date().valueOf()
        this.height = '100vh'
        this.minHeight = '100vh'
        this.scroll = 0

        if (isClient) {
            // First we add a listener to listen for window width changes
            // This is to make sure we only show the opened mobile menu when the
            // size of the browser should show the mobile menu
            this.widthMediaQuery = window.matchMedia('(max-width: 1280px)')
            this.widthMediaQuery.addListener((listener) => this.handleWidthChange(listener))
            this.handleWidthChange(this.widthMediaQuery)

            // Then we add another listener for height changes
            // This is to make sure the menu is never smaller than 650px
            this.heightMediaQuery = window.matchMedia('(max-height: 650px)')
            this.heightMediaQuery.addListener((listener) => this.handleHeightChange(listener))
            this.handleHeightChange(this.heightMediaQuery)
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.mobileOpen !== this.props.mobileOpen) {
            if (this.props.mobileOpen) {
                this.setOverflow()
            } else {
                this.removeOverflow()
            }
        }
    }

    componentWillUnmount() {
        this.widthMediaQuery.removeListener(this.handleWidthChange)
        this.heightMediaQuery.removeListener(this.handleHeightChange)
        this.removeOverflow()
        clearTimeout(this.state.timeoutId)
        window.removeEventListener('scroll', this.handleScroll)
    }

    getClassName = () => {
        const { scenario } = this.state
        const { mobileOpen: open, lightTheme: light } = this.props

        return style('navigation', {
            hide: scenario === 'ScrollTimer' || scenario === 'TopHide',
            scrolled: scenario === 'ScrollShow',
            show: scenario === 'TopShow' || scenario === 'ScrollShow',
            open,
            closed: !open,
            light,
        })
    }

    setOverflow = () => {
        this.scroll = window.scrollY
        const html = document.getElementsByTagName('html')[0]
        const body = html.getElementsByTagName('body')[0]
        const main = body.getElementsByTagName('main')[0]
        if (this.scroll > 0) {
            const top = this.scroll * -1
            main.style.top = `${top}px`
        }
        // html.classList.add('no-scroll')
        body.style.height = this.height
        body.style.minHeight = this.minHeight
        window.scrollTo(0, 0)
    }

    toggleOpen = () => {
        this.props.toggleMobileNavigation()
    }

    closeMenu = () => {
        this.props.closeMobileNavigation()
    }

    handleWidthChange = (listener) => {
        if (!listener.matches) {
            this.removeOverflow(this.props.mobileOpen)
        } else if (this.props.mobileOpen) {
            this.setOverflow()
        }
    }

    handleHeightChange = (listener) => {
        this.height = listener.matches ? '650px' : '100vh'
        this.minHeight = listener.matches ? '600px' : '100vh'

        // If we are in mobile view and the menu is showing
        // Then we update the height and minHeight on body
        if (this.widthMediaQuery.matches && this.props.mobileOpen) {
            const html = document.getElementsByTagName('html')[0]
            const body = html.getElementsByTagName('body')[0]
            body.style.height = this.height
            body.style.minHeight = this.minHeight
        }
    }

    handleScroll = () => {
        if (!this.props.showNavigation) return

        if (this.props.mobileOpen && this.widthMediaQuery.matches) return

        // If window believes the scroll is 0, check what we have stored, since
        // it might be a case of opening/closing the menu
        const scroll = window.scrollY === 0 ? this.scroll : window.scrollY
        if (this.state.render) {
            if (
                this.state.scenario !== 'ScrollTimer' &&
                this.state.scenario !== 'ScrollShow' &&
                this.state.scenario !== 'TopHide' &&
                scroll >= 10
            ) {
                this.setState({ scenario: 'TopHide' })
            } else if (this.state.scenario !== 'TopShow' && scroll < 10) {
                clearTimeout(this.state.timeoutId)
                this.setState({ scenario: 'TopShow' })
            } else if (
                this.state.scenario !== 'ScrollTimer' &&
                this.state.scenario !== 'ScrollShow' &&
                scroll >= 10
            ) {
                const timeoutId = setTimeout(() => {
                    this.setState({ scenario: 'ScrollShow' })
                }, 500)
                this.setState({ scenario: 'ScrollTimer', timeoutId })
            }
        } else if (scroll < 10 && this.state.scenario !== 'TopShow') {
            this.setState({ scenario: 'TopShow' })
        } else if (scroll >= 10 && this.state.scenario !== 'ScrollShow') {
            this.setState({ scenario: 'ScrollShow' })
        }
    }

    removeOverflow = (resetScroll = true) => {
        const html = document.getElementsByTagName('html')[0]
        const body = html.getElementsByTagName('body')[0]
        const main = body.getElementsByTagName('main')[0]
        // html.classList.remove('no-scroll')
        body.style.height = null
        body.style.minHeight = null
        if (main) {
            main.style.top = null
        }
        if (resetScroll) {
            window.scrollTo(0, this.scroll)
            this.scroll = 0
        }
    }

    getColorFromTheme = () => {
        let color = '#fff'

        if (
            this.props.lightTheme &&
            this.state.scenario === 'TopShow' &&
            !this.props.mobileOpen
        ) {
            // logoSrc = logoDark
            color = '#000'
        }
        return color
    }

    renderLogo = () => {
        // let logoSrc = '//web.ccpgamescdn.com/aws/eveonline/images/eve_logo.png'
        // let logoColor = '#fff'

        // if (
        //     this.props.lightTheme &&
        //     this.state.scenario === 'TopShow' &&
        //     !this.props.mobileOpen
        // ) {
        //     // logoSrc = logoDark
        //     logoColor = '#000'
        // }

        const logoColor = this.getColorFromTheme()

        return (
            <div
              role="navigation"
              key="titleContent"
              className={style.titleContent}
              onClick={() => {
                    this.closeMenu()
                }}
            >
                <Link className={style.logo} title="Home">
                    <SvgIcon
                      width={130}
                      name="eve-logo-with-text"
                      fill={logoColor}
                    />
                </Link>
            </div>
        )
    }

    renderMenuTitleFiller = () => (
        <div key="titleFiller" className={style.titleFiller} />
    )

    renderMenuTitle = () => [this.renderMenuTitleFiller(), this.renderLogo()]

    renderMenuButton = () => (
        <div
          className={style('menuButton', {
                light: !this.props.mobileOpen && this.props.lightTheme,
            })}
        >
            <div
              role="navigation"
              className={style('button', { closed: !this.props.mobileOpen })}
              onClick={() => {
                    this.toggleOpen()
                }}
            >
                <span />
                <span />
                <span />
            </div>
        </div>
    )

    renderLogin = () => {
        const { currentPage, lightTheme } = this.props

        // OLD LOGIN - Uncomment if needed back.
        // --------------
        // let login

        // // const temp = true // Remove this temp stuff when turning on AccountNav on dev or if you want this on dev.

        // if (flags.isDevelopment && temp) {
        //     login = (
        //         <div className={style.login2}>
        //             <AccountMenu currentPage={currentPage} />
        //         </div>
        //     )
        // } else {
        //     if (!this.props.showLogin) {
        //         return null
        //     }

        //     login = (
        //         <div className={style.login}>
        //             <div className={style.desktop}>
        //                 {/* {!flags.isDevelopment && <AccountNav />}
        //                 {flags.isDevelopment && ( */}
        //                 {/* <React.Fragment> */}
        //                 <span className={style.userName}>
        //                     {this.props.userName}
        //                 </span>
        //                 <span title="Sign out">
        //                     <Link path={{ page: 'logout' }} title="Sign out">
        //                         <Icon
        //                           name="user-times"
        //                           className={style.userIcon}
        //                         />
        //                     </Link>
        //                 </span>
        //                 {/* </React.Fragment> */}
        //                 {/* )} */}
        //             </div>
        //             <div className={style.onlyMobile}>
        //                 <Icon name="user-alt" className={style.userIcon} />
        //                 <Link path={{ page: 'logout' }}>Sign out</Link>
        //             </div>
        //         </div>
        //     )
        // }
        // -------------


        // NEW LOGIN
        const login = (
            <div className={style.login2}>
                <AccountMenu currentPage={currentPage} />
            </div>
        )

        // if (this.props.isLoggedIn) {
            return login
        // }

        // return (
        //     <div className={style.login} data-cy="login-link">
        //         <span className={style.userIcon}>
        //             <SvgIcon name={'www-user'} fill='#5CA2D5' width="20" />
        //         </span>
        //         <Link
        //           path={{
        //                 page: 'login',
        //                 query: { path: `${currentPage}` },
        //             }}
        //         >
        //             {localize[this.props.language].login}
        //         </Link>
        //         {/* <Icon name="user-alt" className={styles.userIcon} /> */}
        //     </div>
        // )
    }

    render() {
        if (!this.props.showNavigation || !this.state.render) {
            return null
        }

        const className = this.getClassName()

        return (
            <div className={className}>
                <nav id="navio" className={style.container}>
                    {this.renderMenuButton()}
                    <div
                      className={style(
                            style.inner,
                            this.props.hideBorder ? '' : style.border
                        )}
                    >
                        {this.renderMenuTitle()}
                        <NavItems className={style.desktopView} />
                        <CTA className={style(style.signup, style.notMobile)} />
                        <div className={style.navRight}>
                            {/* <Notification language={this.props.language} /> */}
                            {this.renderLogin()}
                            <SelectLanguage className={style.language} />
                        </div>
                    </div>
                </nav>
                <MobileMenu
                  open={this.props.mobileOpen}
                  closeMenu={this.closeMenu}
                />
            </div>
        )
    }
}

Navigation.propTypes = {
    showNavigation: PropTypes.bool,
    mobileOpen: PropTypes.bool,
    // mobileAccountOpen: PropTypes.bool,
    lightTheme: PropTypes.bool,
    hideBorder: PropTypes.bool,
    // showLogin: PropTypes.bool,
    // isLoggedIn: PropTypes.bool,
    // userName: PropTypes.string,
    // language: PropTypes.string,
    closeMobileNavigation: PropTypes.func,
    toggleMobileNavigation: PropTypes.func,
}

export default Navigation

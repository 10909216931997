import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { RetryLink } from 'apollo-link-retry'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { isServer } from 'config/web/configHelper'
import config from 'config/web/config'

const { contentful } = config
const { graphqlBaseUrl, environment, spaceId, accessToken } = contentful
// console.log('isServer', isServer)

const cache = isServer
    ? new InMemoryCache() // Default cache on server
    : new InMemoryCache().restore(window.APOLLO_STATE) // restore data from server on client, much faster

const retryLink = new RetryLink() // Using default config for RetryLink. Tries 5 times with random short delay.

// console.log('Creating new Apollo Client on', isServer ? 'server' : 'client')
const client = new ApolloClient({
    ssrMode: isServer,
    link: ApolloLink.from([
        retryLink,
        onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                graphQLErrors.map(({ message, locations, path }) => console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                    ))
            }
            if (networkError) console.log(`[Network error]: ${networkError}`)
        }),
        new HttpLink({
            uri: `${graphqlBaseUrl}/spaces/${spaceId}/environments/${environment}`,
            credentials: 'same-origin',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }),
    ]),
    cache,
    // cache: new InMemoryCache({ fragmentMatcher }),
})

function getApolloContentfulClient() {
    return client
}

export default getApolloContentfulClient

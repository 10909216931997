import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import { toggleAccountMenu } from 'lib/pages/actions'
import { getOmegaState, isAccountMenuOpen } from 'lib/pages/selectors'
import {
    isLoggedIn,
    getLoggedInUser,
} from 'packages/authentication/lib/selectors'
import config from 'config/web/config'
import Navigation from './navigation'

// const list = [
//     { translationId: 'navigation.account.subscription', page: 'account', sub: 'subscription', external: false },
//     { translationId: 'navigation.account.transactions', page: 'account', sub: 'transactions', external: false },
//     { translationId: 'navigation.account.edit', page: 'account', sub: 'edit', external: false },
//     { translationId: 'navigation.account.activities', page: 'account', sub: 'activity', url: '', external: false },
//     { translationId: 'navigation.account.download', page: 'download', external: false },
//     { translationId: 'navigation.account.security', url: 'https://secure.eveonline.com/', external: true },
//     { translationId: 'navigation.account.logout', page: 'logout', external: false },
// ]

const list = [
    {
        translationId: 'navigation.account.info',
        url: config.secureAccountManagementUrl,
        external: true,
    },
    {
        translationId: 'navigation.account.logout',
        page: 'logout',
        external: false,
    },
]

function mapStateToProps(state) {
    return {
        isLoggedIn: isLoggedIn(state),
        userName: getLoggedInUser(state),
        items: list,
        isOpen: isAccountMenuOpen(state),
        translate: getTranslate(state.localize),
        omegaStatus: getOmegaState(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggle: (open) => dispatch(toggleAccountMenu(open)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)

import { connect } from 'react-redux'
import Articles from './components/articles'

function mapStateToProps({ pages: { articles } }) {
    if (articles) {
        return {
            hasContent: true,
            news: articles.news,
            devblogs: articles.devblogs,
            patchNotes: articles.patchNotes,
            scope: articles.scope,
            upgrade: articles.upgrade,
        }
    }

    return {
        hasContent: false,
    }
}

export default connect(mapStateToProps)(Articles)

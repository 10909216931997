import React from 'react'
import { PropTypes } from 'prop-types'
import { Icon } from 'layouts'
import styles from './table.scss'

/* eslint-disable react/no-array-index-key */

const Table = ({ translate, sortable, sort, columns, rows, className }) => {
    // eslint-disable-next-line no-shadow
    const getProps = ({ className, shortText }) => {
        const props = {}
        const classes = []

        if (className) {
            classes.push(className)
        }

        // short text is the text that should replace the text
        // in the mobile version of the table
        if (shortText) {
            props['data-td'] = shortText
            classes.push(styles.shortText)
        }

        if (classes.length > 0) {
            props.className = classes.join(' ')
        }

        return props
    }

    const getHeader = (index, column) => {
        if (!sortable) {
            return column.name
        }

        let name = column.name
        if (column.sorted) {
            const icon = (
                <Icon name={`chevron-${column.descending ? 'down' : 'up'}`} />
            )
            name = (
                <div className={styles.sorted}>
                    {column.name}
                    <span>{icon}</span>
                </div>
            )
        }

        return (
            <a
              href="#"
              onClick={() => sort(index, column.sorted, column.descending)}
            >
                {name}
            </a>
        )
    }

    return (
        <table className={styles('table', className)}>
            <thead>
                <tr>
                    {columns.map((col, i) => <th key={col.id}>{getHeader(i, col)}</th>)}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, j) => (
                    <tr key={j}>
                        {row.map((rowCol, i) => {
                            const props = getProps(rowCol)
                            if (rowCol.node) {
                                return (
                                    <td
                                      key={`${i}${j}`}
                                      data-th={translate(columns[i].translationId)}
                                      {...props}
                                    >
                                        {rowCol.node}
                                    </td>
                                )
                            }
                            return (
                                <td
                                  key={`${i}${j}`}
                                  data-th={translate(columns[i].translationId)}
                                  {...props}
                                >
                                    <div>{rowCol.text}</div>
                                </td>
                            )
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

Table.propTypes = {
    className: PropTypes.string,
    translate: PropTypes.func,
    sortable: PropTypes.bool,
    sort: PropTypes.func,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.node,
            translationId: PropTypes.string,
            sorted: PropTypes.bool,
            descending: PropTypes.bool,
        })
    ),
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string,
                className: PropTypes.string,
                node: PropTypes.node,
            })
        )
    ),
}

export default Table

import ConfigModel from '../ConfigModel'
import AuthenticationConfigModel from '../../../packages/authentication/models'

const config = new ConfigModel()

config.env = 'preprod'
config.webBaseUrl = 'https://preprod.ccpeveweb.com'
config.apiBaseUrl =
    'https://yo2vtgum73.execute-api.eu-west-1.amazonaws.com/prod'
config.signupServiceUrl = 'https://signup.ccpeveweb.com/v1'
config.recruitmentServiceUrl = 'https://tranquility.recruit.eveonline.com'
config.gtmID = 'GTM-NWZ6XP'
config.gaID = 'UA-45583206-1'

config.authentication = new AuthenticationConfigModel()
config.authentication.authUrl = 'https://login.eveonline.com/v2/oauth/authorize'
config.authentication.tokenUrl = 'https://login.eveonline.com/v2/oauth/token'
config.authentication.logoutUrl =
    'https://login.eveonline.com/account/logoff?returnUrl='
config.authentication.redirectUrl = 'https://preprod.ccpeveweb.com/callback'
config.authentication.silentRedirectUrl =
    'https://preprod.ccpeveweb.com/silentcallback'
config.authentication.clientId = 'wwwEveOnline' // 'wwwEveOnline'
config.authentication.responseType = 'token'
config.authentication.scopes = 'recruit.signup.v1' // cisservice.userProfileBasic.v1

export default config

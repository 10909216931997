import { connect } from 'react-redux'
import { getPathname } from 'lib/location/selectors'
import DynamicPage from './DynamicPage'

function mapStateToProps(state) {
    return {
        location: getPathname(state),
    }
}

export default connect(
    mapStateToProps,
    null
)(DynamicPage)


import React from 'react'
import PropTypes from 'prop-types'
import { En, Fr, De, Ru, Ja } from './components'

const TOS = ({ language }) => {
    const renderContent = () => {
        switch (language) {
            case 'en':
                return <En />
            case 'fr':
                return <Fr />
            case 'de':
                return <De />
            case 'ru':
                return <Ru />
            case 'ja':
                return <Ja />

            default:
                return <En />
        }
    }

    return renderContent(language)
}

TOS.propTypes = {
    language: PropTypes.string,
}

export default TOS

import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import PageWithSeo from 'features/page-with-seo'
import Loading from 'layouts/loading/PageLoading'
import { isClient } from 'config/web/configHelper'
// import mockData from './vegasMock.json'

let LoadableComponent

if (isClient) {
    LoadableComponent = React.lazy(() => import(/* webpackChunkName: "page-vegas" */ './Vegas'))
}

export default function LoadableVegas(props) {
    const page = useSelector(state => state.pages.vegas)

    // temp image replace because vegas components use contentful image api
    const ctfImage =
        page &&
        page.metaImage &&
        page.metaImage.replace(
            '//webimg.ccpgamescdn.com/',
            '//images.ctfassets.net/'
        )

    return (
        <PageWithSeo page={page} showLoading={!isClient} hideSitename>
            {isClient && (
                <React.Suspense fallback={<Loading />}>
                    <LoadableComponent {...props} />
                </React.Suspense>
            )}
        </PageWithSeo>
    )
}

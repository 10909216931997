import AuthenticationConfigModel from '../../packages/authentication/models'

class ConfigModel {
    webBaseUrl: string
    apiBaseUrl: string
    signupServiceUrl: string
    recruitmentServiceUrl: string
    monumentSearchApiUrl: string
    sentryDsn: string
    launcherVersionsUrl: string
    launcherVersionDownloadUrl: string
    shareFacebook: string
    shareTwitter: string
    shareGoogle: string
    shareReddit: string
    gtmID: string
    adGlare: {
        small: string,
        medium: string,
        large: string,
    }
    contentful: {
        spaceId: string,
        accessToken: string,
        preview: boolean,
    }
    authentication: AuthenticationConfigModel
}

export default ConfigModel

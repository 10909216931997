import React from 'react'
import { useSelector } from 'react-redux'
import PageWithSeo from 'features/page-with-seo'
import Loading from 'layouts/loading/PageLoading'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import { findSectionByIdentifier } from 'lib/pages/api'
import { isClient } from 'config/web/configHelper'

let LoadableComponent

if (isClient) {
    LoadableComponent = React.lazy(() => import(/* webpackChunkName: "page-return" */ './components/return'))
}

export default function LoadableReturn() {
    const slug = 'return'
    const language = useSelector(state => state.language)

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {page => {
                let hasContent = false
                if (page) {
                    hasContent = true
                    const sections = page.sectionsCollection.items

                    const Hero = findSectionByIdentifier(sections, 'return-hero')
                    const VideoFeaturedSection = findSectionByIdentifier(sections, 'return-eclipse-trailer')
                    const VideoSection = findSectionByIdentifier(sections, 'return-video-section')
                    const CTASection = findSectionByIdentifier(sections, 'return-cta')
                    const VisualsSection = findSectionByIdentifier(sections, 'visuals')
                    const FeaturesSection = findSectionByIdentifier(sections, 'return-features')
                    const EventsSection = findSectionByIdentifier(sections, 'return-events')
                    const BottomCTASection = findSectionByIdentifier(sections, 'return-bottom-cta')

                    return (
                        <PageWithSeo page={page}>
                            {isClient && (
                                <React.Suspense fallback={<Loading />}>
                                    <LoadableComponent
                                      Hero={Hero}
                                      VideoFeaturedSection={VideoFeaturedSection}
                                      VideoSection={VideoSection}
                                      CTASection={CTASection}
                                      VisualsSection={VisualsSection}
                                      FeaturesSection={FeaturesSection}
                                      EventsSection={EventsSection}
                                      BottomCTASection={BottomCTASection}
                                      hasContent={hasContent}
                                    />
                                </React.Suspense>
                            )}
                        </PageWithSeo>
                    )
                }

                // Fallback error message
                return <h1>Not Found</h1>
            }}
        </PageBySlugQuery>
    )
}

// export default function LoadableReturn(props) {
//     const page = useSelector(state => state.pages.returning)

//     return (
//         <PageWithSeo page={page} showLoading={!isClient} hideSitename>
//             {isClient && (
//                 <React.Suspense fallback={<Loading />}>
//                     <LoadableComponent {...props} />
//                 </React.Suspense>
//             )}
//         </PageWithSeo>
//     )
// }

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Platform from 'platform'
import localize from 'shared/localize'
import { isClient } from 'config/web/configHelper'
import { pushClientDownloadEventToDataLayer } from 'utils/analytics/datalayer/dataLayerFunctions'
import paths from 'config/paths'
import { Icon, Section } from 'layouts'
import { fetchVersionIfNeeded } from '../actions'
import style from './DownloadButton.scss'

class DownloadButton extends Component {
    componentDidMount = () => {
        this.props.dispatch(fetchVersionIfNeeded)
    }

    dataLayerPush = (platform, filename) => {
        if (isClient) {
            let eventAction
            let eventLabel

            if (platform === 'windows') {
                eventAction = 'Windows'
                eventLabel = 'EVE client Windows download'
            } else {
                eventAction = 'Mac OS'
                eventLabel = 'EVE client Mac download'
            }

            // Append filename to event label
            eventLabel = `${eventLabel} - ${filename}`

            pushClientDownloadEventToDataLayer(eventAction, eventLabel)
        }
    }

    renderButtons = () => {
        const { versions, small, transparent = false, hideSecondaryPlatform = false } = this.props
        if (!this.props.hasContent) {
            return ''
        }

        const family = Platform.os.family
        // const family = 'Windows Server 2008 R2 / 7' // Windows 7

        const isWin = family.includes('Windows')
        // const isWin = family === 'Windows' // Now working on Windows 7 and possibly other Windows

        // console.log('family', family)
        // console.log('isWin', isWin)

        const primaryVersionAndExtension = isWin
            ? `${versions.win}.exe`
            : `${versions.mac}.dmg`
        const secondaryVersionAndExtension = isWin
            ? `${versions.mac}.dmg`
            : `${versions.win}.exe`

        const primaryLink =
            paths.launcherVersionDownloadUrl + primaryVersionAndExtension
        const secondaryLink =
            paths.launcherVersionDownloadUrl + secondaryVersionAndExtension

        const primaryPlatformIcon = isWin ? 'windows' : 'apple'
        const secondaryPlatformIcon = isWin ? 'apple' : 'windows'

        const primaryPlatformText = isWin
            ? localize[this.props.language].forWindows
            : localize[this.props.language].forMac

        const secondaryPlatformText = isWin
            ? localize[this.props.language].downloadMacClient
            : localize[this.props.language].downloadWindowsClient

        return (
            <Fragment>
                <div className={style.btnWrapper}>
                    <a
                      className={style(style.primaryBtn, {
                            [style.transparent]: transparent,
                        })}
                      href={primaryLink}
                      alt='Download'
                      onClick={() => this.dataLayerPush(primaryPlatformIcon, primaryVersionAndExtension)}>
                        <div className={style.dArrow}>
                            <Icon name='download' />
                        </div>
                        {localize[this.props.language].download}
                        <span className={style.platform}>
                            <Icon brand name={primaryPlatformIcon} />
                            {primaryPlatformText}
                        </span>
                    </a>
                    {!hideSecondaryPlatform && (
                        <a className={style.secondary} href={secondaryLink} onClick={() => this.dataLayerPush(secondaryPlatformIcon, secondaryVersionAndExtension)}>
                            <Icon brand name={secondaryPlatformIcon} />
                            {secondaryPlatformText}
                        </a>
                    )}
                </div>
            </Fragment>
        )
    }

    render() {
        const { hasContent } = this.props
        return (
            <Section hasContent={hasContent} loadingTypeSmall>
                {this.renderButtons()}
            </Section>
        )
    }
}

DownloadButton.propTypes = {
    dispatch: PropTypes.func.isRequired,
    hasContent: PropTypes.bool,
    small: PropTypes.bool,
    versions: PropTypes.shape({
        win: PropTypes.number,
        mac: PropTypes.number
    }),
    language: PropTypes.string
}

export default DownloadButton

import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { pushClickEventToDataLayer } from 'utils/analytics/datalayer/dataLayerFunctions'
import LazyLoad from 'react-lazyload'
import { Frame, Button } from 'layouts'
import Link from '../../../shared/link'
import { ThemeContext, LocationContext } from '../../../../context'
import { lightOrDark, getImage, slugify } from '../../../../helpers'
import style from './Card.scss'

const Card = ({ card }) => (
    <ThemeContext.Consumer>
        {colorTheme => (
            <LocationContext.Consumer>
                {location => (
                    <div
                      id={slugify(card.name)}
                      style={{ '--dlpBG': colorTheme.cardTemplate }}
                      className={style.item}
                    >
                        <Frame
                          filled
                          small
                          frameClass={style.frame}
                          className={style.frame__inner}
                          key={card.name}
                        >
                            {card.imageFile && (
                                <LazyLoad height={478} offset={350} once>
                                    <img
                                      src={`${getImage(
                                            card.imageFile
                                        )}?w=630`}
                                      alt=""
                                      className={style.img}
                                    />
                                </LazyLoad>
                            )}
                            <div className={style.content}>
                                <ReactMarkdown
                                  source={card.body}
                                  renderers={{
                                        heading: ({ children, level }) => createElement(
                                                `h${level}`,
                                                {
                                                    style: {
                                                        color:
                                                            colorTheme.headline,
                                                    },
                                                },
                                                children
                                            ),
                                        paragraph: ({ children }) => (
                                            <p
                                              style={{
                                                    color: lightOrDark(
                                                        colorTheme.cardTemplate
                                                    ),
                                                }}
                                            >
                                                {children}
                                            </p>
                                        ),
                                        link: ({ children, href }) => (
                                            <Link
                                              href={href}
                                              onClick={() => pushClickEventToDataLayer('Button click', `page: ${location}, url: ${href}`) }
                                              className={style.link}
                                              color={colorTheme.link}
                                            >
                                                {children}
                                            </Link>
                                        ),
                                    }}
                                  escapeHtml={false}
                                />
                                {card.buttonText != null && (
                                    <>
                                        <Button
                                          className={style.btn}
                                          target={'_blank'}
                                          path={card.buttonUrl}
                                          onClick={() => pushClickEventToDataLayer('Button click', `page: ${location}, url: ${card.buttonUrl}`) }
                                          size="small"
                                          custom={{
                                                color: colorTheme.button.color,
                                                background:
                                                    colorTheme.button
                                                        .background,
                                            }}
                                        >
                                            {card.buttonText}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Frame>
                    </div>
                )}
            </LocationContext.Consumer>
        )}
    </ThemeContext.Consumer>
)

Card.propTypes = {
    card: PropTypes.shape({
        name: PropTypes.string,
        body: PropTypes.string,
        buttonText: PropTypes.string,
        buttonTheme: PropTypes.string,
        buttonUrl: PropTypes.string,
        imageFile: PropTypes.shape({
            url: PropTypes.string,
        }),
    }),
}

export default Card

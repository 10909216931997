import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getQuery } from 'lib/location/selectors'
import ReactMarkdown from 'react-markdown'
import _isEmpty from 'lodash/isEmpty'
import classnames from 'classnames'
import { Container, Frame } from 'layouts'
import EmailSignup from 'features/email-signup'
import CTA from 'features/jumbotron/components/cta'
import { FeatureVideo } from 'features'
import { HeadingSmall, ParagraphRegular } from 'layouts/typography'
import Signup from '../signup'
import { ThemeContext } from '../../context'
import { getImage, lightOrDark } from '../../helpers'
import style from './Hero.scss'

function Hero({ section, align }) {
    return (
        <Container>
            <ThemeContext.Consumer>
                {(colorTheme) => (
                    <>
                        <div className={classnames(style.hero, style[align], { [style.form]: colorTheme.signupForm })}>
                            {!_isEmpty(section.imageFile) && (
                                <div className={style.logo}>
                                    <img src={getImage(section.imageFile)} alt='' />
                                </div>
                            )}
                            <div>
                                <ReactMarkdown className={style.content} source={section.body} escapeHtml={false} />
                                <CTA
                                  custom={{
                                    color: colorTheme.CTA.color,
                                    background: colorTheme.CTA.background,
                                  }}
                                  section={section.contentCollection.items}
                                  data-id='dynamic-hero-cta-button'
                                  alignLeft={align !== 'center'}
                                  className={style.cta}
                                />
                                {section.videoId && section.buttonText && (
                                    <div className={style.play} style={{ '--play-color': colorTheme.link }}>
                                        <FeatureVideo isButton videoId={section.videoId} title={section.buttonText} />
                                    </div>
                                )}
                            </div>
                            {colorTheme.emailSignup === true && (
                                <EmailSignup  config={colorTheme} />
                            )}
                            {colorTheme.signupForm && (
                                <div
                                  className={style.signup}
                                  style={{
                                    '--form-bg': colorTheme.signupFormTheme.background,
                                    '--form-btn': colorTheme.signupFormTheme.color,
                                    '--sf-terms-button-color': colorTheme.signupFormTheme.color,
                                    
                                  }}
                                >
                                    <Frame className={style.signup_content}>
                                        {section.headline && (
                                            <HeadingSmall
                                              as="h3"
                                              className={style.signup_title}
                                              id='signup_title'
                                              style={{ color: colorTheme.signupFormTheme.theme === 'light' ? '#212121' : '#fff' }}
                                            >
                                                {section.headline}
                                            </HeadingSmall>
                                        )}
                                        {section.teaser && (
                                            <ParagraphRegular
                                              className={style.signup_text}
                                              style={{ color: colorTheme.signupFormTheme.text }}
                                            >
                                                {section.teaser}
                                            </ParagraphRegular>
                                        )}
                                        <Signup />
                                    </Frame>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </ThemeContext.Consumer>
        </Container>
    )
}

Hero.propTypes = {
    section: PropTypes.shape({
        headline: PropTypes.string,
        teaser: PropTypes.string,
        body: PropTypes.string,
        content: PropTypes.arrayOf(PropTypes.object),
        imageFile: PropTypes.string,
        videoId: PropTypes.string
    }),
    align: PropTypes.oneOf(['left', 'center', 'right']),
}

Hero.defaultProps = {
    align: 'left',
}

export default Hero

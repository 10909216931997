import React from 'react'
import { PropTypes } from 'prop-types'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import config from 'config/web/config'
import { isServer } from 'config/web/configHelper'
import Logger from 'utils/logging'
import { Loading, Section } from '../layouts'
// import RoadmapLoading from '../components/roadmap/components/roadmap-shared/components/loading'

const ARTICLES_SEARCH_QUERY = gql`
    query ArticleCollectionSearch($locale: String, $skip: Int=0, $limit: Int=20, $query: String, $preview: Boolean) {
        articleCollection(
            skip: $skip
            limit: $limit
            locale: $locale
            preview: $preview
            where: { OR: [
              { title_contains: $query },
              { content_contains: $query }
            ]}
            order: [publishingDate_DESC]
        ) {
            skip
            total
            items {
                sys {
                    publishedAt
                    firstPublishedAt
                }
                title
                slug
                category
                author
                publishingDate
                video
                metaDescription
                metaImageUrl {
                    url
                }
            }
        }
    }
`

function ArticlesSearch({ locale, skip, limit, query, children }) {
    const preview = config.contentful.preview
    let fetchPolicy = isServer ? 'network-only' : 'cache-first'
    if (preview) fetchPolicy = 'no-cache' // Disable cache in preview mode.
    return (
        <Query
          query={ARTICLES_SEARCH_QUERY}
          fetchPolicy={fetchPolicy}
          variables={{ locale, skip, limit, query, preview }}
        >
            {({ loading, error, data }) => {
                if (loading) 
                    return <Section hasContent={false} spinnerSize={20} />
                if (error) {
                    Logger.captureException(error)
                    return ''
                }
                return children(data.articleCollection)
            }}
        </Query>
    )
}

ArticlesSearch.propTypes = {
    // slug: PropTypes.string,
    limit: PropTypes.number,
    query: PropTypes.string,
    locale: PropTypes.oneOf(['en', 'de', 'fr', 'ru', 'ja']),
    children: PropTypes.any //eslint-disable-line
}

export default ArticlesSearch

import { connect } from 'react-redux'
import { getLanguage } from 'selectors'
import Tos from './Tos'

function mapStateToProps(state) {
    return {
        language: getLanguage(state),
    }
}

export default connect(mapStateToProps)(Tos)

import React from 'react'
import NewsType from 'models/types/ts/newsType'
import _map from 'lodash/map'
import { HeadingXSmall } from 'layouts/typography'
import { DateAndAuthor } from 'features'
import Tags from '../tags'
import NewsLink from '../../shared/link'
import style from './Card.module.scss'

interface Props {
    newsItem: NewsType,
    filled?: boolean,
    size?: string
}

const Card = ({ newsItem, filled = false, size = 'medium' }: Props) => {

    return (
        <article className={style.card}>
            <Tags tagList={newsItem.tags} />
            {newsItem.metaImageUrl && newsItem.metaImageUrl.url && (
                <NewsLink slug={newsItem.slug}>
                    <div className={style.card__imgWrapper}>
                        <img
                          className={style.card__img}
                          src={newsItem.metaImageUrl.url}
                          alt={newsItem.metaDescription}
                        />
                    </div>
                </NewsLink>
            )}
            <div className={style(style.card__content, { [style.filled]: filled })}>
                <DateAndAuthor
                  date={newsItem.publishingDate}
                  author={newsItem.author}
                />
                <HeadingXSmall textTransform="unset" className={style.card__title}>
                    <NewsLink slug={newsItem.slug}>
                        {newsItem.title}
                    </NewsLink>
                </HeadingXSmall>
            </div>
        </article>
    )
}

export default Card

import React from 'react'
import SectionType from 'models/types/ts/sectionType'
import { Container, Section, SvgIcon, Icon } from 'layouts'
import { Link } from 'features'
import SocialIcon from 'features/social-icon/components'
import paths from 'config/paths'
import { HeadingMedium, TaglineLarge, ParagraphRegular } from 'layouts/typography'
import style from './Hero.module.scss'

interface Props {
    section: SectionType,
    id: string,
    videoUrl: string
}

// const facebookShareLink = = paths.shareFacebook + this.getShareLink()

const getShareLink = (id: string, platform: string) => {
    if (platform==="facebook") {
        return `${paths.shareFacebook}${paths.webBaseUrl}/my-year-in-eve?id=${id}`
    }
    else if (platform==="twitter") {
        return `${paths.shareTwitter}${paths.webBaseUrl}/my-year-in-eve?id=${id}&hashtags=MyEVE2019,tweetfleet,eveonline`
    }
    else if (platform==="vk") {
        return `${paths.shareVk}${paths.webBaseUrl}/my-year-in-eve?id=${id}`
    }
}

const Hero = ({ section, id, videoUrl }: Props) => (
    <Container>
        <Section hasContent={section !== undefined}>
            <div className={style.content}>
                <a href="/">
                    <SvgIcon
                      width={230}
                      name="eve-logo-with-text"
                      fill={'#fff'}
                      className={style.logo}
                    />
                </a>
                <HeadingMedium className={style.heading}>{section.headline}</HeadingMedium>
                <TaglineLarge className={style.tagline}>{section.teaser}</TaglineLarge>
                <div className={style.videoContainer}>
                    <div id="video" className={style.video}>
                        <h1>Video no longer available</h1>
                    </div>

                    {/* <div className={style.bottom}>
                        <div className={style.left}>
                            <ParagraphRegular className={style.text}>
                                <a href={videoUrl} data-id="myyear_download" target="_blank" rel="noopener noreferrer">{section.videoId}</a>
                            </ParagraphRegular>
                            <ParagraphRegular className={style.mobile}>
                                <a href={videoUrl} data-id="myyear_download" target="_blank" rel="noopener noreferrer">Download video <Icon name="download" /></a>
                            </ParagraphRegular>
                        </div>
                        <div className={style.right}>
                            <div className={style.social}>
                                <ParagraphRegular className={style.share}>
                                    {section.body}
                                </ParagraphRegular>
                                <div className={style.icon}>
                                    <SocialIcon
                                      id="vk"
                                      title="VK"
                                      href={getShareLink(id, "vk")}
                                      dataId="myyear_vk_share"
                                      name="vk"
                                      showBrandColor={false}
                                      className={style.icon}
                                    />
                                </div>
                                <div className={style.icon}>
                                    <SocialIcon
                                      id="twitter"
                                      title="Twitter"
                                      href={getShareLink(id, "twitter")}
                                      dataId="myyear_twitter_share"
                                      name="twitter"
                                      showBrandColor={false}
                                      className={style.icon}
                                    />
                                </div>
                                <div className={style.icon}>
                                    <SocialIcon
                                      id="facebook"
                                      title="Facebook"
                                      href={getShareLink(id, "facebook")}
                                      dataId="myyear_facebook_share"
                                      name="facebook-f"
                                      showBrandColor={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                {section.buttonUrl && 
                    <div className={style.details}>
                        <a href={section.buttonUrl} data-id="myyear_details">{section.buttonText}</a>
                    </div>
                }
            </div>
        </Section>
    </Container>
)

export default Hero
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { flags } from 'config'
import localize from 'features/navigation/components/localize'
import { Link } from 'features'
import { login } from 'packages/authentication/lib/actions'
import { Icon, SvgIcon } from 'layouts'
import config from 'config/web/config'
import {
    isLoggedIn,
    getLoggedInUser
} from 'packages/authentication/lib/selectors'
import { getLanguage } from 'selectors'
import style from './AccountManagementMenu.scss'

const items = [
    {
        translationId: 'navigation.account.info',
        url: config.secureAccountManagementUrl,
        external: true,
    },
    {
        translationId: 'navigation.account.logout',
        url: 'logout',
        external: false,
    },
]

export const AccountManagementMenu = ({ className, currentPage }) => {

    const loggedIn = useSelector((state) => isLoggedIn(state))
    const username = useSelector((state) => getLoggedInUser(state))
    const language = useSelector((state) => getLanguage(state))
    const dispatch = useDispatch()

    // Show log in
    if(!loggedIn) {
        return (
            <div className={style(style.accountmenu, { [style.colorTheme]: flags.features.colorThemeEnabled }, className)}>
                <a onClick={() => dispatch(login(currentPage, config.authentication))} role="button" tabIndex="0">
                    <div className={style(style.selected, style.marginOffset)}>
                        <SvgIcon name={'www-user'} fill='#5CA2D5' width={20} className={style.userIcon}/>
                        <span className={style.loginText}>
                            {localize[language].login}
                        </span>
                    </div>
                </a>
            </div>
        )
    }

    // Show account menu if is logged in
    return (
        <Translate>
            {({ translate }) => (
                <div className={style(style.accountmenu, { [style.colorTheme]: flags.features.colorThemeEnabled }, className)}>
                    <div className={style.selected}>
                        <SvgIcon name={'www-user'} className={style.userIcon} width={20} />
                        <div className={style.userName}>
                            {username}
                        </div>
                        <span className={style.arrow}>
                            <Icon name="chevron-down" className={style.icon} />
                        </span>
                    </div>
                    <ul>
                        <li>
                            <a href={items[0].url} target="_blank" rel="noopener noreferrer">
                                <span>{translate(items[0].translationId)}</span>
                                <span className={style.icon}>
                                    <Icon
                                      name='external-link'
                                    />
                                </span>
                            </a>
                        </li>
                        <li>
                            <Link path={{ page: items[1].url }}>{translate(items[1].translationId)}</Link>
                        </li>
                    </ul>
                </div>
            )}
        </Translate>
    )
}

export default AccountManagementMenu

export const colors = {
    primary: '#30B2e6',
    omega: '#faB400',
    recruit: '#913019',
    community: '#F67C0F',
    dark: '#212121', // $www-night-black
    red: '#D81F27', // $www-blood-red
}

export const sizes = {
    xs: '480px',
    sm: '769px',
    md: '992px',
    lg: '1200px',
}

export const typography = {
    headingXLarge: {
        as: 'h1',
        fontSize: [72, 144],
        fontWeight: 500,
        lineHeight: 1.2,
        textTransform: 'uppercase',
    },
    headingLarge: {
        as: 'h1',
        fontSize: [48, 96],
        fontWeight: 500,
        lineHeight: 1.05,
        textTransform: 'uppercase',
    },
    headingMedium: {
        as: 'h1',
        fontSize: [36, 72],
        fontWeight: 500,
        lineHeight: 1.05,
        textTransform: 'uppercase',
    },
    headingRegular: {
        as: 'h2',
        fontSize: [36, 48],
        fontWeight: 500,
        lineHeight: 1.06,
        textTransform: 'uppercase',
    },
    headingSmall: {
        as: 'h2',
        fontSize: [26, 36],
        fontWeight: 500,
        lineHeight: 1.08,
        textTransform: 'uppercase',
    },
    headingXSmall: {
        as: 'h3',
        fontSize: [22, 26],
        fontWeight: 500,
        lineHeight: 1.3,
        textTransform: 'uppercase',
    },
    taglineLarge: {
        as: 'h2',
        fontSize: [26, 36],
        fontWeight: 300,
        lineHeight: 1.2,
        textTransform: 'uppercase',
    },
    taglineRegular: {
        as: 'h3',
        fontSize: [20, 26],
        fontWeight: 300,
        lineHeight: 1.2,
        textTransform: 'uppercase',
    },
    taglineSmall: {
        as: 'h3',
        fontSize: [16, 18],
        fontWeight: 500,
        lineHeight: 1.1,
        textTransform: 'uppercase',
    },
    paragraphLarge: {
        as: 'p',
        fontSize: [20, 22],
        fontWeight: 400,
        lineHeight: 1.55,
        textTransform: 'none',
    },
    paragraphRegular: {
        as: 'p',
        fontSize: [16, 18],
        fontWeight: 400,
        lineHeight: 1.65,
        textTransform: 'none',
    },
}

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import { Column, Row } from 'layouts/common'
import { Border } from 'layouts'
import { FixedImage } from 'features'
import style from '../../LiveEvent.scss'

const Story = ({ name, eventEnds, image, body }) => {
    function daysRemaining() {
        // const eventEndsCustom = new Date('2018-02-14T11:59:55.527Z')
        const eventdate = moment(eventEnds)
        const todaysdate = moment()
        // console.log('cust date', eventdate)
        const daysUntilEnds = eventdate.diff(todaysdate, 'days')
        // console.log('diff', daysUntilEnds)
        // console.log('diff hours', eventdate.diff(todaysdate, 'hours'))

        // check if event the event is finished
        if (todaysdate > eventdate) {
            return ''
        }

        if (todaysdate.isSame(eventdate, 'day')) {
            return 'Event ends: Today'
        } else if (eventdate.diff(todaysdate, 'hours') < 24) {
            return 'Event ends: Tomorrow'
        } else if (daysUntilEnds > 0) {
            return `Event ends in: ${eventdate.diff(todaysdate, 'days')} Days`
        }
        return ''
    }

    function isEventActive() {
        const eventdate = moment(new Date(eventEnds))
        const currDate = moment()

        if (eventdate) {
            if (eventdate > currDate) {
                return true
            }
        } else {
            return false
        }
    }
    return (
        <Fragment>
            <Row className={style.story}>
                <Column xs={12} md={6}>
                    <h3 className={style.heading}>{name}</h3>
                </Column>
                <Column xs={12} md={6} className={style.event_ends}>
                    {isEventActive() && <p id="endsWrapper">{daysRemaining()}</p>}
                </Column>
                <Column xs={12}>
                    <Border transparent className={style.border} />
                </Column>
            </Row>
            <Row className={style.story_wrapper}>
                <Column xs={12} className={style.story_info}>
                    <div className={style.inner}>
                        <Row>
                            <Column md={6} sm={12} xs={12}>
                                <div className={style.img_wrapper}>
                                    <FixedImage url={image} size="small" />
                                </div>
                            </Column>

                            <Column md={6} sm={12} xs={12} className={style.text_wrapper}>
                                <ReactMarkdown source={body} escapeHtml={false} />
                            </Column>
                        </Row>
                    </div>
                </Column>
            </Row>
        </Fragment>
    )
}

Story.propTypes = {
    name: PropTypes.string,
    eventEnds: PropTypes.instanceOf(Date),
    image: PropTypes.string,
    body: PropTypes.string,
}

export default Story

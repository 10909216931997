import React from 'react'
import PropTypes from 'prop-types'
import _map from 'lodash/map'
import Card from './components/Card'
import style from './ImageList.scss'

const ImageList = ({ section }) => (
    <div className={style.imageList}>
        {_map(section.contentCollection.items, (item, i) => (
            <Card key={i} card={item} id={i} theme={section.theme} />
        ))}
    </div>
)

ImageList.propTypes = {
    section: PropTypes.shape({
        content: PropTypes.arrayOf(PropTypes.object),
    }),
}

export default ImageList

import axios from 'axios'
import { setItem, getItem } from 'utils/storage'

const COUNTRY_COOKIE = 'country'

// COOKIES
const setCustomCookie = (key: string, value: string) => {
    const expires = new Date()
    expires.setDate(expires.getDate() + 7) // One week
    setItem(key, value)
}
const getCustomCookie = (key: string) => getItem(key)

export const REQUEST_COUNTRY = 'REQUEST_COUNTRY'
function requestCountry() {
    return {
        type: REQUEST_COUNTRY,
    }
}

export const RECEIVE_COUNTRY = 'RECEIVE_COUNTRY'
function receiveCountry(country) {
    // console.log('Receive countryCode: ', country)
    return {
        type: RECEIVE_COUNTRY,
        country,
        receivedAt: Date.now(),
    }
}

const url =
    'https://a9737f1ffece311e9a3b00a901d89b28-1345874188.eu-west-1.elb.amazonaws.com/api/v1/country/'

function fetchCountry() {
    return dispatch => {
        dispatch(requestCountry())

        const countryFromCookie = getCustomCookie(COUNTRY_COOKIE)
        // console.log('countryFromCookie: ', countryFromCookie)
        if (countryFromCookie)
            // Nothing more needed, we have country in cookie
            return dispatch(receiveCountry(countryFromCookie))

        return axios(url).then(response => {
            const countryFromService = response.data.country
            // console.log('countryFromService: ', countryFromService)
            setCustomCookie(COUNTRY_COOKIE, countryFromService)
            dispatch(receiveCountry(countryFromService))
        })
    }
}

function shouldFetchCountry(state) {
    const country = state.country
    if (!country) {
        return true
    } else {
        return false
    }
}

export function fetchCountryIfNeeded() {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.

    return (dispatch, getState) => {
        if (shouldFetchCountry(getState())) {
            // Dispatch a thunk from thunk!
            return dispatch(fetchCountry())
        } else {
            // Let the calling code know there's nothing to wait for.
            return Promise.resolve()
        }
    }
}

import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Icon from 'layouts/font-awesome'
import styles from './styles.scss'

const SocialIcon = memo(
    ({ id, name, title, href, dataId = '', showBrandColor = false, isLink = true }) => {
        let className = `${styles.socialIcon} ${styles[id]}`
        if (showBrandColor) {
            className = `${styles.socialIcon} ${styles.incolor} ${styles[id]}`
        }

        if (!isLink) {
            return <Icon brand name={name} className={styles.icon} />
        }

        return (
            <div className={className}>
                <a
                  href={href}
                  title={title}
                  data-id={dataId}
                  target="_blank"
                  aria-label={title}
                  rel="noopener noreferrer"
                >
                    <Icon brand name={name} className={styles.icon} />
                </a>
            </div>
        )
    }
)

SocialIcon.propTypes = {
    id: PropTypes.string,
    dataId: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    href: PropTypes.string,
    showBrandColor: PropTypes.bool,
}

export default SocialIcon

import {
    isProduction,
    isStaging,
    isPreProduction,
    isDevelopment,
} from './web/configHelper'

// Define flags with default values
const flags = {
    isDevelopment: !(isProduction || isStaging || isPreProduction),
    isStaging,
    pages: {
        articlesEnabled: true,
        commmunityEnabled: false,
        recruitmentEnabled: true,
    },
    features: {
        savedCardsEnabled: false,
        accountManagementEnabled: false,
        colorThemeEnabled: true,
        mailcheck: true
    },
}

export default flags

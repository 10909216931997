import React from 'react'
import { Translate } from 'react-localize-redux'
import PageWithSeo from 'features/page-with-seo'
import Loading from 'layouts/loading/PageLoading'
import { isClient } from 'config/web/configHelper'

let LoadableComponent

if (isClient) {
    LoadableComponent = React.lazy(() => import(/* webpackChunkName: "page-signup-recruit" */ './Signup'))
}

function renderPageWithSeo(translate, props) {
    const image =
        'https://web.ccpgamescdn.com/aws/eveonline/images/eve-recruit.jpg'

    const page = {
        metaTitle: translate('signup.metaTitle'),
        metaDescription: translate('signup.meta'),
        metaImage: image,
        robots: 'noindex'
    }

    return (
        <PageWithSeo page={page} showLoading={!isClient} hideSitename>
            {isClient && (
                <React.Suspense fallback={<Loading />}>
                    <LoadableComponent {...props} />
                </React.Suspense>
            )}
        </PageWithSeo>
    )
}

export default function LoadableSignup(props) {
    return (
        <Translate>
            {({ translate }) => renderPageWithSeo(translate, props)}
        </Translate>
    )
}

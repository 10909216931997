import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { NextArrow, PrevArrow } from 'layouts'
import defaultSettings from './sliderSettings'
import style from './Carousel.scss'

class Carousel extends PureComponent {
    render() {
        const { children, config, sliderRef, className, ...rest } = this.props

        const settings = {
            ...defaultSettings,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            ...config,
        }

        return (
            <Slider
              className={style(style.slider, className)}
              {...settings}
              {...rest}
              ref={sliderRef}
            >
                {children}
            </Slider>
        )
    }
}

Carousel.propTypes = {
    children: PropTypes.node,
    config: PropTypes.object, // eslint-disable-line
    sliderRef: PropTypes.func,
    className: PropTypes.string,
}

export default Carousel

import _get from 'lodash/get'
import { environment } from './configHelper'
import ConfigModel from './ConfigModel'
import defaultConfig from './default/config'
import developmentConfig from './development/config'
import stagingConfig from './staging/config'
import bacchusConfig from './bacchus/config'
import singularityConfig from './singularity/config'
import preprodConfig from './preprod/config'
import previewConfig from './preview/config'
import productionConfig from './production/config'

export const getConfiguration = env => {

    // Decide what default config to use.
    let defaultEnvironmentConfig

    switch (env) {
        case 'local':
            defaultEnvironmentConfig = developmentConfig
            break
        case 'staging':
            defaultEnvironmentConfig = stagingConfig
            break
        case 'bacchus':
            defaultEnvironmentConfig = bacchusConfig
            break
        case 'singularity':
            defaultEnvironmentConfig = singularityConfig
            break
        case 'preview':
            defaultEnvironmentConfig = previewConfig
            break
        case 'preprod':
            defaultEnvironmentConfig = preprodConfig
            break
        case 'production':
            defaultEnvironmentConfig = productionConfig
            break
        default:
            // Default to development config
            defaultEnvironmentConfig = developmentConfig
            break
    }

    // Remove all undefined properties from defaultEnvironmentConfg
    // This is to make sure that all undefined properties are instead
    // inherited from the default config
    Object.keys(defaultEnvironmentConfig).forEach(
        key => defaultEnvironmentConfig[key] === undefined &&
            delete defaultEnvironmentConfig[key]
    )

    // Overwrite values from environment variables if they have been set.
    let config = new ConfigModel()
    config = {
        ...defaultConfig, // Lowest priority.
        ...defaultEnvironmentConfig, // Higher priority.
        authentication: {
            ...defaultConfig.authentication,
            ...defaultEnvironmentConfig.authentication,
        },
    }
    // console.log('returning config:', config)
    return config
}

const config = getConfiguration(environment)

const scopeString = _get(config, 'authentication.scopes', '')
export const scopes = scopeString !== '' ? scopeString.split(' ') : []

export default config

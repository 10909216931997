import frontpageTranslations from './pages/www/translations/frontpage.json'
import recruitTranslations from './pages/recruit/translations/recruit.json'
import roadmapTranslations from './pages/roadmap/translations/roadmap.json'
import worldTourTranslations from './pages/world-tour/translations/worldTour.json'
import accountManagementTranslations from './pages/account/translations/account.json'
import signupTranslations from './pages/signup2/translations/signup.json'
import commonTranslations from './layouts/app/translations.json'
import navigationTranslations from './features/navigation/translations'

export default {
    ...frontpageTranslations,
    ...recruitTranslations,
    ...roadmapTranslations,
    ...worldTourTranslations,
    ...accountManagementTranslations,
    ...signupTranslations,
    ...commonTranslations,
    ...navigationTranslations,
}

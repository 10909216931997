import ConfigModel from '../ConfigModel'
import AuthenticationConfigModel from '../../../packages/authentication/models'

const config = new ConfigModel()

// const host = 'https://localhost:3000'

config.env = 'singularity'
config.webBaseUrl = 'https://singularity.ccpeveweb.com'
config.apiBaseUrl =
    'https://yo2vtgum73.execute-api.eu-west-1.amazonaws.com/prod'
config.signupServiceUrl = 'https://signup.ccpeveweb.com/staging' // 'https://localhost:44337' //
config.recruitmentServiceUrl = 'https://singularity.recruit.testeveonline.com'
config.storefrontServiceUrl =
    'https://storefront-dev.batcave.ccp.cc/swagger/storefront.swagger.json' // ! Staging should not use dev at some point config.storefrontServiceUrl = 'https://storefront.batcave.ccp.cc/swagger/storefront.swagger.json'
config.secureUrl = 'https://secure.mammonmain.dev'
config.secureAccountManagementUrl = `${config.secureUrl}/accountManMenu.aspx`
config.storePayPalEnvironment = 'sandbox'
config.authentication = new AuthenticationConfigModel()
config.authentication.authUrl =
    'https://sisilogin.testeveonline.com/v2/oauth/authorize'
config.authentication.tokenUrl =
    'https://sisilogin.testeveonline.com/v2/oauth/token'
config.authentication.logoutUrl =
    'https://sisilogin.testeveonline.com/account/logoff?returnUrl='
config.authentication.redirectUrl = `${config.webBaseUrl}/callback`
config.authentication.silentRedirectUrl = `${config.webBaseUrl}/silentcallback`
export default config

import React, { Fragment } from 'react'

export default () => (
    <Fragment>
        <h2>Modalités de service</h2>
        <p>
            Notre site Web utilise des témoins (cookies). Le processus
            d&apos;inscription et la performance du site Web sont tributaires de
            l&apos;emploi de témoins. En poursuivant l&apos;inscription et en
            confirmant votre adresse e-mail, vous acceptez l&apos;utilisation de
            témoins. Si toutefois vous refusez l&apos;utilisation de témoins, ne
            confirmez pas votre adresse e-mail et ne finalisez pas votre
            inscription. Pour de plus amples informations sur nos politiques
            relatives aux témoins, consultez notre{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-eula/"
              rel="noopener noreferrer"
              target="_blank">
                contrat de licence de l&apos;utilisateur final EVE Online
            </a>{' '}
            et notre{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-privacy-policy/"
              rel="noopener noreferrer"
              target="_blank">
                politique de confidentialité
            </a>
            .
        </p>
        <p>
            En poursuivant le processus d&apos;inscription, je certifie avoir lu
            et accepté le{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-eula/"
              rel="noopener noreferrer"
              target="_blank">
                contrat de licence de l&apos;utilisateur final EVE Online
            </a>
            , les{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-tos/"
              rel="noopener noreferrer"
              target="_blank">
                modalités de service
            </a>{' '}
            et la{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-privacy-policy/"
              rel="noopener noreferrer"
              target="_blank">
                politique de confidentialité
            </a>
            . J&apos;autorise également CCP à utiliser mon adresse e-mail pour
            vérifier mes achats, me fournir de l&apos;assistance, m&apos;envoyer
            les mises à jour techniques et fonctionnelles d&apos;EVE Online, et
            pour toute autre communication liée à mon compte.
        </p>
    </Fragment>
)

import React from 'react'
import { flags } from 'config'
import { Translate } from 'react-localize-redux'
import { Container, Button, BackgroundVideo, Visibility } from 'layouts'
import BackgroundImage from 'features/background-image'
import { Link, ScrollIndicator } from 'features'
import { HeadingMedium, TaglineLarge, ParagraphLarge } from 'layouts/typography'
import AnimatedText from 'layouts/animated-text'
import style from './Hero.scss'

const Hero = ({ headline, teaser, align = '', content }) => {
    const playFree = content && content[0]
    const playPremium = content && content[1]
    const { colorThemeEnabled } = flags.features

    return (
        <BackgroundImage
          url="https://images.ctfassets.net/7lhcm73ukv5p/3UEgfmngX3LDgZq8Kg1QHT/0f54d36289cc6996a7ed1fe0f20563e9/phoenix-hero.jpg"
          size="cover"
          position="50% 50%"
          height={600}
        >
            <div className={style(style.hero, style[align], { [style.colorTheme]: colorThemeEnabled })}>
                <div>
                    <BackgroundVideo
                      fill
                      mp4="https://web.ccpgamescdn.com/aws/eveonline/videos/phoenix-splash.mp4"
                      webm="https://web.ccpgamescdn.com/aws/eveonline/videos/phoenix-splash.webm"
                    />
                </div>
                <Container className={style.container}>
                    <HeadingMedium>
                        <AnimatedText>{headline}</AnimatedText>
                    </HeadingMedium>
                    <TaglineLarge as="h2">
                        <AnimatedText>{teaser}</AnimatedText>
                    </TaglineLarge>
                    <div className={style(style.buttons, { [style.colorTheme]: colorThemeEnabled })}>
                        <div id="signup_ext" style={{ display: 'inline' }}>
                            {playFree && (
                                <Button
                                  size="large"
                                  theme={colorThemeEnabled ? 'quadrant' : 'primary'}
                                  className={style.btn}
                                  path={playFree.buttonUrl}
                                  data-id="homepage-playfree-button"
                                  showPlatform
                                >
                                    {playFree.buttonText}
                                </Button>
                            )}
                            {playPremium && (
                                <Button
                                  theme={colorThemeEnabled ? 'secondary' : 'secondary'}
                                  size="large"
                                  className={style.btn}
                                  path={playPremium.buttonUrl}
                                  data-id="homepage-playpaid-button"
                                >
                                    {playPremium.buttonText}
                                </Button>
                            )}
                        </div>
                        <div id="signup_int" style={{ display: 'none' }}>
                            {playFree && (
                                <Button
                                  size="large"
                                  theme={colorThemeEnabled ? 'quadrant' : 'primary'}
                                  className={style.btn}
                                  path={{ page: 'signup' }}
                                  internal
                                  data-id="homepage-playfree-button"
                                  showPlatform
                                >
                                    {playFree.buttonText}
                                </Button>
                            )}
                            {playPremium && (
                                <Button
                                  theme={colorThemeEnabled ? 'secondary' : 'secondary'}
                                  size="large"
                                  className={style.btn}
                                  path={playPremium.buttonUrl}
                                  data-id="homepage-playpaid-button"
                                >
                                    {playPremium.buttonText}
                                </Button>
                            )}
                        </div>
                        <Visibility direction="fadeIn" transitionDelay="2.5s">
                            <div id="returning_player" className={style.returningPlayer}>
                                <ParagraphLarge>
                                    <Translate id="frontpage.returningPlayerText1">
                                        Returning to EVE?
                                    </Translate>&nbsp;
                                    <Link data-id="frontpage-returning-player-cta" path={{ page: 'return' }}>
                                        <Translate id="frontpage.returningPlayerText2">
                                            Recover your account
                                        </Translate>
                                    </Link>
                                </ParagraphLarge>
                            </div>
                        </Visibility>
                    </div>
                </Container>
                <ScrollIndicator id="content" />
            </div>
        </BackgroundImage>
    )
}

export default Hero

import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import _includes from 'lodash/includes'
import PropTypes from 'prop-types'
import { Container } from 'layouts'
import { ParagraphRegular } from 'layouts/typography'
import { Link, SelectLanguage, SocialIcons } from 'features'
import { flags, version } from 'config'
import { isProduction, environment } from 'config/web/configHelper'
import SectionById from 'queries/SectionByIdQuery'
import config from 'config/web/config'
import style from './Footer.scss'

class Footer extends Component {
    constructor(props) {
        super(props)

        this.state = { pegi: true }
    }

    renderSocial = headline => (
        <div className={`${style.row} ${style.top}`}>
            <h3>{headline}</h3>
            <SocialIcons className={style.social} />
        </div>
    )

    renderColumn = content => content && (
    <div className={style.col}>
        <ReactMarkdown source={content.body} escapeHtml={false} />
    </div>
        )

    renderDevLinks = () => (
        <div className={style.col}>
            <ul>
                <li>DEV LINKS</li>
                {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'signup' }}>Signup</Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link
                          path={{
                                page: 'signup-verify',
                                query: {
                                    rec: 'false',
                                    signupSuccess: 'true',
                                    download: 'false'
                                }
                            }}
                        >
                            Signup Success - Manual
                        </Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link
                          path={{
                                page: 'signup-verify',
                                query: {
                                    rec: 'true',
                                    signupSuccess: 'true',
                                    download: 'false'
                                }
                            }}
                        >
                            Recruit Signup Success - Manual
                        </Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link
                          path={{
                                page: 'signup-verify',
                                query: {
                                    rec: 'false',
                                    signupSuccess: 'true',
                                    download: 'true'
                                }
                            }}
                        >
                            Signup Success - Auto
                        </Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link
                          path={{
                                page: 'signup-verify',
                                query: {
                                    rec: 'true',
                                    signupSuccess: 'true',
                                    download: 'true'
                                }
                            }}
                        >
                            Recruit Signup Success - Auto
                        </Link>
                    </li>
                )}
                {/* {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'testerror' }}>Error Test</Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'test' }}>Test Page</Link>
                    </li>
                )} */}
                {/* {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'pagethatdoesnotexist' }}>
                            Page Not Found
                        </Link>
                    </li>
                )} */}
                {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'vegas' }}>Vegas</Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'fanfest' }}>Fanfest</Link>
                    </li>
                )}
                {/* {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'policies' }}>Policies</Link>
                    </li>
                )} */}
                {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'now', subpage: 'dlp-test' }}>
                            DLP Test
                        </Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'events', subpage: 'vegas' }}>
                            Events Test
                        </Link>
                    </li>
                )}
            </ul>
        </div>
    )

    renderLanguage = () => (
        <div className={style.language}>
            <SelectLanguage />
        </div>
    )

    renderPegi = () => (
        <div className={style.pegi}>
            <a
              id="pegi-info"
              href="http://www.pegi.info/"
              title="Visit the PEGI webpage"
            >
                <img
                  src="https://web.ccpgamescdn.com/aws/eveonline/images/pegiinfo.png"
                  width="71"
                  height="50"
                  alt="The PEGI info logo"
                />
            </a>
        </div>
    )

    renderEsrb = () => (
        <div className={style.esrb}>
            <img
              id="ESRB-rating"
              src="https://web.ccpgamescdn.com/aws/eveonline/images/esrbnotrated.png"
              alt="ESRB Rated T for violence"
              height="50"
              width="177"
            />
        </div>
    )

    renderSEOText = (seoText) => (
        <div className={style.seo}>
            <ReactMarkdown source={seoText} escapeHtml={false} />
        </div>
    )

    renderRating = () => {
        const rating = this.state.pegi ? this.renderPegi() : this.renderEsrb()
        return <div className={style.rating}>{rating}</div>
    }

    renderRequired = () => (
        <div className={`${style.row} ${style.required}`}>
            <div className={style.copyright}>
                Copyright © CCP 1997-
                {new Date().getFullYear()}
            </div>
            {this.renderRating()}
        </div>
    )

    render() {
        const language = this.props.language

        return (
            <footer className={style.footer}>

                <SectionById identifier="footer" locale={language}>
                    {section => {
                        let headline
                        let seoText
                        let playContent
                        let playerContent
                        let universeContent
                        let siteinfoContent

                        if (section) {
                            headline = section.headline
                            seoText = section.teaser
                            playContent = section.contentCollection.items[0]
                            playerContent = section.contentCollection.items[1]
                            universeContent = section.contentCollection.items[2]
                            siteinfoContent = section.contentCollection.items[3]
                        }
                        return (
                            <Container>
                                {this.renderSocial(headline)}
                                <div className={`${style.row} ${style.below}`}>
                                    {this.renderLanguage()}
                                    {this.renderColumn(playContent)}
                                    {this.renderColumn(playerContent)}
                                    {this.renderColumn(universeContent)}
                                    {this.renderColumn(siteinfoContent)}
                                    {flags.isDevelopment && this.renderDevLinks()}
                                </div>
                                {this.renderSEOText(seoText)}
                                {this.renderRequired()}
                                {!isProduction && (
                                <div>
                                    <div className={style.dark}>DEV PROPERTIES</div>
                                    <div className={style.dark}>env {environment}</div>
                                    <div className={style.dark}>
                                        config env {config.env}
                                    </div>
                                    <div className={style.dark}>
                                        contentful preview{' '}
                                        {config.contentful.preview ? 'true' : 'false'}
                                    </div>
                                    <div className={style.dark}>
                                        contentful environment:{' '}
                                        {config.contentful.environment}
                                    </div>                                    
                                    <div className={style.dark}>
                                        signup api {config.signupServiceUrl}
                                    </div>
                                    <div className={style.dark}>
                                        recruitment api {config.recruitmentServiceUrl}
                                    </div>
                                    <div className={style.dark}>
                                        sentry url {config.sentryDsn}
                                    </div>
                                    <div className={style.dark}>
                                        storefront api {config.storefrontServiceUrl}
                                    </div>
                                    <div className={style.dark}>Version: {version}</div>
                                    <div className={style.dark}>
                                        Serverless runtime: nodejs8.10
                                    </div>
                                </div>
                            )}
                            </Container>
                        )
                }}
                </SectionById>
            </footer>
        )
    }
}

Footer.propTypes = {
    language: PropTypes.string
}

export default Footer

import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import CTA from 'features/jumbotron/components/cta'
import { Container } from 'layouts'
import { ThemeContext } from '../../context'
import style from './CTATemplate.scss'

const CTATemplate = ({ section }) => (
    <Container className={style.cta}>
        <ReactMarkdown source={section.body} escapeHtml={false} />
        <div className={style.content}>
            <ThemeContext.Consumer>
                {colorTheme => (
                    <CTA
                      section={section.contentCollection.items}
                      data-id="dynamic-bottom-cta-button"
                      className={style.buttons}
                      custom={{
                            color: colorTheme.CTA.color,
                            background: colorTheme.CTA.background,
                        }}
                    />
                )}
            </ThemeContext.Consumer>
        </div>
    </Container>
)

CTATemplate.propTypes = {
    section: PropTypes.shape({
        body: PropTypes.string,
        content: PropTypes.arrayOf(PropTypes.object),
    }),
}

export default CTATemplate

import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import PageWithSeo from 'features/page-with-seo'
import Loading from 'layouts/loading/PageLoading'
import { isClient } from 'config/web/configHelper'

let LoadableComponent

if (isClient) {
    LoadableComponent = React.lazy(() => import(/* webpackChunkName: "page-fanfest" */ './Fanfest'))
}

export default function LoadableFanfest(props) {
    const page = useSelector(state => state.pages.fanfest)

    return (
        <PageWithSeo page={page} showLoading={!isClient} hideSitename>
            {isClient && (
                <React.Suspense fallback={<Loading />}>
                    <LoadableComponent {...props} />
                </React.Suspense>
            )}
        </PageWithSeo>
    )
}

import React from 'react'
import _map from 'lodash/map'
import SectionType from 'models/types/ts/sectionType'

const renderPolicyOverview = (policies: Array<SectionType>) => {
    const tabs = _map(policies, (item) => {
        console.log('ITEM: ', item)
        return (
            item && (
                <a key={item.identifier} href={`/policies/${item.identifier}`}>
                    {item.headline}
                </a>
            )
        )
    })

    return tabs
}

export default function Policies({ policies }) {
    console.log('policies page sections: ', policies)

    // Main policy overview page
    return (
        <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>Policies</h1>
            {/* <h2>{metaTitle}</h2> */}
            {renderPolicyOverview(policies)}
        </div>
    )
}

import { connect } from 'react-redux'

import PlayerResources from './components/PlayerResources'

function mapStateToProps({ pages: { playerResources } }) {
    let data = {}

    if (playerResources) {
        data = playerResources.section
    }

    let { headline, body, backgroundImage } = ''
    let hasContent = false
    if (playerResources) {
        headline = data.headline
        body = data.body
        backgroundImage = data.imageFile
        hasContent = true
    }
    return {
        headline,
        body,
        backgroundImage,
        content: data.content,
        hasContent,
    }
}

export default connect(mapStateToProps, null)(PlayerResources)

import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import Table from './table'

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize),
    }
}

export default connect(mapStateToProps)(Table)

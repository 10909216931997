import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import React, { Fragment } from 'react'
import { render, hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { redirect } from 'redux-first-router'
import { ApolloProvider } from 'react-apollo'
import { LocalizeProvider } from 'react-localize-redux'
import { HelmetProvider } from 'react-helmet-async'
import getApolloContentfulClient from 'utils/apollo/client'
import { renderToStaticMarkup } from 'react-dom/server'
import { getLanguages } from 'selectors'
import { flags } from 'config'
import { redirectFromLangCookie, getLangCookie } from 'utils/langCookie'
// import { fetchCountryIfNeeded } from 'lib/country/actions' // Browser and JS language shims used by Airbnb. Specifically for react-outside-click-handler to work in more browsers

import sessionId from 'utils/session-id'
import TagManager from 'react-gtm-module'
import config from 'config/web/config'
import ScrollContext from 'utils/context/ScrollContext'
import {
    hasToken,
    hasRefreshToken,
    getToken,
    getExpiresAt
} from './packages/authentication/helpers/token'
import {
    loginSuccess,
    authDoRefreshToken
} from './packages/authentication/lib/actions'
import App from './layouts/app'
import './index.scss'
// import * as serviceWorker from './serviceWorker'
import configureStore from './configureStore'
import './config/fontawesome'
import globalTranslations from './globalTranslations'
import 'airbnb-browser-shims'

const { store } = configureStore(window.REDUX_STATE)

if (window !== undefined) {
    // Generate session ID
    sessionId()
    // Check if the user has auth cookie on client-side only
    if (hasToken()) {
        store.dispatch(loginSuccess(getToken(), getExpiresAt(), ''))
    } else if (hasRefreshToken()) {
        store.dispatch(authDoRefreshToken())
    }

    const state = store.getState()
    const { location } = state
    const payload = state.location.payload
    const query = state.location.query

    // Getting country if needed
    // if (flags.isDevelopment) {
    //     if (!state.country) {
    //         store.dispatch(fetchCountryIfNeeded())
    //     }
    // }

    // redirecting to the language saved in the cookie if required
    // REMOVING redirectFromLangCookie to see if ssr is breaking localized not found pages not working.
    redirectFromLangCookie(store.dispatch, payload, location)
}

const tagManagerArgs = {
    gtmId: config.gtmID
}

TagManager.initialize(tagManagerArgs)

const client = getApolloContentfulClient()

const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation
const renderMethod = module.hot ? render : hydrate
renderMethod(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <LocalizeProvider
              store={store}
              initialize={{
                    languages: getLanguages(),
                    translation: globalTranslations,
                    options: {
                        onMissingTranslation,
                        renderToStaticMarkup
                    }
                }}
            >
                <HelmetProvider>
                    <ScrollContext>
                        <App />
                    </ScrollContext>
                </HelmetProvider>
            </LocalizeProvider>
        </ApolloProvider>
    </Provider>,
    document.getElementById('root')
)

// serviceWorker.unregister()

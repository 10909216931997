import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import style from './AppLayout.scss'

const AppLayout = ({ children, lang }) => {

    const theClass = classNames(
        style.layout,
        {[style.japan]: lang === 'ja' }
    )

    return (
        <div className={theClass}>{children}</div>
    )
}

AppLayout.propTypes = {
    children: PropTypes.node,
    lang: PropTypes.string,
}

export default AppLayout

import paths from '../paths'
import release from '../version'
import { environment } from '../web/configHelper'
import { blacklistUrls, ignoreErrors } from './sentryOptions'

const dsn = paths.sentryDsn

const sentryInit = {
    dsn,
    environment,
    release,
    // whitelistUrls, // TODO skipping this for now as it seems to block ssr error to be reported to Sentry
    blacklistUrls,
    ignoreErrors,
    beforeBreadcrumb(crumb, hint) {
        if (crumb.category === 'console') {
            // hint.input === raw arguments passed to console calls
            // do anything to them, parse, stringify, whatever

            // Clearing data for now because it can be too large.
            crumb.data = null
        }
        return crumb
    },
}

export default sentryInit

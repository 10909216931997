import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getPage } from 'lib/location/selectors'
import { getArticleUrl } from 'utils/articles'
import BackgroundImage from 'features/background-image'
import { Container } from 'layouts'
import NotFound from 'pages/not-found'
import PageWithSeo from 'features/page-with-seo'
import getRedirectFromPath from 'utils/redirect/redirect'
import ArticleBySlugQuery from 'queries/ArticleBySlugQuery'
import NewsDetail from './components/NewsDetail'
import style from './NewsDetailContainer.module.scss'

export default function NewsDetailContainer() {
    
    const location = useSelector((state) => state.location)
    const dispatch = useDispatch()

    const subpage = location && location.payload && location.payload.subpage

    const slug = location && location.payload && location.payload.id    

    const newsPage = useSelector((state) => getPage(state))
    const language = useSelector((state) => state.language)

    console.log('detail', slug)

    return (
        <ArticleBySlugQuery slug={slug} locale={language}>
            {(singleArticle) => {
                let hasContent = false
                if(singleArticle) {
                    if (subpage === 't') {
                        dispatch(getRedirectFromPath(getArticleUrl(singleArticle, slug)))
                    }

                    hasContent = true
                                        
                    return (
                        <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
                            <NewsDetail newsItem={singleArticle} />
                        </BackgroundImage>
                        )
                    }

                return <NotFound />
            }}
        </ArticleBySlugQuery>
    )
}

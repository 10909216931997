import React, { useEffect } from 'react'
import { Loading } from 'layouts'
import { SEO } from 'features'
import BackgroundImage from 'features/background-image'
import Hero from './hero'
import Cta from './cta'
import Extra from './extra'
import Props from './YearProps'
import style from './YearReview.module.scss'

// let scriptLoaded = false

const YearReview = ({
    id, baseUrl, metadata, pageBackground, hero, cta, extra, bottom, hasContent
}: Props) =>{

    // Construct full video url to download also.
    const fullName = baseUrl + id
    const fullVideoUrl = `${fullName}.mp4`
    const fullThumbUrl = `${fullName}.jpg`

    // useEffect(() => {

    //     // TODO Check for isClient to use window.

    //     // Only load script if video div is on the page. It's not there in beginning
    //     const videoEl = document.getElementById('video')

    //     if(!scriptLoaded && videoEl){

    //         // Setting script loaded so it is only loaded once.
    //         scriptLoaded = true
    //         const theScript = 
    //         `${'window.IdmEngineCallback = function(){'
    //         +    'Idm.Engine.init({'
    //         +         'domain: "https://idoplayer.idomoo.com/19/",'
    //         +         'url: "'}${ fullVideoUrl }",`
    //         +         `autostart: "1",`
    //         +         `baseMovieURL: "https://e.idomoo.com/",`
    //         +         `movieExt:"mp4"`
    //         +   `});`
    //         + `};`
    //         + `(function(d, s, id){`
    //         + `var js, fjs = d.getElementsByTagName(s)[0];`
    //         + `if (d.getElementById(id)) {return;}`
    //         + `js = d.createElement(s); js.id = id;`
    //         + `js.src = "https://idoplayer.idomoo.com/19/assets/js/eng.js";`
    //         + `js.async = false;`
    //         + `fjs.parentNode.insertBefore(js, fjs);`
    //         + `}(document, "script", "idomooEngine"));`

    //         const script = document.createElement('script')
    //         script.innerHTML = theScript
    //         if(videoEl) {
    //             // console.log('DEMO')
    //             videoEl.appendChild(script)
    //         }
    //         else {
    //             // console.log('NO DEMO')
    //         }

    //     }

    // })


    return (

        // console.log("TCL: YearReview -> render -> cta", cta)
        // console.log("TCL: YearReview -> render -> hero", hero)
        <>
            {!hasContent &&
                <Loading className={`${hasContent ? 'hasLoaded' : 'isLoading'}`} />
            }
            {hasContent && (
                <>
                    <SEO
                      title={metadata.title}
                      description={metadata.description}
                      image={fullThumbUrl}
                      hideSitename
                      robots='noindex'
                    />
                    <BackgroundImage
                      url={pageBackground}
                      repeat="repeat"
                      size="contain"
                      position="top center"
                      className={style.content}>
                        <BackgroundImage
                          url={hero.imageFile}
                          repeat="no-repeat"
                          size="contain"
                          position="center top"
                          className={style.hero}
                        >
                            <BackgroundImage
                              url={bottom.imageFile}
                              repeat="no-repeat"
                              size="contain"
                              position="center bottom"
                              className={style.bottom}
                            >
                                {hero && <Hero section={hero} id={id} videoUrl={fullVideoUrl} />}
                                {cta && <Cta section={cta} />}
                                {extra && <Extra section={extra} />}
                            </BackgroundImage>
                        </BackgroundImage>
                    </BackgroundImage>
                </>
            )}
        </>
    )
}

export default YearReview
import React from 'react'
import moment from 'moment'
import ArticlesByCategory from 'queries/ArticlesByCategory'
import Link from '../shared/link'
import style from './PatchNotesLatest.module.scss'

interface Props {
    language: string
}

const PatchNotesLatest = ({ language }: Props) => {
    return (
        <ArticlesByCategory locale={language} limit={1} category="patch-notes">
            {(item) => {
                const {
                    slug,
                    sys: { publishedAt },
                } = item[0]
                return (
                    <div className={style.patchNotes}>
                        <Link slug={slug} className={style.link}>
                            <strong>Patch Notes</strong> (Last updated{' '}
                            {moment(publishedAt).format('YYYY-MM-DD')})
                        </Link>
                    </div>
                )
            }}
        </ArticlesByCategory>
    )
}

export default PatchNotesLatest

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import { Section, Icon } from 'layouts'
import Slider from 'react-slick'
import { fetchAdsIfNeeded } from '../action'

import style from './adGlare.scss'

// const AdGlare = ({ hasContent, imgSource, link, className, language }) => {
class AdGlare extends Component {
    componentDidMount = () => {
        const { banners } = this.props

        if (_isEmpty(banners)) {
            this.props.dispatch(fetchAdsIfNeeded)
        }
    }

    componentDidUpdate = prevProps => {
        if (prevProps.shouldFetch !== this.props.shouldFetch) {
            this.props.dispatch(fetchAdsIfNeeded)
        }
    }

    render() {

        const NextArrow = (props) => {
            const { onClick, className } = props
            return (
                <div role="presentation" className={className} onClick={onClick}>
                    <Icon name="chevron-circle-right" />
                </div>
            )
        }

        const PrevArrow = (props) => {
            const { onClick, className } = props
            return (
                <div role="presentation" className={className} onClick={onClick}>
                    <Icon name="chevron-circle-left" />
                </div>
            )
        }

        const slideSettings = {
            dots: false,
            arrows: true,
            infinite: true,
            fade: true,
            // lazyLoad: true,
            draggable: false,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
        }

        const { banners, imgSource, link, isFetching } = this.props

        const bannerAds = _map(banners, (item, index) => (
            <a key={index} href={item.link} alt="">
                <img alt="" src={item.src} />
            </a>
        ))

        return (
            <div className={style.adsWrapper}>
                <Section hasContent={!isFetching} loadingTypeSmall spinnerSize={10}>
                    <Slider {...slideSettings}>
                        {bannerAds}
                    </Slider>
                </Section>
            </div>
        )
    }
}

AdGlare.propTypes = {
    dispatch: PropTypes.func.isRequired,
    banners: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            link: PropTypes.string,
        })
    ),
    isFetching: PropTypes.bool,
    shouldFetch: PropTypes.bool,
}

export default AdGlare

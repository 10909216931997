import { connect } from 'react-redux'
import { getTranslate } from 'react-localize-redux'
import NavItem from './NavItem'

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize),
    }
}

export default connect(mapStateToProps)(NavItem)

import React from 'react'
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'
import { getLanguage } from 'selectors'
import NavItem from './components/navitem'

const Navigation = ({ className, page, items }) => {
    const language = useSelector((state) => getLanguage(state))

    return (
        <nav className={className}>
            {items.map((item) => {
                if (item.disabled) {
                    return null
                }

                const props = {
                    key: item.dataId,
                    translationId: item.translationId,
                    dataId: item.dataId,
                }

                if (item.href) {
                    props.href = item.href
                    props.external = true
                    // Can overwrite external links with LOC link if they are set.
                    // Then the string {language} in the loc link will be replaces with the selected language on page
                    if (language !== 'en' && item.locHref) {
                        // extra check just for japan for now. can be removed when updates is sunsetted.
                        if(!(language === 'ja' && item.locHref.includes('updates.eveonline.com')))
                        {
                          props.href = item.locHref.replace('{language}', language)
                        }
                    }
                } else {
                    props.page = item.page

                    if (item.sub) {
                        props.sub = item.sub
                    }

                    if (item.selectedValues && item.selectedValues.length > 0) {
                        props.selected = item.selectedValues.includes(page)
                    }
                }

                return <NavItem {...props} />
            })}
        </nav>
    )
}

Navigation.propTypes = {
    className: PropTypes.string,
    page: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            translationId: PropTypes.string,
            page: PropTypes.string,
            sub: PropTypes.string,
            href: PropTypes.string,
            dataId: PropTypes.string,
            selectedValues: PropTypes.arrayOf(PropTypes.string),
            disabled: PropTypes.bool,
        })
    ),
}

export default Navigation

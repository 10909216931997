import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Column, Row } from '../../../../../../layouts/common'
import { Button } from '../../../../../../layouts'
import style from '../../LiveEvent.scss'

const IntroSection = ({ title, subTitle, content, eventImage, buttonText, buttonUrl }) => (
    <Fragment>
        <Row>
            <Column xs={12} sm={8}>
                <h2>{title}</h2>
                <h3>{subTitle}</h3>
                <ReactMarkdown source={content} escapeHtml={false} />
                <Button
                  path={buttonUrl}
                  size="large"
                  showPlatform
                  className={style.cta}
                >
                    {buttonText}
                </Button>
            </Column>
            <Column xs={12} sm={4} className={style.event_logo_wrapper}>
                <img alt={title} src={eventImage} className={style.event_logo} />
            </Column>
        </Row>
    </Fragment>
)

IntroSection.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    content: PropTypes.string,
    eventImage: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
}

export default IntroSection

import axios from 'axios'
import Logger from 'utils/logging'
import { isClient } from 'config/web/configHelper'
import { getAfterSignupCookieValues } from './PlayNowStorage'
import getOsInfo from './PlayNowHelpers'

// TODO get from config.
const endgameApi = 'https://www.endgame42.com/api'

const apiUrl = endgameApi

// Speed of Me
const SOM_API_KEY = 'SOM5f330746454f0' // TODO add to config

function getAuthValues() {
    const values = getAfterSignupCookieValues()
    // Dummy if we want, then comment out the cookie call
    // values.user_token =
    //     'eyJhbGciOiJSUzI1NiIsImtpZCI6IkpXVC1TaWduYXR1cmUtS2V5IiwidHlwIjoiSldUIn0.eyJzY3AiOlsiZXZlQ2xpZW50TG9naW4iLCJjaXNzZXJ2aWNlLmN1c3RvbWVyUmVhZC52MSIsImNpc3NlcnZpY2UuY3VzdG9tZXJXcml0ZS52MSJdLCJqdGkiOiI1NzA2NTgxNS01NmExLTQyZDMtYTRhYy1lMzU1ODc5OTk2ODYiLCJraWQiOiJKV1QtU2lnbmF0dXJlLUtleSIsInN1YiI6IlVTRVI6RVZFOjE3NzkzOTc2IiwiYXpwIjoiZXZlTGF1bmNoZXJUUSIsIm5hbWUiOiJpbmd2aWhfdGVzdF8wMDEiLCJleHAiOjE1OTg5NzA2NDIsImlzcyI6ImxvZ2luLmV2ZW9ubGluZS5jb20ifQ.lL23saRaxXEeUcN2L4_boE_T1wyl7Q2R9wRjmvdYfCk9D71nKK_Q3VyEt0Xym7Eb96zVFIPYLSr14cozOrSEQV7-6mkNGx31MoxxQw4TXDNuuDKPAXRv7aWH8d30gqWCr29Pzk72O4fQV3vvGEwOQYNtFJ1zP8JH2CLYUJZzAnOgqh3WO_kM0WH6FS_UgjoiFgFHsIkgfFtZ20BrupVNBRBMkC00v8VIdvX01iTe8WMAKVDEwgZAdZj9U7abGU-Pm0TT5OivYf9ShIwQ6q5QTe3B53fNbxGqDZZTOXGIrmGMpJjN7Uxk3_k2y3Yt4_TRiQMtGTEu30CohKxERynoog'
    // values.username = 'ingvih_test_001'
    // values.access_token =
    //     'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6ImppYk5ia0ZTU2JteFBZck45Q0ZxUms0SzRndyJ9.eyJhdWQiOiIzOWZlMjdmYi1hMzk4LTQ2OTYtOTcxOS02ZDFjMDI0ZmRhMDIiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vNjUzMzIzOWYtMzhjZC00Y2U5LTkyOGEtNGZiYjJjODIxMzRlL3YyLjAiLCJpYXQiOjE1OTg5Njg1MDIsIm5iZiI6MTU5ODk2ODUwMiwiZXhwIjoxNTk4OTcyNDAyLCJhaW8iOiJFMkJnWUJDUERtWXc3MnErMXg4b1o2K2VJQjhFQUE9PSIsImF6cCI6Ijc5ZGIzZjVmLTYwNDUtNDFlMC05NTlhLWU1OTJkZDAwMDc5MSIsImF6cGFjciI6IjEiLCJvaWQiOiJjNmQxNzA0YS0yN2RjLTQ5ODQtOWViMS1kOTA4YzdhNmRmMWQiLCJyaCI6IjAuQVRjQW55TXpaYzA0NlV5U2lrLTdMSUlUVGw4XzIzbEZZT0JCbFpybGt0MEFCNUUzQUFBLiIsInN1YiI6ImM2ZDE3MDRhLTI3ZGMtNDk4NC05ZWIxLWQ5MDhjN2E2ZGYxZCIsInRpZCI6IjY1MzMyMzlmLTM4Y2QtNGNlOS05MjhhLTRmYmIyYzgyMTM0ZSIsInV0aSI6InhnYzRvWXFtUEUtU3lQb2QtSWtrQUEiLCJ2ZXIiOiIyLjAifQ.afIW5LVOJCe8F3B1_4ZMB9MvTVKUC3MG8EFRdrW0VPAOEMVKqIW4GZ6Qv3BeuUPY8mQnNvdTEN0UbcGjdZAusIOdRmXSgVlKDfs2nO1F8Mjr34i1Ke47QNqJxs1LlAzUA9VUFDXyeNDZgwCQq7xUNZ3opRBRM7cXdxo7GwFheplc1NnTreQ_qOQ5yPXJV2dh8jME-xQIOpWlTV0NHLlpJNvtuDuJSLsvGOFDlt9NKMoolOEOp5HCxVMjssKUxquTCFh3s1TMWikEuiz-o5b-os8-ZvULwpTU4J6slAQt89dD_6Lf4E4Knyzm8Zs4uFQPaMtxSL80EUn2SqdQmzrnQQ'

    //console.log('values: ', values)

    return values
}

const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay))

export async function serversAvailable(dummy = false): Promise<boolean> {
    //console.log('FUNCTION: 2. serversAvailable')

    if (dummy) {
        //console.log('-- (fake) Returning true after 2 second')
        await sleep(2000)
        return true
    }

    const { user_token, access_token } = getAuthValues()

    const customerHeader = JSON.stringify({
        token: user_token,
        name: 'ccp',
    })

    const value = await axios({
        url: `${apiUrl}/QualifyAccessMachine`,
        headers: {
            Authorization: `Bearer ${access_token}`,
            'Access-Control-Allow-Origin': '*',
            'EndGame-Customer': customerHeader
        },
        method: 'get',
    })
        .then((result) => {
            //console.log('result: ', result.data)
            return result.data.value
        })
        .catch((error) => {
            Logger.captureException(error, null, {
                category: 'playnow',
                location: 'serversAvailable',
                message: error,
            })
        })

    //console.log('returning value: ', value)
    return value
    // return true
}

async function callSpeedTest(): Promise<boolean> {
    //console.log('FUNCTION: 3. callSpeedTest (NEW)')

    const { access_token } = getAuthValues()

    const SomApi = window.SomApi
    //console.log('SomApi: ', SomApi)

    //console.log('window.location.hostname: ', window.location.hostname)

    SomApi.account = SOM_API_KEY //your API Key here
    SomApi.domainName = window.location.hostname //your domain or sub-domain here
    SomApi.config = {
        sustainTime: 4, // Level of accuracy (1-8). Lower values give faster results but are less accurate.
        latencyTestEnabled: false,
        testServerEnabled: false,
        uploadTestEnabled: false,
        downloadTestEnabled: true,
        userInfoEnabled: false,
        //this is optional, to disable, set enabled to false
        progress: {
            enabled: true,
            verbose: true,
        },
    }

    return new Promise((resolve, reject) => {
        function onProgress(e) {
            // handle progress here
            // console.log('handle progress here')
        }
        function onTestCompleted(data) {
            try {
                axios({
                    url: `${apiUrl}/LogSpeedTest?speed=${data.download}`,
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                    method: 'get',
                })
                if (data.download > 30) {
                    resolve(true)
                } else {
                    // Returns false if speed below 30
                    resolve(false)
                }
            } catch (e) {
                Logger.captureException(e, null, {
                    category: 'playnow',
                    location: 'callSpeedTest.onTestCompleted',
                    message: e,
                })
                reject(e)
            }
        }
        function onError(e:Error) {
            Logger.captureException(e, null, {
                category: 'playnow',
                location: 'callSpeedTest.onError',
                message: e,
            })
            reject(e)
        }
        SomApi.onProgress = onProgress
        SomApi.onTestCompleted = onTestCompleted
        SomApi.onError = onError
        SomApi.startTest()
    })
}

export async function showPlayNow(dummy = false): Promise<boolean> {
    //console.log('FUNCTION: 1. showPlayNow')

    // throw new Error(`test error`)

    // Bypassing all pre check calls.
    if (dummy) {
        //console.log('Going to return true from play now after some time.')
        await sleep(3000)
        //console.log('RETURNING TRUE')
        return true
    }

    // Only call in browser, these are doing window.references
    if (isClient) {

        // // Comment this in to run all pre checks

        // Not calling isServersAvailable here, doing it after signup and then not going to PlayNow if it returns false.
        // const isServerAvailable = await serversAvailable()

        // if (isServerAvailable) {
        //     return callSpeedTest()
        // }

        return callSpeedTest()
    }
    return false
}

export async function getLaunchUrl(dummy = false): Promise<string> {
    //console.log('FUNCTION: 4. getLaunchUrl')

    if (dummy) {
        //console.log('Going to return dummy url after some time')
        await sleep(3000)
        //console.log('RETURNING URL')
        return `https://${window.location.hostname}/signup-confirmation?sessionEnded=true`
    }

    const { user_token, username, access_token } = getAuthValues()

    // Got this to work, but need to improve, use axios etc.

    // console.log('FUNCTION: getLaunchUrl')

    const customerHeader = JSON.stringify({
        token: user_token,
        name: 'ccp',
    }) // customer header info detailed above.

    const myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${access_token}`)
    myHeaders.append('Accept', 'text/plain') // Set Accept header to text/plain to get prebuilt url for session
    myHeaders.append('EndGame-Customer', customerHeader)

    //console.log('myHeaders: ', myHeaders)

    const body = getOsInfo(username)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(body),
        redirect: 'follow',
        credentials: 'include',
    }

    const gameid = 'eve' // provided by Intel

    // no redirect param can be added to skip redirect
    const noredirect = '&noredirect'

    // const sessionEndedUrl = '&SessionEndedUrl=https%3A%2F%2Flocal.ccpeveweb.com'
    const sessionEndedUrl = `&SessionEndedUrl=https%3A%2F%2F${window.location.hostname}/signup-confirmation?sessionEnded=true`

    const resolution = '&Resolution=fhd'

    // var launchUrl = "https://www.endgame42.com/api/Launch?GameId=" + gameid + noredirect
    // const launchUrl = `https://www.endgame42.com/api/Launch?gameid=eve&resolution=fhd&username=${username}&version=v2&noredirect`
    const launchUrl = `https://www.endgame42.com/api/Launch?GameId=${gameid}&username=${username}${noredirect}${sessionEndedUrl}${resolution}`
    //console.log('launchUrl: ', launchUrl)


    var finalurl = await fetch(launchUrl, requestOptions) // eslint-disable-line
        .then((response) => {
            //console.log('response: ', response)
            //console.log('response.status: ', response.status)
            if(response.status === 200) {
                return response.text()
            }
            else {
                //console.error(`Error when getting launch url, response.status: ${response.status}`)
                throw new Error(`Error when getting launch url, response.status: ${response.status}`)
            }
        } )
        .then((result) => {
            //console.log('result: ', result) // Should be the session url, if accept
            return result
        })
        .catch((error) => {
            Logger.captureException(error, null, {
                category: 'playnow',
                location: 'getLaunchUrl',
                message: error,
            })
        })

    //console.log('final url: ', finalurl)
    return finalurl
}

import paths from '../paths'
import { environment, nodeenvironment, isServer } from '../web/configHelper'

function setScope(scope) {
    const execution_environment = isServer ? 'server' : 'client'
    scope.setTag('react_app_environment', environment)
    scope.setTag('node_environment', nodeenvironment)
    scope.setTag('domain', paths.webBaseUrl)
    scope.setTag('execution_environment', execution_environment)
    scope.setTag('error_boundary', false)
}

export default setScope

import { isClient, isDevelopment } from 'config/web/configHelper'
import utmType from './models/utmType'

const CAMPAIGN_STORAGE_KEY = 'utm'

// const LANDING_PAGE = 'LANDING_PAGE'
// const utm_source = 'UTM_SOURCE'
// const utm_medium = 'UTM_MEDIUM'
// const utm_campaign = 'UTM_CAMPAIGN'
// const utm_term = 'UTM_TERM'

export const setValues = (utmValues: utmType) => {
    // PROTO_EVENT_TRACKING_CODE
    if (isDevelopment) { // TODO REMOVE LATER
        if (isClient) {
            console.log('ProtoValuesStorage setting values to : ', utmValues)

            sessionStorage.setItem(
                CAMPAIGN_STORAGE_KEY,
                JSON.stringify(utmValues)
            )
            // if(username !== undefined) sessionStorage.setItem(USER_NAME, username)
            // if(playToken !== undefined) sessionStorage.setItem(PLAY_TOKEN, playToken)
        }
    }
}

export const getValues = () => {
    if(isClient) {
        const utm: utmType = JSON.parse(sessionStorage.getItem(CAMPAIGN_STORAGE_KEY))
        console.log('getValues utm: ', utm)
        return utm
    }
    return {}
}
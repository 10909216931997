import React, { Component } from 'react'
import _map from 'lodash/map'
import _find from 'lodash/find'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { scroller } from 'react-scroll'
import { isClient } from 'config/web/configHelper'
import { Section, Loading, SvgIcon } from 'layouts'
import { Link, SelectLanguage } from 'features'
import style from './DynamicPage.scss'
import ComponentFactory from '../componentFactory'
import defaultTheme from '../../defaultTheme.json'
import { ThemeContext, LocationContext } from '../../context'

class DynamicPage extends Component {
    componentDidMount() {
        if (isClient) {
            const hash = window.location.hash.substring(1)
            if (hash) {
                // Set timeout because of dynamic content
                setTimeout(() => {
                    scroller.scrollTo(hash, {
                        duration: 500,
                        smooth: true,
                        isDynamic: true,
                        delay: 200
                    })
                }, 600)
            }
        }
    }

    renderSections = (sections, themeConfig, location) => _map(sections, (section, i) => (
        <Section key={i} className={style.dynamic_section}>
            <ThemeContext.Provider value={themeConfig}>
                <LocationContext.Provider value={location}>
                    <ComponentFactory data={section} key={i} />
                </LocationContext.Provider>
            </ThemeContext.Provider>
        </Section>
        ))

    render() {
        const { sections, pageBackground, colorInfo, slug, config } = this.props
        const { location } = this.props

        const themeConfig = {
            ...defaultTheme,
            ...config
        }

        if (sections != null) {
            _map(sections, (section) => {
                section.colorInfo = colorInfo
                section.wide = true
            })
        } else {
            return <Loading />
        }

        return (
            <div className={classNames(style.page, {[style.japan]: config && config.japanese})}>
                {pageBackground && pageBackground.url && (
                    <div
                      className={style.bg}
                      style={{
                            backgroundImage: `url(${pageBackground.url})`
                        }}
                    />
                )}
                <Link className={style.logo}>
                    <SvgIcon
                      width={130}
                      name="eve-logo-with-text"
                      fill={slug === 'eclipse' ? '#000' : '#fff'}
                    />
                </Link>
                <SelectLanguage className={style.language} />
                {this.renderSections(sections, themeConfig, location)}
            </div>
        )
    }
}

DynamicPage.propTypes = {
    colorInfo: PropTypes.objectOf(PropTypes.number),
    pageBackground: PropTypes.shape({
        url: PropTypes.string
    }),
    sections: PropTypes.arrayOf(PropTypes.object)
}

export default DynamicPage

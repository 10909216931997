import { shape, string, arrayOf } from 'prop-types'

export const roadmapType = shape({
    title: string.isRequired,
    slug: string.isRequired,
    description: string,
    body: string,
    startDate: string.isRequired,
    endDate: string.isRequired,
    delayedDate: string,
})

export const arrayOfRoadmapType = arrayOf(roadmapType)

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { values } from 'config'
import { Content } from 'layouts'
import {
    Footer,
    SEO,
    CookieConsent,
    IEWarning,
    Personalization
} from 'features'
import Navigation from 'features/navigation'
// import Navigation from 'features/navbar'
import ErrorBoundary from 'features/error-boundary'

class DefaultPageLayout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            testingState: 0
        }
    }

    render() {
        const { page, lang } = this.props
        const showLightTheme = this.props.darkNavigation
        const hideBorder = this.props.hideMenuBorder
        const showLogin = this.props.showLogin
        const path = this.props.path

        // console.log('this.props in default page layout', this.props)
        // console.log('path in default page layout', path)

        return (
            <div className={lang}>
                <Personalization content="" />
                <IEWarning />
                <SEO {...values.seo} hideSitename />
                <CookieConsent lang={lang} />
                <Navigation
                  lightTheme={showLightTheme}
                  hideBorder={hideBorder}
                  showLogin={showLogin}
                />
                <Content>
                    <ErrorBoundary>{page}</ErrorBoundary>
                    <Footer />
                </Content>
            </div>
        )
    }
}

DefaultPageLayout.propTypes = {
    page: PropTypes.node,
    lang: PropTypes.string,
    darkNavigation: PropTypes.bool,
    hideMenuBorder: PropTypes.bool,
    showLogin: PropTypes.bool
}

export default DefaultPageLayout

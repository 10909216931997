const localize = {
    en: {
        articles: 'Articles',
        recruit: 'Recruit a Friend',
        updates: 'Updates',
        community: 'Community',
        support: 'Support',
        addGameTime: 'Add Game Time',
        download: 'Download EVE',
        login: 'Log in',
        logout: 'Log out',
        playForFree: 'Play Free',
        upgradeToOmega: 'Upgrade to Omega',
    },

    fr: {
        articles: 'Articles',
        recruit: 'Parrainer un ami',
        updates: 'Mises à jour',
        community: 'Communauté',
        support: 'Assistance',
        addGameTime: 'Ajouter du temps de jeu',
        download: 'Télécharger EVE',
        login: 'Se connecter',
        logout: 'Se déconnecter',
        playForFree: 'Jouez gratuitement',
        upgradeToOmega: 'Passez à Omega',
    },

    de: {
        articles: 'Artikel',
        recruit: 'Freunde rekrutieren',
        updates: 'Updates',
        community: 'Community',
        support: 'Kundendienst',
        addGameTime: 'Spielzeit hinzufügen',
        download: 'EVE herunterladen',
        login: 'Einloggen',
        logout: 'Ausloggen',
        playForFree: 'Kostenlos spielen',
        upgradeToOmega: 'Werte zu Omega auf',
    },

    ru: {
        articles: 'Статьи',
        recruit: 'Программа «Позови друга»',
        updates: 'Обновления',
        community: 'Сообщество',
        support: 'Поддержка',
        addGameTime: 'Продлить время игры',
        download: 'Загрузить игру',
        login: 'Войти',
        logout: 'Выйти',
        playForFree: 'Играть бесплатно',
        upgradeToOmega: 'Оформите омега-статус',
    },

    ja: {
        articles: '記事',
        recruit: '友達を招待しよう',
        updates: 'アップデート',
        community: 'コミュニティ',
        support: 'サポート',
        addGameTime: 'ゲームタイムを追加',
        download: 'EVEをダウンロード',
        login: 'ログイン',
        logout: 'ログアウト',
        playForFree: '無料でプレイ',
        upgradeToOmega: 'オメガにアップグレード',
    },
}

export default localize
class AuthenticationConfigModel {
    authUrl: string
    tokenUrl: string
    logoutUrl: string
    redirectUrl: string
    clientId: string
    responseType: string
    scopes: string
}

export default AuthenticationConfigModel

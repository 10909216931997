import React, { Fragment } from 'react'

export default () => (
    <Fragment>
        <h2>Terms of Service</h2>
        <p>
            Our website uses cookies. Without the use of cookies, the
            registration process and website will not function properly. By
            continuing with the registration process and confirming your email
            address, you accept the use of cookies. If you do not accept the use
            of cookies, please do not confirm your email address and do not
            complete the registration process. For more information on our
            policies with respect to cookies, please review our{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-eula/"
              rel="noopener noreferrer"
              target="_blank">
                EVE Online End User License Agreement
            </a>{' '}
            and{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-privacy-policy/"
              rel="noopener noreferrer"
              target="_blank">
                Privacy Policy
            </a>
            .
        </p>
        <p>
            By continuing, I confirm that I have read and agree to the{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-eula/"
              rel="noopener noreferrer"
              target="_blank">
                EVE Online End User License Agreement
            </a>
            ,{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-tos/"
              rel="noopener noreferrer"
              target="_blank">
                Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://community.eveonline.com/support/policies/eve-privacy-policy/"
              rel="noopener noreferrer"
              target="_blank">
                Privacy Policy
            </a>
            . I also agree that CCP may use my e-mail address to verify my
            purchases, provide me with customer support, send technical and
            feature updates for EVE Online, and for other account-related
            communications.
        </p>
    </Fragment>
)

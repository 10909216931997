import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Icon } from 'layouts'
import { StyledLinkButton, StyledAnchorButton, Text, Platform } from './style'

const Button = ({
    children,
    path,
    internal,
    theme,
    size,
    border,
    custom,
    showPlatform,
    ...rest
}) => (
    <ThemeProvider theme={{ mode: theme, size }}>
        {internal ? (
            <StyledLinkButton
              path={{ ...path }}
              border={border}
              custom={custom}
              {...rest}
            >
                <Text>{children}</Text>
                {showPlatform && (
                    <Platform>
                        <Icon brand name="windows" />
                        <Icon brand name="apple" />
                    </Platform>
                )}
            </StyledLinkButton>
        ) : (
            <StyledAnchorButton
              href={path}
              border={border}
              custom={custom}
              {...rest}
            >
                <Text>{children}</Text>
                {showPlatform && (
                    <Platform>
                        <Icon brand name="windows" />
                        <Icon brand name="apple" />
                    </Platform>
                )}
            </StyledAnchorButton>
        )}
    </ThemeProvider>
)

Button.propTypes = {
    children: PropTypes.node,
    path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    internal: PropTypes.bool,
    theme: PropTypes.string,
    size: PropTypes.oneOf(['small', 'regular', 'large']),
    border: PropTypes.number,
    custom: PropTypes.objectOf(PropTypes.string),
    showPlatform: PropTypes.bool
}

Button.defaultProps = {
    theme: 'primary',
    size: 'regular',
    border: 0,
    showPlatform: false
}

export default Button

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'features'
import style from './LinkArrow.scss'

const Arrow = ({ children }) => (
    <div className={style.inner}>
        <div className={style(style.arrow, style.left)}>
            <div className={style.shaft} />
        </div>
        <div className={style.main}>
            <span className={style.text}>{children}</span>
            <div className={style(style.arrow, style.right)}>
                <div className={style.shaft} />
            </div>
        </div>
    </div>
)

const LinkArrow = ({ children, path, className }) => {
    const isLink = typeof path !== 'undefined'
    const isExternal =
        isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(path || '')

    if (isExternal) {
        return (
            <a
              className={style(style.link, className)}
              target="_blank"
              rel="noopener noreferrer"
              href={path}
            >
                <Arrow>{children}</Arrow>
            </a>
        )
    }

    return (
        <Link path={path} className={style(style.link, className)}>
            <Arrow>{children}</Arrow>
        </Link>
    )
}

LinkArrow.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
}

export default LinkArrow

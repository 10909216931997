import {
    FETCH_PAGE,
    FETCH_PAGE_START,
    FETCH_PAGE_HOME,
    FETCH_PAGE_HOME_START,
    FETCH_PAGE_PLAYER_RESOURCES,
    FETCH_PAGE_PLAYER_RESOURCES_START,
    FETCH_PAGE_LIVE_EVENT,
    FETCH_PAGE_LIVE_EVENT_START,
    FETCH_PAGE_WORLD_TOUR,
    FETCH_PAGE_WORLD_TOUR_START,
    FETCH_PAGE_ARTICLES,
    FETCH_PAGE_ARTICLES_START,
    FETCH_PAGE_ARTICLES_SUCCESSFUL,
    FETCH_PAGE_ARTICLES_CATEGORY,
    FETCH_PAGE_ARTICLES_CATEGORY_START,
    FETCH_PAGE_ARTICLE,
    FETCH_PAGE_ARTICLE_START,
    FETCH_DYNAMIC_PAGE,
    FETCH_DYNAMIC_PAGE_START,
    FETCH_SHARED_CONTENT,
    FETCH_SHARED_CONTENT_START,
    FETCH_PAGE_COMMUNITY,
    FETCH_ACCOUNT_DATA,
    FETCH_ACCOUNT_DATA_START,
    FETCH_ACCOUNT_DATA_SUCCESSFUL,
    UPDATE_ACCOUNT_DATA_AGREEMENT,
    TOGGLE_ACCOUNT_MENU,
} from './actions'

import { LANGUAGE_CHANGE } from '../language/actions'

const INITIAL_STATE = {
    home: {
        isFetching: false,
        hasContent: false,
    },
    // pages: {
    //     hasAnyContent: false,
    // },
    // isFetching: false,
    // hasContent: false,
    // content: {},
}

// const HAS_ANY_CONTENT = {
//     pages: {
//         hasAnyContent: true,
//     },
// }

function pages(state = INITIAL_STATE, action) {
    // console.log('INITIAL_STATE', INITIAL_STATE)
    // console.log('ACTION', action)

    switch (action.type) {
        case FETCH_PAGE_START: {
            return {
                ...state,
                [action.id]: undefined,
            }
        }

        case FETCH_PAGE: {
            return {
                ...state,
                [action.id]: action.payload.page,
            }
        }

        case LANGUAGE_CHANGE: {
            return { home: { hasContent: false } }
        }

        case FETCH_DYNAMIC_PAGE: {
            return {
                ...state,
                dynamic: action.payload.page,
            }
        }
        case FETCH_DYNAMIC_PAGE_START: {
            return {
                ...state,
                dynamic: undefined,
            }
        }
        case FETCH_SHARED_CONTENT: {
            return {
                ...state,
                shared: action.payload.shared,
                // shared: {
                //     ...state.shared,
                //     nav: action.payload.shared.nav,
                // },
            }
        }
        case FETCH_SHARED_CONTENT_START: {
            return {
                ...state,
                shared: undefined,
            }
        }
        case FETCH_PAGE_HOME: {
            return {
                ...state,
                home: action.payload.home,
            }
        }
        case FETCH_PAGE_HOME_START: {
            return {
                ...state,
                home: {
                    isFetching: true,
                    hasContent: false,
                },
            }
        }

        case FETCH_PAGE_LIVE_EVENT: {
            return {
                ...state,
                liveEventPages: action.payload.liveEventPages,
                // HAS_ANY_CONTENT,
            }
        }
        case FETCH_PAGE_LIVE_EVENT_START: {
            return {
                ...state,
                liveEventPages: undefined,
            }
        }

        case FETCH_PAGE_WORLD_TOUR: {
            return {
                ...state,
                worldTour: action.payload.page,
            }
        }
        case FETCH_PAGE_WORLD_TOUR_START: {
            return {
                ...state,
                worldTour: undefined,
            }
        }
        case FETCH_PAGE_PLAYER_RESOURCES: {
            return {
                ...state,
                playerResources: action.payload.section,
                // HAS_ANY_CONTENT,
            }
        }
        case FETCH_PAGE_PLAYER_RESOURCES_START: {
            return {
                ...state,
                playerResources: undefined,
            }
        }
        case FETCH_PAGE_COMMUNITY: {
            return {
                ...state,
                community: action.payload.community,
            }
        }
        case FETCH_PAGE_ARTICLE_START: {
            return {
                ...state,
                hasContent: false,
                isFetching: false,
                article: undefined,
            }
        }
        case FETCH_PAGE_ARTICLE: {
            // console.log('REDUCER FETCH_PAGE_ARTICLE')
            return {
                ...state,
                article: action.payload.article,
            }
        }
        case FETCH_PAGE_ARTICLES: {
            return {
                ...state,
                articles: action.payload.articles,
            }
        }
        case FETCH_PAGE_ARTICLES_START: {
            return {
                ...state,
                articles: undefined,
            }
        }
        case FETCH_PAGE_ARTICLES_CATEGORY_START: {
            return {
                ...state,
                articlesCategory: undefined,
            }
        }
        case FETCH_PAGE_ARTICLES_CATEGORY: {
            return {
                ...state,
                articlesCategory: action.payload,
            }
        }
        case FETCH_PAGE_ARTICLES_SUCCESSFUL: {
            return {
                ...state,
            }
        }
        case FETCH_ACCOUNT_DATA: {
            return {
                ...state,
                account: action.payload.account,
            }
        }
        case FETCH_ACCOUNT_DATA_START: {
            return {
                ...state,
                account: undefined,
            }
        }
        case FETCH_ACCOUNT_DATA_SUCCESSFUL: {
            return state
        }
        case UPDATE_ACCOUNT_DATA_AGREEMENT: {
            return {
                ...state,
                account: {
                    ...state.account,
                    agreementStatus: {
                        ...state.account.agreementStatus,
                        eula: {
                            ...state.account.agreementStatus.eula,
                            hasAgreed: action.payload,
                        },
                    },
                },
            }
        }
        case TOGGLE_ACCOUNT_MENU: {
            return {
                ...state,
                accountMenuOpen: action.payload.open,
            }
        }

        default:
            return state
    }
}

export default pages

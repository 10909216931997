import React from 'react'
import { useSelector } from 'react-redux'
import PageWithSeo from 'features/page-with-seo'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import Download from './components/download'

export default function DownloadContainer() {
    const slug = 'download-eve-online'
    const language = useSelector(state => state.language)

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {page => {
                let hasContent = false
                if (page) {
                    hasContent = true

                    const { body, sectionsCollection } = page

                    return (
                        <PageWithSeo page={page} locale={language} hideSitename>
                            <Download
                              title={page.siteName}
                              body={body}
                              sections={sectionsCollection.items}
                              language={language}
                              hasContent={hasContent}
                            />
                        </PageWithSeo>
                    )
                }

                return <h1>Not Found</h1>
            }}
        </PageBySlugQuery>
    )
}

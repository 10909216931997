import React from 'react'
import { PropTypes } from 'prop-types'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import config from 'config/web/config'
import { isServer } from 'config/web/configHelper'
import Logger from 'utils/logging'
import { Loading } from '../layouts'
// import RoadmapLoading from '../components/roadmap/components/roadmap-shared/components/loading'

const ARTICLE_BY_SLUG_QUERY = gql`
    query ArticleCollection($slug: String, $locale: String, $preview: Boolean) {
        articleCollection(
            limit: 1
            where: { slug: $slug }
            locale: $locale
            preview: $preview
        ) {
            items {
                sys {
                    publishedAt
                    firstPublishedAt
                }
                title
                slug
                content
                category
                author
                publishingDate
                video
                metaDescription
                metaImageUrl {
                    url
                }
            }
        }
    }
`
const ARTICLE_BY_SLUG_QUERY_WITH_TAGS = gql`
    query ArticleCollection($slug: String, $locale: String, $preview: Boolean) {
        articleCollection(
            limit: 1
            where: { slug: $slug }
            locale: $locale
            preview: $preview
        ) {
            items {
                sys {
                    publishedAt
                    firstPublishedAt
                }
                title
                slug
                tags
                content
                category
                author
                publishingDate
                video
                metaDescription
                metaImageUrl {
                    url
                }
            }
        }
    }
`

function ArticleBySlugQuery({ slug, locale, children }) {
    const preview = config.contentful.preview
    let fetchPolicy = isServer ? 'network-only' : 'cache-first'
    if (preview) fetchPolicy = 'no-cache' // Disable cache in preview mode.
    let isContentfulEnvironmentWithTags = false
    if (config.contentful.environment === 'tags')
        isContentfulEnvironmentWithTags = true
    return (
        <Query
          query={isContentfulEnvironmentWithTags ? ARTICLE_BY_SLUG_QUERY_WITH_TAGS : ARTICLE_BY_SLUG_QUERY}
          fetchPolicy={fetchPolicy}
          variables={{ slug, locale, preview }}
        >
            {({ loading, error, data }) => {
                if (loading) return <Loading />
                if (error) {
                    Logger.captureException(error)
                    return ''
                }
                return children(data.articleCollection.items[0])
            }}
        </Query>
    )
}

ArticleBySlugQuery.propTypes = {
    slug: PropTypes.string,
    locale: PropTypes.oneOf(['en', 'de', 'fr', 'ru', 'ja']),
    children: PropTypes.any //eslint-disable-line
}

export default ArticleBySlugQuery
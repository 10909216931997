import React from "react"
import ProtoManager from 'utils/proto'
// import { setValues } from 'utils/proto/ProtoValuesStorage'

export default function TestProtoEvents() {
    console.log("doing stuff with ProtoManager")

    console.log('setting session storage values here to see how it works.')
    // setValues()

    function processClick() {
        const username = 'ingvih'
        const hashedUserId = '123test'
        ProtoManager.publishSignupEvent(username, hashedUserId)
        // ProtoManager.publishHelloWorldEvent()
    }

    return (
        <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1>TESTING ProtoManager</h1>
            <button onClick={() => processClick()}>CLICK ME!</button>
        </div>
    )
}
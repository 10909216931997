import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
// import _isEmpty from 'lodash/isEmpty'
// import moment from 'moment'
import { Section, Container, Row, Column, Border } from 'layouts'
import { SEO, FixedImage } from 'features'
import Evergreen from './Evergreen'
import SectionIntro from './components/intro'
import Story from './components/story'
import { flags } from '../../../../config'
import style from './LiveEvent.scss'

const LiveEvent = (props) => {
    const {
        pageEverGreen,
        pageEverGreenSectionsIntro,
        pageEverGreenSectionsPastEvents,
        pageEverGreenSectionsParticipate,

        pageLiveEvent,
        pageLiveEventSectionsIntro,
        pageLiveEventSectionsStory,
        pageLiveEventParticipate,
        showLiveEvent,
        hasContent,
    } = props

    function renderActiveEvent(bg, intro, story, participate) {
        const bgImgStyle = {
            backgroundImage: `url(${bg})`,
        }

        return (
            <div className={style.live_event} style={bgImgStyle}>
                <Section hasContent={hasContent}>
                    <Container>
                        <SEO
                          title={pageLiveEvent.metaTitle}
                          description={pageLiveEvent.metaDescription}
                          image={pageLiveEvent.metaImage}
                        />
                        <SectionIntro
                            //   style={style}
                          title={intro.name}
                          subTitle={intro.headline}
                          content={intro.body}
                          eventImage={intro.imageFile}
                          buttonText={intro.buttonText}
                          buttonUrl={intro.buttonUrl}
                        />

                        {story && (
                            <Story
                              name={story.name}
                              eventEnds={new Date(story.date)}
                              image={story.imageFile}
                              body={story.body}
                            />
                        )}
                        {participate && (
                            <Row className={style.participate}>
                                <Column md={7} xs={12}>
                                    <h3 className={style.heading}>{participate.name}</h3>
                                    <Border transparent className={style.border} />
                                    <ReactMarkdown source={participate.body} escapeHtml={false} />
                                </Column>
                                <Column md={5} xs={12}>
                                    <FixedImage url={participate.imageFile} size="small" />
                                </Column>
                            </Row>
                        )}
                    </Container>
                </Section>
            </div>
        )
    }

    // if there is a active live Event going on we show that,
    // otherwise we show the Evergreen component

    if (showLiveEvent || flags.isDevelopment) {
        return renderActiveEvent(
            pageLiveEvent.pageBackground,
            pageLiveEventSectionsIntro,
            pageLiveEventSectionsStory,
            pageLiveEventParticipate,
        )
    }

    return (
        <Evergreen
          pageEverGreen={pageEverGreen}
          pageEverGreenSectionsIntro={pageEverGreenSectionsIntro}
          pageEverGreenSectionsPastEvents={pageEverGreenSectionsPastEvents}
          pageEverGreenSectionsParticipate={pageEverGreenSectionsParticipate}
          hasContent={hasContent}
        />
    )
}

LiveEvent.propTypes = {
    pageLiveEvent: PropTypes.any, // eslint-disable-line
    pageLiveEventSectionsIntro: PropTypes.any, // eslint-disable-line
    pageLiveEventSectionsStory: PropTypes.any, // eslint-disable-line
    pageLiveEventParticipate: PropTypes.any, // eslint-disable-line
    pageEverGreenSectionsIntro: PropTypes.any, // eslint-disable-line
    pageEverGreenSectionsPastEvents: PropTypes.any, // eslint-disable-line
    pageEverGreenSectionsParticipate: PropTypes.any, // eslint-disable-line
    pageLiveEvent: PropTypes.any, // eslint-disable-line
    pageEverGreen: PropTypes.any, // eslint-disable-line
    hasContent: PropTypes.bool, // eslint-disable-line
    showLiveEvent: PropTypes.bool,
}

export default LiveEvent

import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import {
    Section,
    Container,
    Row,
    Column,
    AlertMessage,
    Visibility,
} from 'layouts'
import DownloadButton from '../../../../features/DownloadButton'
import SystemRequirements from './SysReq'
import style from './Download.scss'

const Download = props => {
    const { title, body, sections, language, hasContent } = props

    return (
        <div className={style.download}>
            <Section hasContent={hasContent}>
                <Container>
                    <Row>
                        <Column xs={12} md={8}>
                            <h2>{title}</h2>
                            <ReactMarkdown source={body} escapeHtml={false} />
                        </Column>
                        <Column xs={12} md={4}>
                            <DownloadButton />
                        </Column>
                    </Row>
                    <SystemRequirements
                      sections={sections}
                      language={language}
                    />
                </Container>
            </Section>
        </div>
    )
}

Download.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.object),
    language: PropTypes.string,
    hasContent: PropTypes.bool,
}

export default Download

import React from 'react'
import { PropTypes } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
// import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import config from 'config/web/config'
import Error from 'pages/error'
import { isServer } from 'config/web/configHelper'
import Logger from 'utils/logging'
import { Loading } from '../layouts'

const PAGE_BY_SLUG_QUERY = gql`
    query PageCollection($slug: String, $locale: String, $preview: Boolean) {
        pageCollection(
            limit: 1
            where: { slug: $slug }
            locale: $locale
            preview: $preview
        ) {
            items {
                siteName
                slug
                metaTitle
                metaDescription
                metaImage {
                    url
                }
                pageBackground {
                    url
                }
                body
                config
                sectionsCollection(limit: 20) {
                    total
                    items {
                        name
                        headline
                        teaser
                        body
                        buttonText
                        buttonUrl
                        template
                        videoId
                        backgroundImage
                        imageFile {
                            url,
                            description
                        }
                        theme
                        identifier
                        contentCollection(limit: 20) {
                            total
                            items {
                                ... on Content {
                                    name
                                    headline
                                    body
                                    buttonText
                                    buttonUrl
                                    buttonTheme
                                    date
                                    imageFile {
                                        url,
                                        description
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

function PageBySlugQuery({ slug, locale, children }) {
    // console.log('INSIDE: PageBySlugQuery')
    // Old way, works better with ssr
    // const preview = config.contentful.preview
    // let fetchPolicy = isServer ? 'network-only' : 'cache-first'
    // if (preview) fetchPolicy = 'no-cache' // Disable cache in preview mode.
    // return (
    //     <Query
    //       query={PAGE_BY_SLUG_QUERY}
    //       fetchPolicy="no-cache"
    //       variables={{ slug, locale, preview }}
    //     >
    //         {({ loading, error, data }) => {
    //             // console.log('loading:', loading)
    //             // console.log('error:', error)
    //             // console.log('data:', data)
    //             console.log('loading__', loading)

    //             if (loading) return <Loading />
    //             if (error) {
    //                 Logger.captureException(error)
    //                 return ''
    //             }
    //             return children(data.pageCollection.items[0])
    //         }}
    //     </Query>
    // )

    // // New way, uses hooks but ssr meta stuff does not work.
    const preview = config.contentful.preview
    // console.log('preview', preview)

    const fetchPolicy = isServer ? 'cache-and-network' : 'cache-first'
    // const fetchPolicy = 'network-only'
    // if (preview) fetchPolicy = 'no-cache' // Disable cache in preview mode.
    // console.log('fetch policy:', fetchPolicy)

    const { loading, error, data, networkStatus } = useQuery(
        PAGE_BY_SLUG_QUERY,
        {
            variables: { slug, locale, preview },
            fetchPolicy,
            errorPolicy: 'ignore'
        }
    )

    // console.log('loading:', loading)
    // console.log('error:', error)
    // console.log('data:', data)
    // console.log('networkStatus:', networkStatus)

    if (loading) return <Loading />
    if (error) {
        Logger.captureException(error)
        return <Error />
    }

    return <div>{children(data.pageCollection.items[0])}</div>
}

PageBySlugQuery.propTypes = {
    slug: PropTypes.string,
    locale: PropTypes.oneOf(['en', 'de', 'fr', 'ru', 'ja']),
    children: PropTypes.any //eslint-disable-line
}

export default PageBySlugQuery

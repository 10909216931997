import _isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import Explore from './components/explore'

function mapStateToProps(props, ownProps) {
    let data = {}

    if (!_isEmpty(ownProps.section)) {
        data = ownProps.section
    }

    let { headline, body, img, videoId } = ''

    if (data) {
        headline = data.headline
        body = data.body
        img = data.imageFile
        videoId = data.videoId
    }
    return {
        headline,
        body,
        img,
        videoId,
    }
}


export default connect(mapStateToProps, null)(Explore)

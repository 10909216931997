import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import style from './DateAndAuthor.scss'

function DateAndAuthor({ className, date, author, suffix }) {
    if (date === undefined) return null

    return (
        <span className={style(style.author_date, className)}>
            {moment(date).format('YYYY-MM-DD')}
            { author && (
                <span> - By {author} {suffix}</span>
            )}
        </span>
    )
}

DateAndAuthor.propTypes = {
    className: PropTypes.string,
    date: PropTypes.string, // eslint-disable-line
    author: PropTypes.string,
    suffix: PropTypes.string,
}

export default DateAndAuthor

import React from 'react'
import { SEO } from 'features'
import Loading from 'layouts/loading/PageLoading'
import getMetadata from 'selectors/metadata'

export default function PageWithSeo({
    children,
    page,
    showLoading,
    hideSitename = false,
}) {
    const { title, description, image, robots } = getMetadata(page)

    return (
        <>
            <SEO
              title={title}
              description={description}
              image={image}
              hideSitename={hideSitename}
              robots={robots}
            />
            {showLoading ? <Loading /> : <>{children}</>}
        </>
    )
}

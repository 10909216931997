class ContentViewModel {
    constructor(content) {
        if (content) {
            this.name = content.name
            this.headline = content.headline
            this.body = content.body
            this.image = content.image
            this.imageFile = content.imageFile
            this.buttonText = content.buttonText
            this.buttonUrl = content.buttonUrl
            this.date = content.date
            this.buttonTheme = content.buttonTheme
        }
    }
}

export default ContentViewModel

import React, { useState } from 'react'
import {
    Formik,
    FormikConsumer,
    FormikErrors,
    Field,
    Form,
    ErrorMessage
} from 'formik'
import _isEmpty from 'lodash/isEmpty'
import cx from 'classnames'
import { Translate } from 'react-localize-redux'
import ReCaptcha from 'react-google-recaptcha'
import { ClipLoader } from 'react-spinners'
import ReactMarkdown from 'react-markdown'
import { isClient } from 'config/web/configHelper'
import { flags } from 'config'
import { Button, Input2, Checkbox, Icon, AlertMessage } from 'layouts'
import { Modal, useModal } from 'layouts/modal'
import { HeadingXSmall, TaglineSmall, ParagraphRegular } from 'layouts/typography'

import signupValidation from './signupValidation'
import SignupFormProps from './SignupFormProps'
import TOS from './components/tos'
import style from './SignupForm.module.scss'

const validation = []

async function validateInput(key, value, validationFunction, message) {
    const errorMessage = await validationFunction(value, message).then(data => {
        validation[key] = true
        return data
    })
    if (errorMessage) {
        validation[key] = false
        return errorMessage
    } else {
        validation[key] = true
        return ''
    }
}

let formFields
let formActions
function setFormProps(fields: any, actions: any) {
    formFields = fields
    formActions = actions
}

interface Window {
    recaptchaOptions: {
        useRecaptchaNet: boolean;
    }
}
declare var window: Window;

const SignupForm = ({
    handleSubmit,
    validateEmail,
    validateUsername,
    validatePassword,
    theme = 'dark',
}: SignupFormProps) => {
    const recaptchaRef = React.createRef<ReCaptcha>()
    const [reCaptchaValue, setCaptchaValue] = useState(null)
    const [showPassword, toggleShowPassword] = useState(false)
    const [isUpSell, setUpsellValue] = useState(false)
    const { isOpen, toggleModal } = useModal()

    // Use recaptcha.net. google.com may not be accessible in all locations
    // https://developers.google.com/recaptcha/docs/faq#can-i-use-recaptcha-globally
    if (isClient) {
        window.recaptchaOptions = { useRecaptchaNet: true, }
    }

    return (
        <Translate>
            {({ translate }) => (
                <Formik
                  initialValues={{
                        email: '',
                        username: '',
                        password: '',
                        agreedTerms: false,
                        // recaptcha: null,
                        global: ''
                    }}
                  validateOnChange={false}
                  validateOnBlur
                  validationSchema={signupValidation(translate)}
                  onSubmit={(fields, actions) => {
                        // Storing fields and actions to be used after recaptcha has executed.
                        setFormProps(fields, actions)

                        // Reset first to make sure we have fresh value, can happen when there is an error in form and it was submitted to server and recaptcha value used.
                        recaptchaRef.current.reset()

                        // Calling recaptcha, that will call handleSubmit after it gets captcha value.
                        recaptchaRef.current.execute()
                    }}
                >
                    {({
                        values,
                        errors,
                        status,
                        touched,
                        handleBlur,
                        handleChange,
                        validateField,
                        validateForm,
                        setFieldValue,
                        setFieldError,
                        setFieldTouched,
                        submitForm,
                        setSubmitting,
                        isSubmitting,
                        isValidating,
                        isValid,
                        dirty
                    }) => (
                        <Form className={style(style.signup, style[theme])}>
                            {errors.global && (
                                <div className={style.globalError}>
                                    <AlertMessage error>
                                        {errors.global}
                                    </AlertMessage>
                                </div>
                            )}
                            <div className={style.formGroup}>
                                <Field
                                  id="signup-email"
                                  name="email"
                                  autoFocus
                                  isFormDirty={dirty}
                                  labelText={translate('signup.email')}
                                  type="email"
                                  disabled={isSubmitting}
                                  isServerValidated={validation.email}
                                  component={Input2}
                                  setFieldValue={setFieldValue}
                                  setFieldError={setFieldError}
                                    //   onChange={handleChange}
                                  validate={(value: string) => validateInput(
                                            'email',
                                            value,
                                            validateEmail,
                                            `${translate(
                                                'signup.validation.emailInvalid'
                                            )}`
                                        )
                                    }
                                />
                            </div>
                            <div className={style.formGroup}>
                                <Field
                                  id="signup-username"
                                  name="username"
                                  isFormDirty={dirty}
                                  labelText={translate('signup.username')}
                                  type="text"
                                  disabled={isSubmitting}
                                  isServerValidated={validation.username}
                                  component={Input2}
                                  setFieldError={setFieldError}
                                  validate={(value: string) => validateInput(
                                            'username',
                                            value,
                                            validateUsername,
                                            `${translate(
                                                'signup.validation.usernameUnavailable'
                                            )}`
                                        )
                                    }
                                />
                            </div>
                            <div className={style.formGroup}>
                                <div
                                  id={
                                        showPassword
                                            ? 'hide-password'
                                            : 'show-password'
                                    }
                                  data-id="show/hide"
                                  role="button"
                                  className={style.showHidePass}
                                  tabIndex={-1}
                                  title={
                                        showPassword
                                            ? `${translate(
                                                  'signup.passwordHide'
                                              )}`
                                            : `${translate(
                                                  'signup.passwordShow'
                                              )}`
                                    }
                                  onClick={() => {
                                        toggleShowPassword(!showPassword)
                                    }}
                                >
                                    <Icon
                                      name={
                                            showPassword ? 'eye-slash' : 'eye'
                                        }
                                    />
                                </div>
                                <Field
                                  id="signup-password"
                                  name="password"
                                  isFormDirty={dirty}
                                  type={showPassword ? 'text' : 'password'}
                                  disabled={isSubmitting}
                                  isServerValidated
                                  labelText={translate('signup.password')}
                                  component={Input2}
                                  setFieldError={setFieldError}
                                    //   validate={(value: string) => validatePassword(value)}
                                />
                            </div>
                            <div
                              className={cx(
                                    style.checkbox,
                                    style.formGroup,
                                    style.slim
                                )}
                            >
                                <Field
                                  id="agree-terms"
                                  type="checkbox"
                                  disabled={isSubmitting}
                                  name="agreedTerms"
                                  checked={values.agreedTerms}
                                  component={Checkbox}
                                >
                                    <span className={style.terms}>
                                        {translate('signup.iAgreeTo')}{' '}
                                        <span
                                          tabIndex={0}
                                          role="button"
                                          onClick={toggleModal}
                                          className={style.terms__button}
                                        >
                                            {translate('signup.termsOfService')}{' '}
                                        </span>
                                        {translate('signup.iAgreeToAfter')}{' '}
                                    </span>
                                </Field>
                            </div>
                            <div className={style.recaptchaBadge}>
                                <ReCaptcha
                                  ref={recaptchaRef}
                                  size="invisible"
                                  sitekey="6LeOEbkUAAAAAN6MuQlva4QFH4nIdWXoOZUa0G09"
                                  onExpired={(): void => {}} // Returning empty because by default it will call the onChange with null to signify expired callback.
                                  onChange={value => {
                                      console.log('RECAPTCHA CHANGE____', value)
                                        // If user gets the challenge then all form values are ready and when he gets it right,
                                        // this executes, sets the code value and submits the form.
                                        // Set the recaptcha value in form to the code we get.
                                        // setFieldValue('recaptcha', value)
                                        setCaptchaValue(value)
                                        // Finally submit form
                                        // submitForm()

                                        // console.log('VALUE: ', value)
                                        handleSubmit(
                                            formFields,
                                            formActions,
                                            value
                                            // isUpSell
                                        )
                                    }}
                                />
                            </div>
                            <div
                              id="signup_extra"
                              className={cx(style.formGroup, style.extra)}
                            >
                                {/* <img
                                  src="//images.ctfassets.net/7lhcm73ukv5p/7Jw28Y0eAI0CcVIkeTHrdD/ccff904975d2bbbc787a815de7d7528a/sp_price.jpg"
                                  alt="starter pack"
                                /> */}
                            </div>
                            <div className={cx(style.formGroup, style.slim)}>
                                <Button
                                  as="button"
                                  theme={theme === 'quadrant' ? 'quadrant' : 'primary'}
                                //   onClick={() => {
                                //     setUpsellValue(false)
                                //   }}
                                  size="regular"
                                  type="submit"
                                  disabled={isSubmitting}
                                  className={style.submit}
                                >
                                    {isSubmitting ? (
                                        <ClipLoader
                                          size={30}
                                          color={'#2a80b9'}
                                          loading
                                        />
                                    ) : (
                                        <>
                                            {translate(
                                                'signup.startPlayingForFree'
                                            )}
                                        </>
                                    )}
                                </Button>
                            </div>
                            <div id="signup_to_login">
                                <TaglineSmall
                                  className={
                                    style.login
                                  }
                                >
                                    {translate(
                                        'signup.alreadyHaveAccount'
                                    )}{' '}
                                    <a href="https://login.eveonline.com/account/logon?ReturnUrl=%2Foauth%2Fauthorize%2F%3Fresponse_type%3Dcode%26client_id%3Daccount_management%26scope%3Dbuddyservice.user.v1%2520mammonservice.secureAccess.v1%2520sso.writeTwoFactor.v1%26redirect_uri%3Dhttps%253A%252F%252Fsecure.eveonline.com%252FSSOLogin%252Fssocallback.aspx">
                                        {translate(
                                            'signup.login'
                                        )}
                                    </a>
                                </TaglineSmall>
                            </div>

                            <div className={style.recaptchaText}>
                                <ReactMarkdown
                                  escapeHtml
                                  source={translate('signup.googleCaptchaText')}
                                  renderers={{
                                        paragraph: ({ children }) => <>{children}</>,
                                        link: ({ children, href }) => (
                                            <a href={href} target='blank'>
                                                {children}
                                            </a>
                                        ),
                                    }}
                                />
                            </div>
                            {/* {flags.isDevelopment && (
                                <FormikConsumer>
                                    {({
                                        validationSchema,
                                        validate,
                                        // onSubmit,
                                        ...rest
                                    }) => (
                                        <pre
                                          style={{
                                                fontSize: '.85rem',
                                                padding: '.25rem .5rem',
                                                // overflowX: 'scroll',
                                            }}
                                        >
                                            {JSON.stringify(rest, null, 2)}
                                        </pre>
                                    )}
                                </FormikConsumer>
                            )} */}
                            {/* <div className={style.upSell}>
                                <HeadingXSmall>{translate('signup.upsell.title')}</HeadingXSmall>
                                <div className={style.ctaContainer}>
                                    <div className={style.cta}>
                                        <p>{translate('signup.upsell.product')}</p>
                                        <Button
                                          as="button"
                                          onClick={() => {
                                            setUpsellValue(true)
                                          }}
                                          size="small"
                                          type="submit"
                                          disabled={isSubmitting}
                                          className={style.submit}
                                        >
                                            {isSubmitting ? (
                                                <ClipLoader
                                                size={30}
                                                color={'#2a80b9'}
                                                loading
                                                />
                                            ) : (
                                                <>
                                                    {translate(
                                                        'signup.upsell.buttonText'
                                                    )}
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                                <div className={style.benefits}>
                                    <ParagraphRegular>{translate('signup.upsell.subTitle')}</ParagraphRegular>
                                    <ul>
                                        <li>{translate('signup.upsell.benefit1')}</li>
                                        <li>{translate('signup.upsell.benefit2')}</li>
                                        <li>{translate('signup.upsell.benefit3')}</li>
                                    </ul>
                                </div>
                            </div> */}
                            <Modal isOpen={isOpen} hide={toggleModal}>
                                <TOS />
                            </Modal>
                        </Form>
                    )}
                </Formik>
            )}
        </Translate>
    )
}

export default SignupForm

import React from 'react'
import _map from 'lodash/map'
import ReactMarkdown from 'react-markdown'
import SectionType from 'models/types/ts/sectionType'
import BackgroundImage from 'features/background-image'
import AdGlare from 'features/ad-glare'
import ArticlesQuery from 'queries/ArticlesQuery'
import { HeadingLarge, TaglineLarge, HeadingRegular } from 'layouts/typography'
import { Section, Container, Button, Frame, Heading } from 'layouts'
import dummyAd from 'static/img/adglare-410.jpg'
// import Slider from './slider'
import Hero from './hero'
import Tags from './tags'
import PatchNotesLatest from './patch-notes-latest'
import FeaturedPosts from './featured-posts'
import Promo from './promo'
import { Card, CardFeatured } from './cards'
import style from './NewsPage.module.scss'

interface Props {
    hero: SectionType,
    promo: SectionType,
    language: string,
    background: string,
    hasContent: boolean
}

export default function NewsPage({ hero, promo, language, background, hasContent }: Props) {

    return (
        <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
            {hero && <Hero section={hero} />}
            <Container>
                <ArticlesQuery locale={language} limit={12} categoryToExclude="patch-notes">
                    {(newsList) => {
                        // console.log('news list', newsList)
                        return (
                            <>
                                <Tags />
                                <PatchNotesLatest language={language} />
                                <div className={style(style.grid, style.grid2_1)}>
                                    <CardFeatured newsItem={newsList[0]} />
                                    {/* <AdGlare /> */}
                                    <img src={dummyAd} alt="" />
                                </div>
                                <div className={style(style.grid, style.grid3)}>
                                    <Card filled newsItem={newsList[1]} />
                                    <Card filled newsItem={newsList[2]} />
                                    <Card filled newsItem={newsList[3]} />
                                </div>
                                <div className={style(style.grid, style.grid2)}>
                                    <Card newsItem={newsList[4]} />
                                    <Card newsItem={newsList[5]} />
                                </div>
                                <div className={style(style.grid, style.grid2_1)}>
                                    <Card newsItem={newsList[6]} />
                                    <FeaturedPosts language={language} />
                                </div>
                                <div className={style(style.grid, style.grid2)}>
                                    <Card newsItem={newsList[7]} />
                                    <Card newsItem={newsList[8]} />
                                </div>
                                <div className={style(style.grid, style.grid3)}>
                                    <Card newsItem={newsList[9]} />
                                    <Card newsItem={newsList[10]} />
                                    <Card newsItem={newsList[11]} />
                                </div>   
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridColumnGap: '30px' , marginTop: '40px' }}>
                                    {/* <Button
                                      style={{ gridColumnStart: 2 }}
                                      path="#"
                                      border={1}
                                      size="small"
                                      theme="secondary"
                                    >  
                                        View more news
                                    </Button> */}
                                </div>
                            </>
                        )
                    }}
                </ArticlesQuery>
            </Container>
            {promo && <Promo section={promo} />}
        </BackgroundImage>
    )
}

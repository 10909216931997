import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Column } from 'layouts/common'
import Icon from 'layouts/font-awesome'
import style from '../PlayerResources.scss'

const ResourceItem = ({ content }) => {
    const { headline, body, image, buttonUrl, buttonText } = content

    return (
        <Column xs={12} sm={6} md={4} className={style.resource}>
            <a href={buttonUrl}>
                <img src={image} alt="" />
            </a>
            <h2>{headline}</h2>
            <ReactMarkdown source={body} />
            <a href={buttonUrl}>
                {buttonText}{' '}
                <span>
                    <Icon name="chevron-right" />
                </span>
            </a>
        </Column>
    )
}

ResourceItem.propTypes = {
    content: PropTypes.objectOf(PropTypes.string),
}

export default ResourceItem

import ContentViewModel from './ContentViewModel'

class SectionViewModel {
    constructor(section) {
        if (section) {
            this.name = section.name
            this.headline = section.headline
            this.teaser = section.teaser
            this.body = section.body
            this.image = section.image
            this.imageFile = section.imageFile
            this.backgroundImage = section.backgroundImage
            this.buttonText = section.buttonText
            this.buttonUrl = section.buttonUrl
            this.theme = section.theme
            this.content = section.content &&
                section.content.map(content => new ContentViewModel(content))
            this.videoId = section.videoId
            this.date = section.date
        }
    }
}

export default SectionViewModel

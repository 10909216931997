import ConfigModel from '../ConfigModel'
import { isLocal, webBaseUrl } from '../configHelper'
import AuthenticationConfigModel from '../../../packages/authentication/models'

let host = isLocal ? 'localhost:3000' : 'dev.ccpeveweb.com'
if (webBaseUrl) host = webBaseUrl

const config = new ConfigModel()

config.monumentSearchApiUrl = 'https://api.ccpgames.com/v1/monument/search/'
config.launcherVersionsUrl =
    'https://launcher.eveonline.com/launcherVersions.json'
config.launcherVersionDownloadUrl =
    'https://binaries.eveonline.com/EveLauncher-'
config.shareFacebook = 'https://www.facebook.com/sharer/sharer.php?u='
config.shareTwitter = 'https://twitter.com/share?url='
config.shareGoogle = 'https://plus.google.com/share?url='
config.shareReddit = 'https://www.reddit.com/submit?url='
config.shareVk = 'https://vk.com/share.php?url='
config.adGlare = {
    small: 'https://engine.extccp.com/?786108110',
    medium: 'https://engine.extccp.com/?380868224', // This is the same AdGlare zone as the launhcer uses
    large: 'https://engine.extccp.com/?526235715',
}
config.languages = ['en', 'fr', 'de', 'ru', 'ja']
config.gtmID = 'GTM-NHK9F3'
config.gaID = 'UA-45583206-1'
config.optimizeID = 'GTM-5SGSLS5' // We only use one Optimize environment at the moment, the ID is also used in index.html (hardcoded)
config.optimizeEndGameExperimentId = 'vhxTZi6VSkOeuqLsJPqS_w'
config.authentication = new AuthenticationConfigModel()
config.authentication.authUrl =
    'https://login-mammonmain.testeveonline.com/v2/oauth/authorize'
config.authentication.tokenUrl =
    'https://login-mammonmain.testeveonline.com/v2/oauth/token'
config.authentication.logoutUrl =
    'https://login-mammonmain.testeveonline.com/account/logoff?returnUrl='
config.authentication.redirectUrl = `https://${host}/callback`
config.authentication.silentRedirectUrl = `https://${host}/silentcallback`
config.authentication.clientId = 'wwwEveOnline' // 'wwwEveOnline'
config.authentication.responseType = 'token'
config.authentication.scopes = 'recruit.signup.v1 cisservice.userProfileBasic.v1 cisservice.writeUserProfileBasic.v1 cisservice.entitlementsRead.v1 cisservice.customerRead.v1 cisservice.customerWrite.v1 loginhistory.loginHistoryRead.v1'
config.sentryDsn = 'https://7e6385dfb18d49b18c01180358777e8d@sentry.io/1484810'
config.contentful = {
    graphqlBaseUrl: 'https://graphql.contentful.com/content/v1/',
    environment: 'master',
    spaceId: '7lhcm73ukv5p',
    accessToken:
        '899953620e835d4ff23098fd81adc7ae2c39c848d59b5698fd129b33725b23a8',
    preview: false,
}
config.playNow = false
// BELOW NOT USED NOW/YET
config.cis = 'https://cis.mammonmain.dev/'
config.loginHistory = 'https://dev.loginhistory-service.kube.ccp-cloud.net/'

export default config

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, SvgIcon } from 'layouts'
import { Link } from 'features'
import styles from '../../account-navigation.scss'

class AccountNavigation extends Component {
    constructor(props) {
        super(props)

        this.dropdownRef = React.createRef()
        this.navRef = React.createRef()
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleClickOutside(event) {
        if (
            this.props.isOpen &&
            this.dropdownRef.current &&
            !this.dropdownRef.current.contains(event.target)
        ) {
            if (
                !this.navRef.current ||
                !this.navRef.current.contains(event.target)
            ) {
                this.props.toggle(false)
            }
        }
    }

    getUser = () => {
        const {
            isLoggedIn,
            isOpen,
            userName,
            omegaStatus,
            toggle,
            color
        } = this.props

        if (isLoggedIn) {
            return (
                <button
                  ref={this.navRef}
                  className={styles.navButton}
                  onClick={() => toggle(!isOpen)}
                >
                    <span className={styles.user}>{userName}</span>
                    <div className={styles.chevron}>
                        <Icon name={`chevron-${isOpen ? 'up' : 'down'}`} />
                    </div>
                    <div className={styles.loggedIn}>
                        <SvgIcon name={'www-user'} fill={color} />
                    </div>
                </button>
            )
        }

        return '<LOGIN>'
    }

    // getUserType = () => {
    //     const { omegaStatus } = this.props
    //     return (
    //         <div className={styles.userType}>
    //             <SvgIcon name={`${omegaStatus.isValid ? 'omega' : 'alpha'}-hexagon-small`} />
    //         </div>
    //     )
    // }

    renderOption = (item, translate) => {
        const ext = (
            <div className={styles.logo}>
                <Icon
                  name="external-link"
                  className={`${styles.icon} ${styles.external}`}
                />
            </div>
        )
        const text = (
            <div key="text" className={styles.text}>
                <div className={styles.title}>
                    {translate(item.translationId)}
                </div>
                {item.external ? ext : null}
            </div>
        )
        const props = {}
        if (item.external) {
            props.target = '_blank'
            props.rel = 'noopener noreferrer'
            props.url = item.url
        } else if (item.page) {
            props.path = { page: item.page, subpage: item.sub }
        }
        return (
            <Link
              onClick={() => this.props.toggle(false)}
              key={item.translationId}
              className={styles.option}
              {...props}
            >
                {text}
            </Link>
        )
    }

    renderOptions = () => {
        const { items, translate, isOpen, userName } = this.props

        const classnames = [styles.list]

        if (isOpen) {
            classnames.push(styles.open)
        }

        const user = <div className={styles.userMobile}>{userName}</div>
        const options = items.map((item) => this.renderOption(item, translate))

        return (
            <div className={classnames.join(' ')} ref={this.dropdownRef}>
                {user}
                {options}
            </div>
        )
    }

    render = () => {
        return (
            <div className={styles.accountNav}>
                <div>
                    {/* {this.getUserType()} */}
                    {this.getUser()}
                    {this.renderOptions()}
                </div>
            </div>
        )
    }
}

AccountNavigation.propTypes = {
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
    isOpen: PropTypes.bool,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            translationId: PropTypes.string,
            url: PropTypes.string,
            external: PropTypes.bool,
        })
    ),
    toggle: PropTypes.func,
    color: PropTypes.string,
    translate: PropTypes.func,
}

export default AccountNavigation

import React from 'react'
import PropTypes from 'prop-types'
import style from './Link.scss'

const Link = ({ children, href, color, onClick = null }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={style.link}
      style={{ color }}
      onClick={onClick}
    >
        {children}
    </a>
)

Link.propTypes = {
    children: PropTypes.node,
    href: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
}

export default Link

import React from 'react'
import PropTypes from 'prop-types'
import style from './FullscreenVideo.scss'

const FullscreenVideo = ({ children, animate, isVisible, ...rest }) => (
    <div
      className={style(style.video, {
            [style.animate]: animate,
            [style.visible]: isVisible,
        })}
    >
        <div className={style.inner}>{children}</div>
    </div>
)

FullscreenVideo.propTypes = {
    children: PropTypes.node,
    animate: PropTypes.bool,
    isVisible: PropTypes.bool,
}

export default FullscreenVideo

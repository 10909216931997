import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import VisibilitySensor from 'react-visibility-sensor'
import SectionType from 'models/types/ts/sectionType'
import { Container, Section } from 'layouts'
import { HeadingSmall, TaglineRegular } from 'layouts/typography'
import Picture from 'features/picture'
import style from './Extra.module.scss'

interface Props {
    section: SectionType
}

const Extra = ({ section }: Props) => {
    const [isVisible, setVisible] = useState(false)

    const handleChange = (visible: boolean) => {
        if (visible) setVisible(true)
    }

 // Being very safe
 const extraCta1 = section && section.content && section.content[0] && section.content[0]
 const extraCta2 = section && section.content && section.content[1] && section.content[1]

return (
    <Container>
        <Section hasContent={section !== undefined}>
            <div className={style.content}>
                <div className={style.top}>
                    <HeadingSmall>{section.headline}</HeadingSmall>
                    <TaglineRegular className={style.tagline}>
                        <ReactMarkdown source= {section.teaser}
                          renderers={{
                            paragraph: ({ children }) => <>{children}</>,
                        }}
                        />
                    </TaglineRegular>
                    <div className={style.companion}>
                        {extraCta1 &&
                        <a href={extraCta1.buttonUrl} rel="noopener noreferrer" target="_blank" data-id="myyear_google_play">
                            <img src={extraCta1.imageFile} alt="Companion App" />
                        </a>
                        }
                        {extraCta2 &&
                        <a href={extraCta2.buttonUrl} rel="noopener noreferrer" target="_blank" data-id="myyear_app_store">
                            <img src={extraCta2.imageFile} alt="Companion App" />
                        </a>
                        }
                    </div>
                </div>
                <div className={style.bottom}>
                    <div className={style.left}>
                        <ReactMarkdown source= {section.body} />
                    </div>
                    <VisibilitySensor
                      onChange={handleChange}
                      offset={{ top: 30 }}
                      partialVisibility
                      delayedCall
                      active={!isVisible}
                    >
                        <div
                          className={style(
                            style.right,
                            isVisible ? style.visible : style.hidden,
                          )}
                        >
                            <Picture
                              widthPercentage={30}
                              src={section.imageFile}
                              alt="Mobile Phone"
                            />
                        </div>
                    </VisibilitySensor>
                </div>
            </div>
        </Section>
    </Container>
)
} 

export default Extra
import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { SelectLanguage } from 'features'
import NavItems from '../navitems'
import style from './MobileMenu.scss'

class MobileMenu extends Component {
    getClassName = open => style('menu', { open, closed: !open })

    handleClick = e => {
        const className = e.target.parentNode.className
        if (
            className === style.social ||
            className === this.getClassName(this.props.open)
        ) {
            return
        }

        this.props.closeMenu()
    }

    renderFooter = () => (
        <div className={style.footer}>
            <SelectLanguage className={style.language} />
        </div>
    )

    render() {
        // console.log('MobileMenu render')
        const className = this.getClassName(this.props.open)
        // console.log('MobileMenu render. className: ', className)
        // The role and onClick are to make sure we close the menu
        // when the user clicks on a link.
        return (
            <div
              role="presentation"
              className={className}
              onClick={e => this.handleClick(e)}
            >
                <NavItems className={style.navigation} />
                {this.renderFooter()}
            </div>
        )
    }
}

MobileMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func,
}

export default MobileMenu

import React from 'react'
import SectionType from 'models/types/ts/sectionType'
import { Container, Section } from 'layouts'
import { HeadingSmall, TaglineRegular } from 'layouts/typography'
import Button from 'layouts/button'
import BackgroundImage from 'features/background-image'
import style from './Cta.module.scss'

interface Props {
    section: SectionType
}

const Cta = ({ section }: Props) => {

    // Being very safe
    const plexCta = section && section.content && section.content[0] && section.content[0]
    const omegaCta = section && section.content && section.content[1] && section.content[1]

    // console.log('plexCta: ', plexCta)
    // console.log('omegaCta: ', omegaCta)

    return (
        <Container>
            <Section hasContent={section !== undefined}>
                <div className={style.content}>
                    <div className={style.headline}>
                        <HeadingSmall className={style.heading}>
                            {section.headline}
                        </HeadingSmall>
                        <TaglineRegular className={style.tagline}>
                            {section.teaser}
                        </TaglineRegular>
                    </div>
                    <div className={style.cta}>
                        {plexCta &&
                        <a href={plexCta.buttonUrl} className={style.link} data-id="myyear_buy_plex">
                            <BackgroundImage
                              url={plexCta.imageFile}
                              repeat="no-repeat"
                              size="cover"
                              position="center top"
                              className={style.background}
                                >
                                <Button
                                  theme="omega"
                                  size="small"
                                  as="button"
                                  className={style.button}
                                >
                                    {plexCta.buttonText}
                                </Button>
                            </BackgroundImage>
                        </a>
                        }
                        {omegaCta &&
                        <a href={omegaCta.buttonUrl} className={style.link} data-id="myyear_buy_omega">
                            <BackgroundImage
                              url={omegaCta.imageFile}
                              repeat="no-repeat"
                              size="cover"
                              position="center top"
                              className={style.background}
                            >
                                <Button
                                  theme="omega"
                                  size="small"
                                  as="button"
                                  className={style.button}
                                >
                                    {omegaCta.buttonText}
                                </Button>
                            </BackgroundImage>
                        </a>
                        }
                    </div>
                </div>
            </Section>
        </Container>
    )

}
export default Cta
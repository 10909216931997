import React, { useState, SyntheticEvent } from 'react'
import { useSelector } from 'react-redux'
import { getLanguage } from 'selectors'
import NewsType from 'models/types/ts/newsType'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import paths from 'config/paths'
import { Container, Icon, Content } from 'layouts'
import style from './SocialShare.module.scss'

interface Props {
    newsItem: NewsType
}

const SocialShare = ({ newsItem }: Props) => {
    const [linkCopied, setLinkCopied] = useState(false)
    const language = useSelector((state) => getLanguage(state))

    const getShareLink = () => {
        let langPrefix = ''
        if (language !== 'en') {
            langPrefix = `/${language}`
        }
        return `${paths.webBaseUrl}${langPrefix}/news/view/${newsItem.slug}`
    }

    const copyToClipBoard = (event: SyntheticEvent) => {
        event.preventDefault()
        copy(getShareLink(), {
            format: 'text/plain',
        })
        setLinkCopied(true)

        setTimeout(() => {
            // if (linkCopied) {
                setLinkCopied(false)
            // }
        }, 2000)
        
    }    

    return (
        <div className={style.socialShare}>
            <div className={style.inner}>
                <a
                  href={paths.shareFacebook + getShareLink()}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={style.facebook}
                >
                    <Icon
                      brand
                      name="facebook-f"
                      className={style.social}
                    />
                </a>
                <a
                  href={paths.shareTwitter + getShareLink()}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={style.twitter}
                >
                    <Icon
                      brand
                      name="twitter"
                      className={style.social}
                    />
                </a>
                <a
                  href={paths.shareVk + getShareLink()}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={style.twitter}
                >
                    <Icon
                      brand
                      name="vk"
                      className={style.social}
                    />
                </a>
                <a
                  href={paths.shareReddit + getShareLink()}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={style.twitter}
                >
                    <Icon
                      brand
                      name="reddit"
                      className={style.social}
                    />
                </a>
            </div>
            <div className={style.copyLink}>
                <a href="#" role="button" onClick={(event) => copyToClipBoard(event) }>
                    <Icon
                      regular
                      name="link"
                      className={style.social}                  
                    />
                </a>               
                <div className={classNames(style.copied, {[style.active]: linkCopied})} >
                    Link copied
                </div>
            </div>
        </div>
    )
}

export default SocialShare

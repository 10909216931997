import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { isClient } from 'config/web/configHelper'
import { Icon } from 'layouts'
import style from './Modal.scss'

if (isClient) {
    const root = document.getElementById('root')
    if (root) {
        ReactModal.setAppElement('#root')
    }
}

const Modal = ({ isOpen, hide, children }) => {
    return (
        <ReactModal
          isOpen={isOpen}
          onRequestClose={hide}
          overlayClassName={style.overlay}
          className={style.modal}
          closeTimeoutMS={300}
        >
            <button onClick={hide} className={style.close}>
                <Icon solid name="times-hexagon" />
            </button>
            {children}
        </ReactModal>
    )
}

export default Modal

Modal.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    hide: PropTypes.func
}

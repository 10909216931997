import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import { isDynamic } from 'lib/pages/selectors'
import DynamicPage from './components/dynamic-page'

export default function ArticlePreview() {
    const location = useSelector(state => state.location)
    let slug = location && location.payload && location.payload.subpage

    // TODO - TEMP FIX to make /now/SLUGHASUPPERCASEMADNESS work
    slug = slug && slug.toLowerCase()   
    
    const language = useSelector(state => state.language)

    const dispatch = useDispatch()

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {page => {
                if (page) {
                    /**
                     * * Check if page is Dynamic
                     * * return 404 if not
                     */
                    if (!isDynamic(page)) {
                        dispatch({ type: NOT_FOUND })

                        return <h1>Not Found</h1>
                    }

                    return (
                        <PageWithSeo
                          page={page}
                            //   showLoading
                        >
                            <DynamicPage
                              slug={page.slug}
                              sections={page.sectionsCollection.items}
                              pageBackground={page.pageBackground}
                              config={page.config}
                              location={slug}
                              hasContent
                            />
                        </PageWithSeo>
                    )
                } else {
                    console.log('page', page)
                    /**
                     * * Page is not found / undefined
                     * * Return 404
                     * ? Have to return something from render
                     */
                    dispatch({ type: NOT_FOUND })

                    return <h1>Not Found</h1>
                }
            }}
        </PageBySlugQuery>
    )
}

import React from 'react'
import _map from 'lodash/map'
import BackgroundImage from 'features/background-image'
import ArticlesByTag from 'queries/ArticlesByTag'
import NotFound from 'pages/not-found'
import { HeadingSmall } from 'layouts/typography'
import { Container } from 'layouts'
import { Card } from '../cards'
import BreadCrumbs from '../../../articles/components/articles/components/articles-breadcrumbs'
import style from './NewsByTag.module.scss'

interface Props {
    tag: string,
    language: string,
    // hasContent: boolean
}

export default function NewsByTag({ language, tag }: Props) {

    return (
        <div className={style.newsByCategoryContainer}>
            <BackgroundImage
              url="https://images.ctfassets.net/7lhcm73ukv5p/6tcqDcocg2kARQbxjbGOP2/d328aad97866814c40ac4bff10b714f0/clouds-bg.jpg"
              repeat="no-repeat"
              size="contain"
              height={700}
              position="top center"
              className={style.newsBackground}
            >
                <Container>
                    <ArticlesByTag locale={language} limit={12} tag={tag}>
                        {(newsList) => {
                            if (newsList) {
                                return (
                                    <div className={style.newsByCategory}>
                                        {/* <Tags /> */}
                                        <BreadCrumbs first={'Home'} second={'news'} third={`#${ tag}`} />
                                        <HeadingSmall>News tagged with #{tag}</HeadingSmall>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '30px', gridRowGap: '30px', marginTop: '40px' }}>
                                            {_map(newsList, (item, i) => (
                                                <Card key={i} filled newsItem={item} />
                                            ))}
                                        </div>
                                    </div>
                                )
                            }
                            
                            return <NotFound />                            
                        }}

                    </ArticlesByTag>
                </Container>
            </BackgroundImage>
        </div>
    )
}

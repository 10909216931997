import { connect } from 'react-redux'
import CTA from './CTA'

function mapStateToProps({ location, language }) {
    return {
        page: location.payload.page,
        language,
    }
}

export default connect(mapStateToProps)(CTA)

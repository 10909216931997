import { REQUEST_COUNTRY, RECEIVE_COUNTRY } from './actions'

const country = (state = null, action) => {
    switch (action.type) {
        case REQUEST_COUNTRY:
            return state
        case RECEIVE_COUNTRY:
            return action.country
        default: {
            return state
        }
    }
}

export default country

import React from 'react'
import PropTypes from 'prop-types'
import { flags } from 'config'
import localize from 'features/navigation/components/localize'
import Button from 'layouts/button'

const CTA = ({ page, language, ...rest }) => {
    /**
     * * CTA Navigation Button
     * * Customize based on page level
     */
    const renderButtonTheme = () => {
        switch (page) {
            case 'omega':
                return (
                    <Button
                      theme="omega"
                      size="small"
                      path="https://secure.eveonline.com/AddGameTime/"
                      data-id="eveNav_omegaUpgrade"
                      {...rest}
                    >
                        {localize[language].upgradeToOmega}
                    </Button>
                )
            case undefined:
                return (
                    <Button
                      theme={
                            flags.features.colorThemeEnabled
                                ? 'quadrant'
                                : 'primary'
                        }
                      size="small"
                      path="https://secure.eveonline.com/signup/"
                      data-id="eveNav_playFree"
                      {...rest}
                    >
                        {localize[language].playForFree}
                    </Button>
                )
            default:
                return (
                    <Button
                      size="small"
                      path="https://secure.eveonline.com/signup/"
                      data-id="eveNav_playFree"
                      {...rest}
                    >
                        {localize[language].playForFree}
                    </Button>
                )
        }
    }

    return renderButtonTheme()
}

CTA.propTypes = {
    page: PropTypes.string,
    language: PropTypes.string
}

export default CTA

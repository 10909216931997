import React from 'react'
import PropTypes from 'prop-types'
import _map from 'lodash/map'
import style from './CardList.scss'
import Card from './components/card'

const CardList = ({ section }) => (
    <div className={style.cardList}>
        {_map(section.contentCollection.items, (item, i) => (
            <Card card={item} key={i} />
        ))}
    </div>
)

CardList.propTypes = {
    section: PropTypes.shape({
        content: PropTypes.arrayOf(PropTypes.object),
    }),
}

export default CardList

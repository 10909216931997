import React, { Fragment } from 'react'
import _isEmpty from 'lodash/isEmpty'
import { redirect } from 'redux-first-router'
import paths from 'config/paths'
import flags from 'config/flags'
import { SEO } from 'features'
import WWW from 'pages/www'
import Omega from 'pages/omega'
import PlayerResources from 'pages/player-resources'
import Articles from 'pages/articles'
import ArticlesCategory from 'pages/articles-category'
import Article from 'pages/article'
import Monument from 'pages/monument'
import LiveEvent from 'pages/live-event'
import Evergreen from 'pages/live-event/EverGreen'
import Download from 'pages/download'
import Signup from 'pages/signup'
import Signup2 from 'pages/signup2'
import SignupVerify from 'pages/signup-verify'
import SignupConfirmation from 'pages/signup-confirmation'
import Policies from 'pages/policies'
import TestErrorPage from 'pages/testerrorpage'
import DynamicPage from 'pages/dynamic-page'
import SeoPage from 'pages/seo-page'
import Discovery from 'pages/discovery'
import Recruit from 'pages/recruit'
import News from 'pages/news'
import Return from 'pages/return'
import Fanfest from 'pages/fanfest'
import WorldTour from 'pages/world-tour'
import Vegas from 'pages/vegas'
import YearReview from 'pages/year-review'
import Events from 'pages/events'
import Portal from 'pages/portal'

// Things for dynamic pages, not found, redirects etc.
// TODO move to helper for less imports and logic in sitemap.js
import _find from 'lodash/find'
import TestPage from 'pages/testpage'
import { mapping } from './sections'
import config from './web/config'

import { Loading } from '../layouts'

import { Login, Logout, Callback } from '../packages/authentication'

import {
    fetchSection,
    fetchSections,
    fetchPageArticles,
    fetchArticle,
    fetchPageWithoutDispatch,
    fetchHome,
    fetchLiveEvents,
    fetchEvergreen,
    fetchAccountData
} from '../lib/pages/api'

import {
    setPage,
    setPageStarted,
    setHome,
    setHomeStarted,
    setPlayerResources,
    setPlayerResourcesStarted,
    setLiveEvent,
    setLiveEventStarted,
    setArticles,
    setArticlesStarted,
    setArticlesSuccessful,
    setArticlesCategoryStarted,
    setArticle,
    setArticleStarted,
    setNow,
    setNowStarted,
    redirect as redirectAction,
    setWorldTour,
    setWorldTourStarted,
    setError,
    setNotFound,
    setAccountData,
    setAccountDataStarted,
    setAccountDataSuccessful
} from '../lib/pages/actions'

import getRedirectFromPath from '../utils/redirect/redirect'

async function getPagePopulate(id, slug, dispatch, getState, language) {
    if (!getState().pages[id]) {
        dispatch(setPageStarted(id))
        const page = await fetchPageWithoutDispatch(slug, language)
        if (page && !page.data) {
            return dispatch(setNotFound())
        }
        return dispatch(setPage(id, page.data))
    }
}

const sitemap = {
    home: {
        path: '/',
        render: () => <WWW />,
        hideMenuBorder: true
    },
    test: {
        id: 'test',
        path: '/test',
        render: () => <TestPage />,
        hideMenuBorder: true,
        disabled: !flags.isDevelopment
    },
    omega: {
        path: '/omega',
        render: () => <Omega />
    },
    download: {
        path: '/download',
        render: () => <Download />
    },
    fanfest: {
        path: '/fanfest',
        render: () => <Fanfest />,
        async populate(dispatch, getState, language) {
            return getPagePopulate(
                'fanfest',
                'fanfest',
                dispatch,
                getState,
                language
            )
        }
    },
    return: {
        path: '/return',
        render: () => <Return />,
        hideMenuBorder: true
    },
    vegas: {
        path: '/vegas',
        render: () => <Vegas />,
        async populate(dispatch, getState, language) {
            return getPagePopulate(
                'vegas',
                'vegas',
                dispatch,
                getState,
                language
            )
        }
    },
    'my-year-in-eve': {
        path: '/my-year-in-eve',
        render: () => <YearReview />,
        async populate(dispatch, getState, language) {
            return getPagePopulate(
                'year',
                'year-in-review',
                dispatch,
                getState,
                language
            )
        },
        hideMenu: true,
        hideMenuBorder: true
    },

    // ! Below are other ways of getting data.
    'player-resources': {
        path: '/player-resources',
        render: () => <PlayerResources />,
        async populate(dispatch, getState, language) {
            if (!getState().pages.playerResources) {
                dispatch(setPlayerResourcesStarted())
                const section = await fetchSection(
                    mapping.playerResources,
                    language
                )
                if (!section) {
                    return dispatch(setError())
                }
                return dispatch(setPlayerResources(section))
            }
        }
    },
    'live-event': {
        path: '/live-event',
        showLiveEvent: false,
        async populate(dispatch, getState, language) {
            if (!getState().pages.liveEvent) {
                dispatch(setLiveEventStarted())
                const liveEventPages = await fetchLiveEvents(language)
                if (liveEventPages == null) {
                    return dispatch(setError())
                }
                if (liveEventPages.pageLiveEvent) {
                    this.showLiveEvent = true
                }
                return dispatch(setLiveEvent(liveEventPages))
            }
        },

        render: () => <LiveEvent />
    },
    evergreen: {
        path: 'evergreen',
        async populate(dispatch, getState, language) {
            if (!getState().pages.liveEvent) {
                dispatch(setLiveEventStarted())
                const liveEventPages = await fetchEvergreen(language)
                if (liveEventPages == null) {
                    return dispatch(setError())
                }
                return dispatch(setLiveEvent(liveEventPages))
            }
        },
        render: () => <Evergreen />,
        disabled: !flags.isDevelopment
    },
    expansion: {
        path: '/expansion',
        redirect: redirectAction()
    },
    articles: {
        path: '/articles',
        render: () => <Articles />,
        async populate(dispatch, getState, language) {
            if (!getState().pages.articles) {
                dispatch(setArticlesStarted())
                const articles = await fetchPageArticles(language)
                // return dispatch(setArticles(articles))
                dispatch(setArticles(articles))
            }
            dispatch(setArticlesSuccessful())
        },
        children: {
            values: ['news', 'dev-blogs', 'patch-notes', 'scope'],
            render: () => <ArticlesCategory endpoint={paths.articlesApiUrl} />
        }
    },
    article: {
        path: '/article',
        render: () => <Article />,
        async populate(dispatch, getState, language) {
            if (!getState().pages.articles) {
                dispatch(setArticlesStarted())
                const articles = await fetchPageArticles(language)
                dispatch(setArticles(articles))
            }
            const {
                payload: { subpage, id }
            } = getState().location

            if (
                !getState().pages.article ||
                getState().pages.article.id !== subpage
            ) {
                dispatch(setArticleStarted())
                if (!subpage) {
                    return dispatch(setNotFound())
                }

                // Hack to not show really old article from elastic that breaks GDPR
                if (
                    subpage ===
                    'the-council-of-stellar-management-second-election-results-are-in'
                ) {
                    return dispatch(setNotFound())
                }

                // Adding preview functionality for dev and preview web
                if (config.contentful.preview) {
                    // No need to do anything, maybe invert the if??
                    // console.log('IT IS SET TO PREVIEW!!')
                    // this part is handled by graphql query and preview
                    // No need to get article here with our API, let component with graphql handle it.
                } else {
                    const article = await fetchArticle(
                        subpage,
                        language
                    ).catch((error) => dispatch(setNotFound()))

                    // Article not found or error.
                    if (article == null) {
                        return dispatch(setNotFound())
                    }

                    // Redirect to correct slug if article has slug set
                    // and slug in url is not same as article slug.
                    if (article.slug) {
                        if (article.slug !== id) {
                            const action = redirect(
                                redirectAction(
                                    'article',
                                    article.id,
                                    article.slug
                                )
                            )
                            return dispatch(action)
                        }
                        // This is slug in url that should not be there if slug is not set in article.
                    } else if (id) {
                        const action = redirect(
                            redirectAction('article', article.id)
                        )
                        return dispatch(action)
                    }
                    return dispatch(setArticle(article))
                }
            }
        }
    },
    now: {
        path: '/now',
        render: () => <DynamicPage />,
        async populate(dispatch, getState, language) {
            // Code not used anymore after DLPs moved to graphql
            dispatch(setNowStarted())
            const {
                payload: { subpage }
            } = getState().location

            // Adding preview functionality for dev and preview web
            // if (config.contentful.preview) {
            //     // No need to do anything, maybe invert the if??
            //     console.log('Preview enabled')
            //     // this part is handled by graphql query and preview
            //     // No need to get article here with our API, let component with graphql handle it.
            // } else {
            //     // Get the dynamic page
            //     const now = await fetchPageWithoutDispatch(subpage, language)

            //     // console.log('now: ', now)

            //     // Return Not found if no data.
            //     if (!now.data) {
            //         return dispatch(setNotFound())
            //     }

            //     // Return Not found if page is not set as dynamic in page config.
            //     if (!isDynamic(now.data)) {
            //         return dispatch(setNotFound())
            //     }

            //     return dispatch(setNow(now.data))
            // }
        },
        hideMenu: true
    },
    p: {
        path: '/p',
        render: () => <SeoPage />,
        hideMenuBorder: true
    },
    events: {
        path: '/events',
        render: () => <Events />
    },
    signup: {
        id: 'signup',
        path: '/signup',
        render: () => <Signup2 />,
        disabled: !flags.pages.recruitmentEnabled,
        showDarkHeader: true,
        hideMenu: true
    },
    signup2: {
        id: 'signup2',
        path: '/signup2',
        render: () => <Signup />,
        disabled: !flags.pages.recruitmentEnabled,
        showDarkHeader: true,
        hideMenu: true
    },
    'signup-verify': {
        id: 'signupVerify',
        path: '/signup-verify',
        render: () => <SignupVerify />,
        disabled: false,
        showDarkHeader: true,
        hideMenu: true
    },
    'signup-confirmation': {
        id: 'signupConfirmation',
        path: '/signup-confirmation',
        render: () => <SignupConfirmation />,
        disabled: false,
        showDarkHeader: true,
        hideMenu: true
    },
    discovery: {
        path: '/discovery',
        render: () => <Discovery />
    },
    monument: {
        path: '/monument',
        render: () => (
            <Fragment>
                <SEO title='Monument Name Finder' />
                <Monument />
            </Fragment>
        )
    },
    news: {
        path: '/news',
        render: () => <News />,
        disabled: !flags.isDevelopment,
        hideMenuBorder: true,
    },
    // 'news-detail': {
    //     path: '/news-detail',
    //     render: () => <NewsDetail />,
    //     disabled: !flags.isDevelopment,
    //     hideMenuBorder: true,
    // },    
    recruit: {
        id: 'recruit',
        path: '/recruit',
        render: () => <Recruit />,
        showDarkHeader: true,
        showLogin: true,
        disabled: !flags.pages.recruitmentEnabled
    },
    'world-tour': {
        path: '/world-tour',
        render: () => <WorldTour />,
        async populate(dispatch, getState, language) {
            if (!getState().pages.worldTour) {
                dispatch(setWorldTourStarted())
                const worldTour = await fetchPageWithoutDispatch(
                    'world-tour-2019-new',
                    language
                )
                if (worldTour && !worldTour.data) {
                    return dispatch(setNotFound())
                }
                return dispatch(setWorldTour(worldTour.data))
            }
        }
        // disabled: !flags.isDevelopment,
    },
    policies: {
        path: '/policies',
        render: () => <Policies />,
        // Data is populated in component with graphql query.
        disabled: !flags.isDevelopment
    },
    'eve-portal': {
        path: '/eve-portal',
        render: () => <Portal />,
        hideMenuBorder: true,
    },
    // cookies: {
    //     path: '/cookies',
    //     render: () => <Cookies />,
    //     disabled: true,
    // },
    // account: {
    //     id: 'account',
    //     role: 'member',
    //     path: '/account',
    //     requireAuth: true,
    //     render: () => <Account />,
    //     disabled: !flags.isDevelopment,
    //     async populate(dispatch, getState, language) {
    //         if (!getState().pages.account) {
    //             dispatch(setAccountDataStarted())
    //             const accountData = await fetchAccountData(getState().auth.token, language)
    //             dispatch(setAccountData(accountData))
    //         }
    //         dispatch(setAccountDataSuccessful())
    //     },
    //     children: {
    //         values: ['edit', 'transactions', 'activity', 'agreement', 'subscription', 'email_landing', 'email_failure', 'email_validation', 'email_validation_failure'],
    //         disabled: !flags.isDevelopment,
    //         render: () => <Account />,
    //         async populate(dispatch, getState, language) {
    //             if (!getState().pages.account) {
    //                 dispatch(setAccountDataStarted())
    //                 const accountData = await fetchAccountData(getState().auth.token, language)
    //                 dispatch(setAccountData(accountData))
    //             }
    //             dispatch(setAccountDataSuccessful())
    //         },
    //     },
    // },
    // billboard: {
    //     id: 'billboard',
    //     role: 'member',
    //     path: '/billboard',
    //     requireAuth: true,
    //     render: () => <Billboard />,
    //     disabled: !flags.isDevelopment,
    // },
    loading: {
        id: 'loading',
        path: '/loading',
        render: () => <Loading />,
        disabled: !flags.isDevelopment
    },
    login: {
        id: 'login',
        path: '/login',
        render: () => (
            <Login config={config.authentication}>
                <Loading />
            </Login>
        )
    },
    logout: {
        id: 'logout',
        path: '/logout',
        render: () => (
            <Logout logoutUrl={config.authentication.logoutUrl}>
                <Loading />
            </Logout>
        )
    },
    callback: {
        id: 'callback',
        path: '/callback',
        render: () => (
            <Callback getRedirectFromPath={getRedirectFromPath}>
                <Loading />
            </Callback>
        )
    },
    testerror: {
        id: 'testerror',
        path: '/',
        render: () => <TestErrorPage />
    }
}

export default sitemap

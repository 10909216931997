export { HeadingXLarge } from './Typography'
export { HeadingLarge } from './Typography'
export { HeadingMedium } from './Typography'
export { HeadingRegular } from './Typography'
export { HeadingSmall } from './Typography'
export { HeadingXSmall } from './Typography'
export { TaglineLarge } from './Typography'
export { TaglineRegular } from './Typography'
export { TaglineSmall } from './Typography'
export { ParagraphLarge } from './Typography'
export { ParagraphRegular } from './Typography'

import React from 'react'
import LazyLoad, { LazyLoadProps } from 'react-lazyload'
import ImageType from 'models/types/ts/imageType'

interface Props {
    image: ImageType,
    lazyloadProps: LazyLoadProps
}

export default function ImageLazyLoad({ image, lazyloadProps }: Props) {
    if (image && image.url)
        return (
            <LazyLoad {...lazyloadProps}>
                <img src={`${image.url}?fm=webp`} alt={image.description} />
            </LazyLoad>
        )

    return <></>
}

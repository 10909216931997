import React from 'react'
import SectionByIdNewsContent from 'queries/SectionByIdNewsContent'
import NewsType from 'models/types/ts/newsType'
import { Heading } from 'layouts'
import Link from '../shared/link'
import style from './FeaturedPosts.module.scss'

interface Props {
    language: string
}

const FeaturedPosts = ({ language }: Props) => {
    const posts = (content: NewsType) => {
        console.log('content', content)
        return (
            <article key={content.title} className={style.featured__content}>
                <img
                  src={`${content.metaImageUrl.url}?w=130`}
                  alt={content.metaDescription}
                  className={style.featured__img}
                />
                <Link slug={content.slug}>
                    {content.title}
                </Link>
            </article>
        )
    }

    return (
        <SectionByIdNewsContent
          identifier="news-featured-posts"
          locale={language}
        >
            {(section) => {
                if (section) {
                    const { items, total } = section.contentCollection
                    return (
                        <div className={style.featured}>
                            <Heading size="small">{section.headline}</Heading>
                            {total > 0 && items.map((item: NewsType) => posts(item))}
                        </div>
                    )
                }
            }}
        </SectionByIdNewsContent>
    )
}

export default FeaturedPosts

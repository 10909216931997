import ConfigModel from '../ConfigModel'
import { isPreview, isLocal, isDevelopment, webBaseUrl } from '../configHelper'

let host = isLocal ? 'localhost:3000' : 'dev.ccpeveweb.com'
if (webBaseUrl) host = webBaseUrl

const config = new ConfigModel()

const apiEndpoint =
    isPreview || isDevelopment || isLocal
        ? 'https://j62tyvg8r3.execute-api.eu-west-1.amazonaws.com/preview'
        : 'https://yo2vtgum73.execute-api.eu-west-1.amazonaws.com/prod' // usii for all webs except preview web.
config.env = 'preview'
config.webBaseUrl = `https://${host}`
config.apiBaseUrl = apiEndpoint
config.signupServiceUrl = 'https://signup.ccpeveweb.com/dev'
config.recruitmentServiceUrl =
    'https://ccp-recruitmentservice-dev.azurewebsites.net'
config.launcherVersionsUrl = 'https://www.mocky.io/v2/5e1ed5ca3100003600189501'
config.storefrontServiceUrl =
    'https://storefront-dev.batcave.ccp.cc/swagger/storefront.swagger.json'
config.storePayPalEnvironment = 'sandbox'
config.secureUrl = 'https://secure.mammonmain.dev'
config.secureAccountManagementUrl = `${config.secureUrl}/accountManMenu.aspx`
config.contentful = {
    graphqlBaseUrl: 'https://graphql.contentful.com/content/v1/',
    environment: 'master',
    spaceId: '7lhcm73ukv5p',
    accessToken:
        '17c81e61246f71aeb0bd81eec81e5779d1564b425105aed8e1df903380b8d9e8', // Preview
    preview: true
}
config.playNow = true

export default config

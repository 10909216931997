import React from 'react'
import _map from 'lodash/map'
import SectionType from 'models/types/ts/sectionType'

export default function Policies({ policy }) {
    console.log('policy: ', policy)

    // const policyId = 'policy-1'

    if (policy) {
        return (
            <>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h1>Single Policy</h1>
                <p>{policy.headline}</p>
                <p>{policy.teaser}</p>
                <p>{policy.body}</p>
            </>
        )
    }
}

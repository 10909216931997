import React from 'react'
import NewsType from 'models/types/ts/newsType'
import ReactMarkdown from 'react-markdown'
import paths from 'config/paths'
import BackgroundImage from 'features/background-image'
import { Container, Icon, Content } from 'layouts'
import { HeadingRegular, TaglineLarge } from 'layouts/typography'
import dummyAd from 'static/img/adglare-410.jpg'
import { DateAndAuthor } from 'features'
import FeaturedPosts from '../../featured-posts'
import BreadCrumbs from '../../../../articles/components/articles/components/articles-breadcrumbs'
import SocialShare from './socialShare'
import style from './NewsDetail.module.scss'

interface Props {
    newsItem: NewsType
}

const NewsDetail = ({ newsItem }: Props) => {
    // console.log('ble')

    const AuthorAndShare = () => (
        <div className={style.authAndShare}>
            <DateAndAuthor
              className={style.author_date}
              date={newsItem.publishingDate}
              author={newsItem.author}
            />
            <SocialShare newsItem={newsItem} />
            {/* <div className={style.socialShare}>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${paths.webBaseUrl}/news/view/${newsItem.slug}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={style.facebook}
                >
                    <Icon
                      brand
                      name="facebook-f"
                      className={style.social}
                    />
                </a>
                <a
                  href={`https://twitter.com/share?url=https://eveonline.com/news/view/${newsItem.slug}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={style.twitter}
                >
                    <Icon
                      brand
                      name="twitter"
                      className={style.social}
                    />
                </a>
                <a href="ble">
                    <Icon
                      regular
                      name="link"
                      className={style.social}
                    />
                </a>
            </div> */}
        </div>
    )

    const Details = (item) => {
        return (
            <div className={style.detail}> 
                <BreadCrumbs first={'Home'} second={'news'} third={item.title} />
                <HeadingRegular textTransform="unset">{item.title}</HeadingRegular>
                <AuthorAndShare />
                <p>Tags: {item.tags} </p>
                {item.content != null && (
                    <ReactMarkdown
                      source={item.content}
                      escapeHtml={false}
                      renderers={{
                        table: ({ children }) => (
                            <div className={style.table}>
                                <table>
                                    {children}
                                </table>
                            </div>
                            ),
                        }}
                    />   
                )}                 
            </div>
        )
    }    

    return (
        <div className={style.newsDetail}>
            {/* <Gradient /> */}
            <BackgroundImage url={newsItem.metaImageUrl.url}
              repeat="no-repeat"
              size="cover"
              height={900}
              position="center center"
              className={style.newsBackground}
            >&nbsp;</BackgroundImage>
            <Container>
                <div className={style.grid}>
                    {Details(newsItem)}
                    <div>
                        <img src={dummyAd} alt="" />
                        <FeaturedPosts language='en' />
                    </div>
                </div>
            </Container>

        </div>
    )
}

export default NewsDetail

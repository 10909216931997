import React, { useState, useEffect } from 'react'
import _map from 'lodash/map'
import { useSelector } from 'react-redux'
import BackgroundImage from 'features/background-image'
import ArticlesArchiveQuery from 'queries/ArticlesArchiveQuery'
import { getLocationPayload } from 'lib/location/selectors'
import { Container } from 'layouts'
import { HeadingRegular } from 'layouts/typography'
import { Link } from 'features'
import { Card } from '../cards'
import style from './NewsArchive.module.scss'

interface Props {
    language: string
}

const years = ['2020', '2019', '2018', '2017', '2016']

export default function NewsArchive({ language }: Props) {
    const location = useSelector((state) => getLocationPayload(state))
    const [year, setYear] = useState('2020')

    useEffect(() => {
        if (location && location.id) {
            setYear(location.id)
        }
    }, [location])
    return (
        <BackgroundImage
          url="https://images.ctfassets.net/7lhcm73ukv5p/6tcqDcocg2kARQbxjbGOP2/d328aad97866814c40ac4bff10b714f0/clouds-bg.jpg"
          repeat="no-repeat"
          size="contain"
          height={700}
          position="top center"
          className={style.archive}
        >
            <Container>
                <HeadingRegular>EVE News archive </HeadingRegular>
            </Container>
            <div className={style.content}>
                <div className={style.filter}>
                    <div className={style.filter_title}>Filter</div>
                    {years.map((yr) => (
                        <Link
                          key={yr}
                          className={style(style.year, {
                                [style.active]: yr === year,
                            })}
                          path={{
                                page: 'news',
                                subpage: 'archive',
                                id: yr,
                            }}
                        >
                            {yr}
                        </Link>
                    ))}
                </div>
                <div>
                    <ArticlesArchiveQuery
                      locale={language}
                      limit={20}
                      query={year}
                    >
                        {(newsList) => {
                            return (
                                <div className={style.grid}>
                                    {_map(newsList, (item, i) => (
                                        <Card key={i} filled newsItem={item} />
                                    ))}
                                </div>
                            )
                        }}
                    </ArticlesArchiveQuery>
                </div>
            </div>
        </BackgroundImage>
    )
}

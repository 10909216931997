const mapping = {
    omega: '2YnNsMuEpOy6SgIEYmSKiK',
    playerResources: '3GxzDiKida82gwA6Y0GWw6',
    liveEventIntro: '4jox5GkRFeC08e20yC2wEQ',
    // liveEventChallenges: 'hD8ZQCGosE2sMAg6Eiokc',
    // liveEventAwards: '3H1GlnWZwss84WaAqIoIwI',
    liveEventStory: '15SGO8JPBIcKgoCYA6MkO4',
    liveEventParticipate: '6kyy5AugbSkoOwEMy8amKi',
    liveEventEvergreenIntro: '4SdwV1wK402IUWu2U8Cwk2',
    liveEventEvergreenPast: '66NhtVDlLO6giuQiSEwqU6',
    liveEventEvergreenParticipate: '2Ohg5eRIGQiq86Cy6OEEqK',
}

export default mapping

import React from "react"
import NewsType from "models/types/ts/newsType"
import { HeadingRegular } from "layouts/typography"
import Picture from "features/picture"
import { DateAndAuthor } from "features"
import Tags from '../tags'
import Link from '../../shared/link'
import style from "./CardFeatured.module.scss"

interface Props {
    newsItem: NewsType;
}

const CardFeatured = ({ newsItem }: Props) => (
    <article className={style.card}>
        <Tags tagList={newsItem.tags} />
        <Link slug={newsItem.slug}>
            {newsItem.metaImageUrl && newsItem.metaImageUrl.url && (
                <Picture
                  src={newsItem.metaImageUrl.url}
                  alt={newsItem.metaDescription}
                />
            )}
            {/* <img
          src={newsItem.metaImageUrl.url}
          alt={newsItem.metaDescription}
        /> */}

            <div className={style.card__content}>
                <DateAndAuthor
                  date={newsItem.publishingDate}
                  author={newsItem.author}
                />
                <HeadingRegular
                  textTransform="unset"
                  fontSize={[28, 42]}
                  fontWeight={600}
                  className={style.card__title}
                >
                    {newsItem.title}
                </HeadingRegular>
            </div>
        </Link>
    </article>
)

export default CardFeatured

import { connect } from 'react-redux'
import flags from 'config/flags'
import NavItems from './NavItems'

const list = [
    {
        translationId: 'navigation.articles',
        page: 'articles',
        dataId: 'eveNav_articles',
        selectedValues: ['articles', 'article'],
    },
    {
        translationId: 'navigation.news',
        page: 'news',
        dataId: 'eveNav_news',
        disabled: !flags.isDevelopment,
    },
    {
        translationId: 'navigation.recruit',
        page: 'recruit',
        dataId: 'eveNav_recruit',
        selectedValues: ['recruit'],
        disabled: !flags.pages.recruitmentEnabled,
    },
    {
        translationId: 'navigation.updates',
        href: 'https://updates.eveonline.com',
        locHref: 'https://updates.eveonline.com/{language}',
        dataId: 'eveNav_updates',
    },
    {
        translationId: 'navigation.support',
        href: 'https://support.eveonline.com/',
        locHref: 'https://support.eveonline.com/hc/{language}',
        dataId: 'eveNav_support',
    },
    {
        translationId: 'navigation.eveStore',
        href: 'https://secure.eveonline.com',
        locHref: 'https://secure.eveonline.com/?lan={language}',
        dataId: 'eveNav_eveStore',
    },
    {
        translationId: 'navigation.download',
        page: 'download',
        dataId: 'eveNav_download',
        selectedValues: ['download'],
    },
]

const accountList = [
    { translationId: 'navigation.account.subscription', page: 'account', sub: 'subscription', dataId: 'eveNav_account_subscription' },
    { translationId: 'navigation.account.transactions', page: 'account', sub: 'transactions', dataId: 'eveNav_account_transactions' },
    { translationId: 'navigation.account.edit', page: 'account', sub: 'edit', dataId: 'eveNav_account_editAccount' },
    { translationId: 'navigation.account.activities', page: 'account', sub: 'activity', dataId: 'eveNav_account_activities' },
    { translationId: 'navigation.account.download', page: 'download', dataId: 'eveNav_account_download' },
    { translationId: 'navigation.account.security', href: 'https://secure.eveonline.com/', dataId: 'eveNav_account_security' },
    { translationId: 'navigation.account.logout', page: 'logout', dataId: 'eveNav_account_logout' },
]

function mapStateToProps(state, ownProps) {
    return {
        page: state.location.payload.page,
        items: list, // ownProps.accountMenu ? accountList : list,
    }
}

export default connect(mapStateToProps)(NavItems)

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getSubpage } from 'lib/location/selectors'
import { isSeo } from 'lib/pages/selectors'
import NotFound from 'pages/not-found'
import PageWithSeo from 'features/page-with-seo'
import PageBySlugQuery from 'queries/PageBySlugQuery'

import SeoLandingPage from './components'

export default function SeoPageContainer() {

    const subpage = useSelector((state) => getSubpage(state))

    const slug = `seo-page-${subpage}`

    const language = useSelector((state) => state.language)

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page) => {
                let hasContent = false
                if (page && isSeo(page)) {
                    hasContent = true

                    const { sectionsCollection } = page

                    return (
                        <PageWithSeo page={page} showLoading={false} hideSitename>
                            <SeoLandingPage sections={sectionsCollection.items} slug={subpage} background={page.pageBackground?.url} hasContent />
                        </PageWithSeo>
                    )
                }

                return <NotFound />
            }}
        </PageBySlugQuery>
    )
}
